import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class VerifyClientService {
  constructor(private apollo: Apollo) {}

  getClient(license: string, cell: string): Observable<any> {
    return this.apollo.mutate({
      mutation: login,
      variables: {
        input: {
          app: 'portal',
          type: 'licensephone',
          license: license || '',
          phone: cell || '',
        },
      },
    });
  }
}

const login = gql`
  fragment verifyClientMembershipFields on ClientMembership {
    id
    membership {
      id
      name
      mode
      price
      taxedPrice
      desc {
        shortDescrip
        longDescrip
      }
      portal
      type
      expirationDays
    }
    dates {
      start
      end
      renewal
    }
    status {
      active
      canceled
      offline
      previousChange
      changeable
    }
    prices {
      purchaseAmount
    }
    billingDayOfMonth
    createdAt
  }

  mutation login($input: CredentialInput!) {
    login(input: $input) {
      authenticated
      message
      jwt
      nextChallenge {
        name
        type
      }
      user {
        id
        name {
          first
          last
        }
        address {
          line1
          line2
          city
          state
          zip
        }
        contact {
          email {
            address
            allowed
            bad
          }
          cell {
            number
            allowed
            bad
            verified
            verifiedAt
          }
        }
        dob
        license {
          number
          passport
          state
          documentId
        }
        active
        balance
        eftBalance

        ... on Client {
          chargedBack
          terminated
          createdAt
          promos {
            used
            restricted
          }
          clientMemberships {
            activeMembership {
              ...verifyClientMembershipFields
            }
            memberships {
              ...verifyClientMembershipFields
            }
          }
          tan {
            history(take: 2) {
              edges {
                ... on SprayHistory {
                  id
                  type
                  createdAt
                  solution {
                    id
                    name
                    desc
                    type
                  }
                  level
                }
                ... on UvHistory {
                  id
                  type
                  createdAt
                  minutes
                }
              }
              totalCount
            }
          }
          receipts(query: ALL) {
            id
            amount
            createdAt
            agreement
          }
          billing {
            billingName
            cardInfo {
              lastFourCardNumber
              cardType
              expireMonth
              expireYear
            }
            badCard
            deadCard
            expiredCard
            recurringFee
          }
          packages {
            id
            packageId
            total
            expireDate
            package {
              id
              type
              name
              price
              pricePlusTax
              sale {
                active
                price
                startDate
                endDate
                saved
              }
              desc {
                short
                long
              }
            }
          }
        }
      }
    }
  }
`;
