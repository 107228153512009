import { createReducer, on } from '@ngrx/store';
import { ChangeMembershipActions } from '../actions';

export const changeMembershipFeatureKey = 'changeMembership';

export interface State {
  loading: boolean;
  error: string;
}

export const initialState: State = {
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(ChangeMembershipActions.changeMembershipRequest, (state) => ({
    ...state,
    error: null,
    loading: true,
  })),
  on(ChangeMembershipActions.changeMembershipSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(ChangeMembershipActions.changeMembershipFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
);

export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
