<button mat-icon-button class="close-dialog-button" [mat-dialog-close]="true">
  <mat-icon class="close-dialog-icon">close</mat-icon>
</button>

<div class="container">
  <h1 class="container__title" mat-dialog-title>{{ title }}</h1>
  <p class="container__description">{{ description }}</p>
  <!-- Success Message -->
  <div *ngIf="showSuccess">
    <h1 class="container__title" mat-dialog-title>{{ successTitle }}</h1>
    <p class="container__description">
      {{ successDescription }}
    </p>
  </div>
  <mat-dialog-content #dialogContent>
    <hyp2-overlay-spinner *ngIf="loading$ | async"></hyp2-overlay-spinner>
    <!-- Pay Balance -->
    <mat-card class="card mat-elevation-z6" *ngIf="balance$ | async as balance">
      <div class="card__content">
        <span class="card__content-text"> Past amount due: </span>
        <span class="card__content-balance">
          {{ balance | currency }}
        </span>
      </div>
      <button
        mat-raised-button
        class="card__button--pay_now"
        mat-dialog-close
        routerLink="/pay-balance"
      >
        Pay Balance
      </button>
    </mat-card>
    <div *ngIf="(balance$ | async) === 0">
      <hyp2-card-form
        *ngIf="!showSuccess"
        class="container__card-form"
        (billing)="cardFormEvent($event)"
      >
      </hyp2-card-form>
      <!-- Terms & Conditions -->
      <hyp2-terms-and-conditions
        #tos
        *ngIf="clientBilling && client$ | async as client"
        [client]="client"
        [item]="item"
        [agreed]="agreed"
        [clientBilling]="clientBilling"
        [showAgreement]="true"
        (buttonEvent)="confirmAgreed($event)"
      ></hyp2-terms-and-conditions>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="container__actions">
    <button
      *ngIf="!showSuccess"
      class="container__close"
      mat-raised-button
      mat-dialog-close
      data-cy="update-billing-close"
    >
      close
    </button>
    <button
      *ngIf="!showSuccess && (balance$ | async) === 0"
       data-cy="update-billing-submit"
      class="container__button"
      mat-raised-button
      (click)="submit()"
      [disabled]="!this.agreed"
    >
      Submit
    </button>
    <button
      *ngIf="showSuccess"
      data-cy="update-billing-okay"
      class="container__button"
      mat-raised-button
      (click)="closeSuccess()"
    >
      Okay
    </button>
  </mat-dialog-actions>
</div>
