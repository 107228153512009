import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ROOT_REDUCERS, metaReducers } from './reducers';

import { AppRoutingModule } from './app-routing.module';
import { GraphQLModule } from './graphql.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/material/material.module';

import { AppComponent } from './core/containers/app.component';
import { environment } from '../environments/environment.prod';
import { TokenHttpInterceptorService } from './core/services/token-http-interceptor.service';
import { SentryHttpInterceptorService } from './core/services/sentry-interceptor.service';
import { GoogleTagManagerService } from './shared/services/google-tag-manager.service';
import { CancelMembershipModule } from './cancel-membership/cancel-membership.module';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    GraphQLModule,
    CoreModule,
    AuthModule,
    SharedModule,
    MaterialModule,
    PageNotFoundModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    StoreDevtoolsModule.instrument({
      name: 'Ecommerce',
      // In a production build you would want to disable the Store Devtools
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
  ],
  providers: [
    { provide: 'googleTagManagerId', useValue: 'GTM-M4MRM9D' },
    { provide: 'googleAnalyticsId', useValue: 'G-BQ5VQB8GFF' },
    Meta,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenHttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SentryHttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: !environment.production,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
