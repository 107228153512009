import { Component, OnInit } from '@angular/core';
import { BillingInput, Client } from '@hyp2/graphql';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { RegisterClientActions } from '../../../auth/actions';
import { CheckoutPurchaseActions } from '../../../checkout/actions';
import { SelectedItem } from '../../../checkout/models';
import { SelectedItemService } from '../../../checkout/services';

import * as util from '@hyp2/fe/util';

import * as fromAuth from './../../../auth/reducers';
import * as fromRoot from './../../../reducers';
import { InitTokenService } from '../../../auth/services';
@Component({
  selector: 'hyp2-admin-tools',
  templateUrl: './admin-tools.component.html',
  styleUrls: ['./admin-tools.component.scss'],
})
export class AdminToolsComponent implements OnInit {
  hasMembership$: Observable<Boolean>;
  hasYearly$: Observable<Boolean>;
  onCheckout$: Observable<Boolean>;
  client$: Observable<Client>;
  jwt$: Observable<string>;
  canBuyMembership$: Observable<boolean>;
  canCreateRandomAccount$: Observable<boolean>;
  showTools = false;

  constructor(
    private authStore$: Store<fromAuth.State>,
    private selectedItemService: SelectedItemService,
    private rootStore$: Store<fromRoot.State>,
    private initTokenService: InitTokenService
  ) {}

  ngOnInit(): void {
    this.onCheckout$ = this.rootStore$.pipe(
      select(fromRoot.selectUrl),
      map((url) => url.startsWith('/checkout'))
    );
    const activeCM$ = this.authStore$.pipe(
      select(fromAuth.getActiveClientMembership)
    );
    this.jwt$ = this.authStore$.pipe(select(fromAuth.getJwt));
    this.client$ = this.authStore$.pipe(select(fromAuth.getClient));
    this.hasMembership$ = activeCM$.pipe(map((membership) => !!membership));
    this.hasYearly$ = activeCM$.pipe(map((membership) => !!membership?.yearly));

    this.canBuyMembership$ = combineLatest([
      this.onCheckout$,
      this.client$,
      this.hasMembership$,
    ]).pipe(
      map(
        ([onCheckout, client, hasMembership]) =>
          onCheckout && !!client && !hasMembership
      )
    );
    this.canCreateRandomAccount$ = combineLatest([
      this.jwt$,
      this.client$,
    ]).pipe(map(([jwt, client]) => jwt && !client));
  }

  buyMembership() {
    this.selectedItemService
      .getSelectedItemObservable()
      .pipe(first())
      .subscribe((item) => {
        const clientBilling: BillingInput = util.getRandomClientBilling();
        this.dispatchCheckout({
          item,
          clientBilling,
        });
      });
  }

  createRandomAccount(): void {
    this.authStore$.dispatch(
      RegisterClientActions.registerClientRequest({
        name: {
          first: util.getRandomString(),
          last: util.getRandomString(),
        },
        dob: util.getRandomDate(),
        license: {
          number: util.getRandomString(),
          passport: false,
          state: null,
          documentId: null,
        },
        cell: util.getRandomNumber()?.toString(),
      })
    );
  }

  dispatchCheckout({
    item,
    clientBilling,
  }: {
    item: SelectedItem;
    clientBilling: BillingInput;
  }): void {
    this.authStore$.dispatch(
      CheckoutPurchaseActions.checkoutPurchaseRequest({
        membershipId: item?.membershipId,
        promo: item?.sale?.promo,
        clientBilling: clientBilling,
        useOnFileBilling: false,
      })
    );
  }

  toggleShowButtons(): void {
    this.showTools = !this.showTools;
  }

  getInitToken(): void {
    this.initTokenService.initializeToken();
  }
}
