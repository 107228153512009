import { Component, OnInit, Input } from '@angular/core';
import { SelectedItem } from '../../models';
import { PromoComponent } from '../../../promo/main/promo.component';

@Component({
  selector: 'hyp2-selected-items',
  templateUrl: './selected-items.component.html',
  styleUrls: ['./selected-items.component.scss']
})
export class SelectedItemsComponent implements OnInit {
  @Input() selectedItem: SelectedItem;
  get promo() {
    return this.selectedItem?.sale?.saved || this.selectedItem?.sale?.extraDays;
  }
  get offerDetails() {
    const { saved, extraDays } = this.selectedItem.sale;
    return PromoComponent.offerDetails(saved, extraDays);
  }

  constructor() { }

  ngOnInit(): void {

  }

}
