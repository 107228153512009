import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProfileRoutingModule } from './profile-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material';
import * as fromProfile from './reducers';
import { UpdateBillingEffects } from './effects';
import { MatCardProfileComponent } from '../shared/components';
import {
  ProfileComponent,
  ReceiptsComponent,
  AccountComponent,
} from './containers';
import {
  AccountInformationComponent,
  ReceiptsInformationComponent,
} from './components';

export const COMPONENTS = [
  ProfileComponent,
  AccountInformationComponent,
  ReceiptsInformationComponent,
  ReceiptsComponent,
  AccountComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  entryComponents: [MatCardProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MaterialModule,
    ProfileRoutingModule,
    StoreModule.forFeature(fromProfile.profileFeatureKey, fromProfile.reducers),
    EffectsModule.forFeature([UpdateBillingEffects]),
  ],
  providers: [CurrencyPipe, DatePipe],
})
export class ProfileModule {}
