<mat-card class="card mat-elevation-z6">
  <div class="card__grid">
    <img
      *ngIf="zoomTan"
      class="card__img"
      alt="{{
        monthlyMembership?.type === 'UV'
          ? 'Zoom Tan stand-up UV tanning booth'
          : 'Zoom Tan stand-up VersaPro Spray Tanning Booth'
      }}"
      src="{{
        monthlyMembership?.type === 'UV'
          ? '/assets/images/zoomtan/uv_booth.png'
          : monthlyMembership?.type === 'SPRAY'
          ? '/assets/images/zoomtan/spray_booth.png'
          : '/assets/images/zoomtan/both-beds.png'
      }}"
      data-cy="membership__image"
    />
    <img
      *ngIf="zoomFit && monthlyMembership.image"
      class="card__img"
      alt="Zoom Fit machine"
      src="/assets/images/zoomfit/{{ monthlyMembership.image }}"
      data-cy="zoomfit__image"
    />
    <div class="card__grid--right-content">
      <span class="card__title" data-cy="mem-pkg__title">
        {{ monthlyMembership?.parentTitle }}
      </span>
      <span class="card__sub-title" data-cy="mem-pkg__sub-title">
        Memberships
      </span>
      <span class="card__info" data-cy="mem-pkg__info">
        {{ monthlyMembership?.desc?.shortDescrip }}
      </span>
      <button
        (click)="openDetailsDialog()"
        class="card__open-dialog-text"
        data-cy="mem-pkg__details-button"
      >
        details
      </button>
    </div>
  </div>
</mat-card>
<div class="actions">
  <button
    (click)="buttonEvent.emit(monthlyMembership)"
    class="actions__button"
    mat-raised-button
    data-cy="membership__monthly-button"
  >
    <div class="button-grid">
      <div class="button-grid--left">
        <span class="button-grid__item--left">MONTHLY</span>
        <span class="button-grid__item--left-small">Cancel Anytime</span>
      </div>
      <div class="button-grid--right">
        <span
          class="button-grid__item--right"
          data-cy="membership__monthly-price"
        >
          ${{ monthlyMembership?.price }}
        </span>
      </div>
    </div>
  </button>
  <button
    *ngIf="zoomTan"
    (click)="buttonEvent.emit(yearlyMembership)"
    class="actions__button"
    mat-raised-button
    data-cy="membership__yearly-button"
  >
    <div class="button-grid">
      <div class="button-grid--left">
        <span class="button-grid__item--left">BUY YEAR</span>
        <span
          class="button-grid__item--left-small"
          data-cy="membership__yearly-savings"
          >You save ${{
            monthlyMembership?.price * 12 - yearlyMembership?.price
          }}</span
        >
        <!-- dynamic 'you save' calculation -->
      </div>
      <div class="button-grid--right">
        <span
          class="button-grid__item--right"
          data-cy="membership__yearly-price"
        >
          ${{ yearlyMembership?.price }}
        </span>
      </div>
    </div>
  </button>
</div>
