import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class FiveDollarFridayMonthlyComponent extends PromoComponent {
  // id
  static path = '5fm';
  static promoCode = '5F';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.MONTHLY;

  // display
  imageFileDesktop = '/assets/images/promos/five-dollar-friday-desktop.jpg';
  imageFileMobile = '/assets/images/promos/five-dollar-friday-mobile.jpg';
  header = 'Save Big On Your First Month With Our $5 Friday Sale!';
  text = 'With this limited time exclusive sale, you can save 50% on your first tanning month if you buy today! We do not require any long-term commitments or contracts. Cancellation is FREE and can be completed online any time after just 10 days from the start date. Members who LOVE our service can continue after the first month at regular pricing or opt to cancel and use the remainder of the tanning month that they purchased.';
}
