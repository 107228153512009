export const defaultError =
  'Oops... Something went wrong please contact Customer Service or Try again.';
export const errMap = {
         auth: {
           '99': 'Invalid Login.',
         },
         payment: {
           '1000': 'No billing information found. Please try again',
           '1001': 'An error occurred and your card was not charged.',
           '1002':
             'You can only purchase one membership or package at a time.  Please retry your purchase with only 1 item.',
           '1011': 'No balance found on your account.',
           '1004':
             'An error occurred identifying a product in your cart.  Please try again.',
           '1005':
             'An error occurred while processing your payment.  Please try again.',
           '1006':
             'There was a pricing error while calculating your membership change rate.  Please try again.',
           '1007': 'There was an error creating your receipt.',
           '1308': 'There was an error retrieving your receipt.',
           '1009': 'No active membership was found on your account.',
           '1010':
             'Your balance was not cleared. Please contact Zoom Tan customer service during business hours at (877) 966-6826 or visit www.zoomtan.com and chat with an available agent.',
           '10011': 'You already have an active membership and cannot proceed.',
           '1012':
             'An error occurred due to a conflict between your active membership expiration and delayed membership starting date.',
           '1013':
             'Cancel membership request cannot be processed because you do not have an active membership on file.',
           '1014':
             'You currently have an outstanding balance and must make a payment before proceeding.',
           '1015':
             'You must wait 10 days after purchasing a membership before any changes or cancellations can be made.',
           '1016': 'You cannot cancel a yearly membership.',
           '1018':
             'You already have an active autopay membership and cannot proceed.',
           '1019': 'You already have an active membership and cannot proceed.',
           '1020':
             'You currently do not have an active membership and cannot proceed.',
           '1021':
             'You need to wait at least 10 days after purchasing a Monthly Membership to make any changes to it.',
           '1022':
             'You cannot purchase a yearly membership with an active monthly autopay membership.',
           '1023':
             'You are currently unable to change your membership.  You may contact our customer service department during business hours at (877) 966-6826 or visit www.zoomtan.com and chat with an available agent.',
           '1024':
             'You cannot change a yearly membership to an monthly membership unless you have less than 30 days of your membership remaining.',
           '1025':
             'Your yearly membership change cannot have a delayed starting date.  New membership must become active on the date of change.',
           '1026':
             'You cannot change to this membership.  If you would like to purchase this membership please visit our membership shop page to continue.',
           '1027':
             'You cannot change to a membership that is already active under your account.',
           '1028': 'Your membership has already been changed.',
           '1029':
             'Membership cannot be changed at this time.  You can only change your membership once per billing cycle.',
           '1030':
             'Your yearly membership has already been changed once and cannot be changed again.',
           '1031': 'You cannot change a membership that was already cancelled.',
           '2136':
             'This Account is Locked. Please Contact ZoomTan for more information.',
           '1137':
             'You are currently on an Employees Membership. Employee memberships cannot be changed.',
           '2114':
             'You currently have an outstanding balance and must make a payment before proceeding.',
           '2029':
             'Membership cannot be changed at this time.  You can only change your membership once per billing cycle.',
           '2140':
             'An AutoPay membership is currently On Hold. Please contact ZoomTan for more information.',
           '2200': 'Current saved card is expired.',
           '1132':
             'The payment method you entered could not be validated.  Please try again, use another payment method, or contact customer service if the issue persists.',
           '2142':
             'Change membership failed. Please contact ZoomTan at (877) 966-6826 immediately for more information.',
           '10100':
             'Your Account Is Being Processed For Billing. Please Try Again After 4AM Eastern Time.',
         },
         membership: {
           '2113':
             'Cancel membership request cannot be processed because you do not have an active membership on file.',
           '2114':
             'You currently have an outstanding balance and must make a payment before proceeding.',
           '2115':
             'You must wait 10 days after purchasing a membership before any changes or cancellations can be made.',
           '2116': 'You cannot cancel a yearly membership.',
           '2031': 'You cannot change a membership that was already cancelled.',
           '2136':
             'This Account is Locked. Please Contact ZoomTan for more information.',
           '2138': `You currently have a membership on hold. Please contact Zoom Tan customer service during business hours at (877) 966-6826 or visit www.zoomtan.com and chat with an available agent.`,
           '10100':
             'Your Account Is Being Processed For Billing. Please Try Again After 4AM Eastern Time.',
         },
         client: {
           '3086': 'Your account has been terminated and you may not continue.',
           '3101': 'No client profile or information found.',
           '3102':
             'An error occurred while creating or updating your client profile.',
           '3103': 'Unable to start the cell phone verification process.',
           '3104': 'A valid ID is required.',
           '3105':
             'Too many verification attempts have been made. You cannot send another verification text at this time, please wait another hour.',
           '3106': "You've entered an invalid code.  Please try again.",
           '3107':
             'Please provide your valid name in the name field to continue.',
           '3108':
             'An error occurred while updating your account.  Please try again.',
         },
       };
