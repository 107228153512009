import { pipe, OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Selector, createSelector, select } from '@ngrx/store';

function selectWhen<T>(
  mainSelector: Selector<object, T>,
  conditionSelector: Selector<object, any>
): OperatorFunction<object, T> {
  return pipe(
    select(
      createSelector(
        mainSelector,
        conditionSelector,
        (val, condition) => ({ val, condition }),
      ),
    ),
    filter(({ condition }) => condition),
    map(({ val }) => val),
  );
}

export function selectWhenLoaded<T>(
  mainSelector: Selector<object, T>,
  loadedSelector: Selector<object, any>
): OperatorFunction<object, T> {
  return selectWhen(mainSelector, loadedSelector);
}

export function selectUnlessLoading<T>(
  mainSelector: Selector<object, T>,
  loadingSelector: Selector<object, any>
): OperatorFunction<object, T> {
  const notLoadingSelector = createSelector(loadingSelector, (loading) => !loading);
  return selectWhen(mainSelector, notLoadingSelector)
}

