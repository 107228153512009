import { ChangeMembership } from '@hyp2/graphql';
import { createAction, props } from '@ngrx/store';

export const changeMembershipInfoRequest = createAction(
  '[Profile] Change Membership Info Request',
  props<{ promo: string; }>()
);
export const changeMembershipInfoSuccess = createAction(
  '[Profile] Change Membership Info Success',
  props<{ changeMembershipInfo: ChangeMembership }>()
);
export const changeMembershipInfoFailure = createAction(
  '[Profile] Change Membership Info Failure',
  props<{ error: any }>()
);
