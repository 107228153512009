import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ChangeExistingMemDialogData,
  ChangeExistingMemDialogComponent,
} from '../components';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ChangeExistingMemDialogService {
  dialogRef: MatDialogRef<ChangeExistingMemDialogComponent>;

  constructor(private dialog: MatDialog) {}

  public openDialog({
    year,
    cancelled,
    current,
    itemEft,
    clientEft,
    promo,
  }: {
    year?: string;
    cancelled?: boolean;
    current?: boolean;
    itemEft?: boolean;
    clientEft?: boolean;
    promo?: string;
  }) {
    this.dialog.closeAll();
    const currentDialogData = {
      title: 'Active Membership Detected',
      description: year
        ? `We have detected you already have the same active yearly membership. Click 'continue' to buy an additional year that will start when your ${
            moment(year).isAfter(moment().add(1, 'year'))
              ? 'last yearly membership'
              : 'current year'
          } ends on: ${year} `
        : 'You cannot proceed to purchase because the membership you selected is the same as your active membership',
      current,
      year,
      itemEft,
    };
    const dialogData: ChangeExistingMemDialogData = {
      title: cancelled
        ? 'Cancelled Membership Detected'
        : clientEft
        ? 'Active Monthly Membership Detected'
        : 'Active Yearly Membership Detected',
      description: cancelled
        ? `We have detected your current membership has been cancelled.
           You cannot proceed to purchase nor change membership until your cancelled membership has expired.`
        : itemEft
        ? clientEft
          ? `We have detected you already have an active monthly membership. Click 'change' to switch to a different type of membership.`
          : `We have detected you already have an active yearly membership and are trying to purchase a monthly membership. 
               Click 'change' to switch to a different type of membership.`
        : clientEft
        ? `We have detected you already have an active monthly membership. Click 'change' to switch to a different type of membership.`
        : `We have detected you already have an active yearly membership. 
               Click 'change' to switch to a different type of membership. 
               Click 'continue' to buy an additional year that will start when your ${
                 moment(year).isAfter(moment().add(1, 'year'))
                   ? 'last yearly membership'
                   : 'current year'
               } ends on: ${year} `,
      cancelled,
      year,
      itemEft,
      promo,
    };
    this.dialogRef = this.dialog.open(ChangeExistingMemDialogComponent, {
      data: current ? currentDialogData : dialogData,
      disableClose: true,
      hasBackdrop: true,
    });
  }
}
