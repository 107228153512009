import { createAction, props } from '@ngrx/store';

export const textResendRequest = createAction('[Auth] Resend Text Request');
export const textResendSuccess = createAction(
  '[Auth] Resend Text Success',
  props<{ response: string; textVerificationCode: boolean }>()
);
export const textResendFailure = createAction(
  '[Auth] Resend Text Failure',
  props<{ error: string }>()
);
