import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, switchMap, tap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { VerifyClientService } from '../services';
import { VerifyClientActions } from '../actions';
import { ResponseDialogService } from '../../shared/services';
import { isPlatformBrowser } from '@angular/common';
import { MonitorClientService } from '../services/monitor-client.service';
declare var __insp;
@Injectable()
export class VerifyClientEffects {
  verifyClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VerifyClientActions.verifyClientRequest),
      switchMap((action) =>
        this.verifyClientService.getClient(action.license, action.cell).pipe(
          map((response) =>
            VerifyClientActions.verifyClientSuccess({
              isAuthenticated: response.data.login.authenticated,
              jwt: response.data.login.jwt,
              client: response.data.login.user,
              returnUrl: action.returnUrl,
            })
          ),
          catchError((error) =>
            of(
              VerifyClientActions.verifyClientFailure({
                error: error.message,
                license: action.license,
                cell: action.cell,
                returnUrl: action.returnUrl,
              })
            )
          )
        )
      )
    )
  );

  verifyClientSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VerifyClientActions.verifyClientSuccess),
        tap((action) => {
          if (action.isAuthenticated) {
            if (isPlatformBrowser(this.platformId)) {
              if (__insp) {
                __insp.push(['identify', action.client.id]);
              }
              sessionStorage.setItem('token', action.jwt);
              this.monitorClientService.setClient(action.client);
            }
            if (action.returnUrl) {
              this.router.navigateByUrl(action.returnUrl);
            }
          }
        })
      ),
    { dispatch: false }
  );

  verifyClientFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VerifyClientActions.verifyClientFailure),
        tap((action) => {
          if (!action.error.includes('Network error' || 'ECONNREFUSED')) {
            if (isPlatformBrowser(this.platformId)) {
              sessionStorage.removeItem('token');
            }
            this.responseDialogService.openDialog({
              title: 'Invalid Login',
              description:
                'Drivers License or Phone Number incorrect. Please try again.',
              error: true,
            });
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private verifyClientService: VerifyClientService,
    private responseDialogService: ResponseDialogService,
    private monitorClientService: MonitorClientService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
}
