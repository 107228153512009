import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

import { SEOService } from '../../shared/services';
import { GoogleTagManagerService } from '../../shared/services/google-tag-manager.service';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { isPlatformBrowser } from '@angular/common';
import { BrowserService } from '../services';
import { environment } from '../../../environments/environment';
import { InitTokenService } from '../../auth/services';

@Component({
  selector: 'hyp2-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'frontend-ecommerce';
  showAdminTools = false;
  isSupportedBrowser = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private seoService: SEOService,
    private browserService: BrowserService,
    private store: Store<fromRoot.State>,
    private initTokenService: InitTokenService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    this.updateSEO();
    this.updateGtm();
    this.initTokenService.initializeToken();

    if (isPlatformBrowser(this.platformId)) {
      // @ts-ignore
      if (window.Cypress) {
        // @ts-ignore
        window.store = this.store;
      }

      if (
        environment.mode === 'staging' ||
        environment.mode === 'development'
      ) {
        this.showAdminTools = true;
      }
    }
    this.isSupportedBrowser = this.browserService.isSupportedBrowser();
  }

  updateGtm() {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  updateSEO() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.seoService.updateTitle(`Zoom Buy | ${event['title']}`);
        // Updating Description tag dynamically with title
        this.seoService.updateDescription(event['description']);
      });
  }
}
