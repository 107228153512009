import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as fromAuth from '../../../auth/reducers';
import {
  ReceiptDialogService,
} from '../../../shared/services';

@Component({
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss'],
})
export class ReceiptsComponent {
  receipts$ = this.authStore.pipe(select(fromAuth.getClientReceipts));

  constructor(
    private authStore: Store<fromAuth.AuthState>,
    private receiptDialogService: ReceiptDialogService
  ) {}

  viewReceipt($event: any) {
    this.receiptDialogService.openDialog($event);
  }
}
