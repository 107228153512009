import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { HomeRoutingModule } from './home-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material';
import { HomeEffects } from './effects';
import {
  HomeComponent,
  ZoomTanMembershipsComponent,
  ZoomFitMembershipsComponent,
} from './containers';
import {
  ZoomFitMembershipCardComponent,
  ZoomTanMembershipCardComponent,
} from './components';

export const COMPONENTS = [
  HomeComponent,
  ZoomTanMembershipsComponent,
  ZoomFitMembershipsComponent,
  ZoomTanMembershipCardComponent,
  ZoomFitMembershipCardComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    HomeRoutingModule,
    EffectsModule.forFeature([HomeEffects]),
  ],
})
export class HomeModule {}
