import { Injectable } from '@angular/core';
import { SelectedItem } from '../models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Membership } from '../../core/models';
@Injectable({
  providedIn: 'root'
})
export class SelectedItemService {
  private selectedItem$ = new BehaviorSubject<SelectedItem>(null);

  constructor() { }

  sendSelectedItem(item: SelectedItem): void {
    this.selectedItem$.next(item);
  }

  getSelectedItem(): SelectedItem {
    return this.selectedItem$.value;
  }
  getSelectedItemObservable(): Observable<SelectedItem> {
    return this.selectedItem$;
  }

  selectMembership(membership: Membership): void {
    this.sendSelectedItem(
      this.fromMembership(membership)
    );
  }

  fromMembership(membership: Membership): SelectedItem {
    return {
      title: membership.name,
      categoryInfo: membership.desc.shortDescrip,
      membershipId: membership.id,
      image:
        membership.type === 'UV' ? '/assets/images/zoomtan/uv-bed.png' :
        membership.type === 'SPRAY' ? '/assets/images/zoomtan/spray_booth.png' :
        '/assets/images/zoomtan/both-beds.png',
      cost: membership.price,
      taxedCost: membership.taxedPrice,
      eft: membership.mode === 'EFT',
      sale: {
        promo: membership.sale?.promo,
        start: membership.sale?.start,
        end: membership.sale?.end,
        previousPromoRestrictions: membership.sale?.previousPromoRestrictions,
        clientRestrictions: membership.sale?.clientRestrictions,
        saved: membership.sale?.saved,
        extraDays: membership.sale?.extraDays,
      },
    };
  }
}
