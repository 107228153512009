import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CancelMembershipService {
  constructor(private apollo: Apollo) {}

  cancelMembership(fulfill: boolean): Observable<any> {
    return this.apollo.mutate({
      mutation: cancelMembership,
      variables: { fulfill: fulfill },
    });
  }
}

const cancelMembership = gql`
  mutation cancelMembership($fulfill: Boolean) {
    cancelMembership(fulfill: $fulfill) {
      form
      done
    }
  }
`;
