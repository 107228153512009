import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class BlackFridayYearlyComponent extends PromoComponent {
  // id
  static path = 'bfyp';
  static promoCode = 'BFY';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.YEARLY;

  // display
  imageFileDesktop = '/assets/images/promos/blackfriday-yearly-desktop.jpg';
  imageFileMobile = '/assets/images/promos/blackfriday-yearly-mobile.jpg';
  header = 'Get 6 extra months FREE with this special Black Friday offer!';
  text = 'We have an exclusive online offer just for you this Black Friday! You can get 6 extra months for FREE when you purchase any of our yearly memberships. Yearly members already save big over monthly members but with this deal you can save up to an extra of $249.50. Don\'t miss out!';
}
