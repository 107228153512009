import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MaterialModule } from '../shared/material';

import * as fromCore from './reducers';
import {
  PortalMembershipsEffects,
  PortalPromoMembershipsEffects,
} from './effects';
import {
  AdminToolsComponent,
  HeaderComponent,
  UnsupportedBrowserComponent,
} from './components';
import { AppComponent } from './containers/app.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UnsupportedBrowserComponent,
    AdminToolsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    StoreModule.forFeature(fromCore.coreFeatureKey, fromCore.reducers),
    EffectsModule.forFeature([
      PortalMembershipsEffects,
      PortalPromoMembershipsEffects,
    ]),
  ],
})
export class CoreModule {}
