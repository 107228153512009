import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'hyp2-confirm-change',
  templateUrl: './confirm-change.component.html',
  styleUrls: ['./confirm-change.component.scss'],
})
export class ConfirmChangeComponent implements OnInit {
  @Output() paymentCardEvent = new EventEmitter();
  @Output() newCardEvent = new EventEmitter();
  @Output() useOnFileBillingEvent = new EventEmitter();

  @Input() confirmChangeInformation: {
    showPrice;
    isMembershipYearly;
    price;
    tax;
    total;
    subtotal;
    creditCardLastFour;
    nextPayment;
    nextPaymentAmount;
    isBadClientBilling;
    shortDescription;
    details;
    note;
  };
  public newCard$ = new BehaviorSubject<boolean>(false);
  public forcePayment$: Observable<boolean>;

  public editBilling = {
    type: 'billing',
    title: 'Change Payment Info',
    description:
      'Please enter or scan a new card to update payment information for this transaction.',
    successTitle: 'Update Successful',
    successDescription:
      'Your billing information has been successfully updated!',
  };

  ngOnInit() {
    this.forcePayment$ = this.newCard$.pipe(
      map(
        (newCard) =>
          (this.confirmChangeInformation?.isBadClientBilling &&
            !this.confirmChangeInformation?.isMembershipYearly) ||
          (this.confirmChangeInformation?.isBadClientBilling &&
            this.confirmChangeInformation?.total > 0) ||
          newCard
      )
    );
    if (this.confirmChangeInformation?.isBadClientBilling) {
      this.useOnFileBillingEvent.emit(false);
    }
  }

  setNewCard(value: boolean): void {
    this.newCard$.next(value);
    this.newCardEvent.emit(value);
    this.useOnFileBillingEvent.emit(
      this.confirmChangeInformation.isBadClientBilling ? false : !value
    );
  }

  emitButtonEvent(event: any) {
    if (event) {
      this.paymentCardEvent.emit(event);
    } else {
      this.paymentCardEvent.emit(null);
    }
  }
}
