import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromAuth from '../../../auth/reducers';

@Component({
  selector: 'hyp2-change-mem-successful',
  templateUrl: './change-mem-successful.component.html',
  styleUrls: ['./change-mem-successful.component.scss'],
})
export class ChangeMemSuccessfulComponent implements OnInit {
  loading$: Observable<Boolean>;
  constructor(private authStore: Store<fromAuth.State>) {}

  ngOnInit(): void {
    this.loading$ = this.authStore.select(fromAuth.getLoginLoading);
  }
}
