import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  DetailsDialogComponent,
  Details,
} from '../components/details-dialog/details-dialog.component';
import { Package } from '../../core/models';
import { ClientPackages } from '../../auth/models';
import { ClientMembership } from '@hyp2/graphql';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DetailsDialogService {
  dialogRef: MatDialogRef<DetailsDialogComponent>;
  private monthlyDetails: Details;
  private yearlyDetails: Details;
  private packageDetails: Details;
  private defaultDescription = `No details available.  You currently have a legacy membership that is no longer offered by Zoom Tan.
                                For membership details and information please contact customer service at (239) 465-4285.`;
  constructor(public dialog: MatDialog) {}

  public openDialog(monthlyMembership: any, yearlyMembership: any) {
    this.monthlyDetails = {
      title: monthlyMembership?.name,
      description: monthlyMembership?.desc?.longDescrip.split('-'),
    };
    this.yearlyDetails = {
      title: yearlyMembership?.name,
      description: yearlyMembership?.desc?.longDescrip.split('-'),
    };
    this.dialogRef = this.dialog.open(DetailsDialogComponent, {
      width: 'auto',
      data: {
        monthly: this.monthlyDetails,
        yearly: this.yearlyDetails,
        package: this.packageDetails,
      },
    });
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        // Reset Dialog State
        (this.monthlyDetails = null),
          (this.yearlyDetails = null),
          (this.packageDetails = null);
      });
  }

  public openProfileDialog(
    categoryKey: string,
    clientMemberships: ClientMembership,
    packages: ClientPackages
  ) {
    const memebershipDescription = !clientMemberships?.membership?.desc
      ?.longDescrip
      ? [this.defaultDescription]
      : clientMemberships?.membership?.desc?.longDescrip?.split('-');

    const packageDescription = !packages?.package?.desc?.long
      ? [this.defaultDescription]
      : packages?.package?.desc?.long?.split('-');

    if (categoryKey === 'membership') {
      this.monthlyDetails = {
        title: clientMemberships.membership.name,
        description: memebershipDescription,
      };
    } else {
      this.packageDetails = {
        title: packages?.package?.name,
        description: packageDescription,
      };
    }
    this.dialog.open(DetailsDialogComponent, {
      width: 'auto',
      data: { monthly: this.monthlyDetails, package: this.packageDetails },
    });
  }
}
