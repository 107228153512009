import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '../../../auth/reducers';

export interface ChangeExistingMemDialogData {
  title: string;
  description: string;
  year?: string;
  cancelled?: boolean;
  current?: boolean;
  itemEft?: boolean;
  promo?: string;
}

@Component({
  selector: 'hyp2-change-existing-mem-dialog',
  templateUrl: './change-existing-mem-dialog.component.html',
  styleUrls: ['./change-existing-mem-dialog.component.scss'],
})
export class ChangeExistingMemDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ChangeExistingMemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeExistingMemDialogData,
    private router: Router,
    private authStore: Store<fromAuth.AuthState>
  ) {}

  client$ = this.authStore.pipe(select(fromAuth.getClient));

  closeDialog(value: boolean) {
    this.dialogRef.close(value);
  }
}
