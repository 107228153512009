import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PayBalanceRoutingModule } from './pay-balance-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material';

import * as fromPayBalance from './reducers';
import {
  PayBalanceEffects,

} from './effects';
import { MatCardProfileComponent } from '../shared/components';
import {
  PayBalanceComponent,
  PayBalanceSuccessComponent,
} from './containers';


export const COMPONENTS = [
  PayBalanceSuccessComponent,
  PayBalanceComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  entryComponents: [MatCardProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MaterialModule,
    PayBalanceRoutingModule,
    StoreModule.forFeature(
      fromPayBalance.payBalanceFeatureKey,
      fromPayBalance.reducer
    ),
    EffectsModule.forFeature([PayBalanceEffects]),
  ],
  providers: [CurrencyPipe, DatePipe],
})
export class PayBalanceModule {}
