import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Client } from '@hyp2/graphql';
import { isBadClientBilling } from '../../../shared/utils';
import { Router } from '@angular/router';
import { SelectedItemService } from '../../../checkout/services';

@Component({
  selector: 'hyp2-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountInformationComponent {
  @Input() client: Client;
  @Input() locked: boolean;
  @Input() verifyCell: boolean;
  @Output() buttonEvent = new EventEmitter();
  @Output() balanceEvent = new EventEmitter();
  @Output() verifyCellEvent = new EventEmitter();

  constructor(
    private router: Router,
    private selectedItemService: SelectedItemService,
  ) {}

  get routeToBuyMembership() {
    return this.selectedItemService.getSelectedItem() ? '/checkout' : '/';
  }

  get cardText(): string {
    if (!this?.client?.billing) return 'No card found';
    if (this?.client?.billing?.badCard) return 'Bad card detected';
    if (this?.client?.billing?.deadCard) return 'Dead card detected';

    return `xxxx-xxxx-xxxx-${this?.client?.billing?.cardInfo?.lastFourCardNumber}`;
  }

  get updateBillingText(): string {
    if (isBadClientBilling(this?.client?.billing)) return 'update';
    else return 'edit';
  }

  editBilling() {
    this.buttonEvent.emit({
      type: 'billing',
      title: 'Change Billing Info',
      description:
        this.client?.balance > 0
          ? `You currently have an outstanding balance. Please select ”Pay Balance” to pay your balance.
           Be sure to check the box at the bottom of the payment form on the next screen to also update your billing information.`
          : 'Update your current card on file by scanning or entering in the new card below.',
      successTitle: 'Update Successful',
      successDescription:
        'Your billing information has been successfully updated!',
    });
  }
}
