import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { OnfidoActions } from '../../actions';
import { requestOnfidoSdkToken } from '../../actions/onfido-actions';
import * as fromOnfido from '../../reducers/onfido.reducer';
import { OnfidoDocument } from '../../types/OnfidoDocument';

@Component({
  selector: 'hyp2-onfido-flow',
  templateUrl: './onfido-flow.component.html',
  styleUrls: ['./onfido-flow.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OnfidoFlowComponent implements OnInit, OnDestroy {
  private onfido: any;
  private tokenSub: Subscription;

  constructor(
    private readonly onfidoStore: Store<fromOnfido.OnfidoState>,
    private readonly ngZone: NgZone
  ) {}

  ngOnInit() {
    this.initializeOnfido();
    this.tokenSub = this.onfidoStore
      .select(fromOnfido.selectSdkTokenState)
      .pipe(
        tap((token) => {
          if (!token) {
            this.onfidoStore.dispatch(requestOnfidoSdkToken());
          }
        }),
        filter((token) => !!token)
      )
      .subscribe((token) => {
        this.onfido.setOptions({ token });
      });
  }

  ngOnDestroy(): void {
    this.onfido?.tearDown();
    this.tokenSub.unsubscribe();
  }

  initializeOnfido() {
    this.onfido = (window as any).Onfido.init({
      containerId: 'onfido-mount',
      smsNumberCountryCode: 'US',
      onComplete: (data) => {
        const documents = Object.keys(data)
          ?.filter((key) => key.includes('document'))
          ?.map((key) => data[key] as OnfidoDocument);
        this.ngZone.run(() => {
          this.onfidoStore.dispatch(
            OnfidoActions.onfidoFlowComplete({
              documents,
              livePhotoId: data.face?.id,
            })
          );
        });
      },
      onError: (_) => {
        this.ngZone.run(() => {
          this.onfidoStore.dispatch(OnfidoActions.onfidoFlowFailed());
        });
      },
      steps: [
        {
          type: 'welcome',
          options: {
            title: 'Begin by scanning your ID',
            descriptions: ['Your ID will be used to identify you in-store.'],
            nextButton: 'Scan Now',
          },
        },
        {
          type: 'document',
          options: {
            documentTypes: {
              driving_licence: {
                country: 'USA',
              },
              passport: true,
            },
          },
        },
      ],
    });
  }
}
