import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hyp2-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss']
})
export class UnsupportedBrowserComponent implements OnInit {
  public browsers = [
    {
      img: {
        alt: 'Chrome',
        href: 'assets/images/browsers/chrome.png',
      },
      link: {
        title: 'Chrome',
        href: 'https://www.google.com/chrome/'
      },
      version: 'version ≥ 38'
    },
    {
      img: {
        alt: 'Firefox',
        href: 'assets/images/browsers/firefox.png',
      }, 
      link: {
        title: 'Firefox',
        href: 'https://www.mozilla.org/en-US/firefox/new/'
      },
      version: 'version ≥ 28'
    },
    {
      img: {
        alt: 'Microsoft Edge',
        href: 'assets/images/browsers/edge.png',
      }, 
      link: {
        title: 'Microsoft Edge',
        href: 'https://www.microsoft.com/en-us/edge'
      },
      version: 'version ≥ 25'
    },
    {
      img: {
        alt: 'Safari',
        href: 'assets/images/browsers/safari.png',
      }, 
      link: {
        title: 'Safari',
        href: 'https://www.apple.com/safari/'
      },
      version: 'version ≥ 9'
    },
  ]
  ngOnInit(): void { }
}
