<form [formGroup]="onfidoRegistrationForm" class="container__card-form">
  <p>Please provide your phone number to complete your registration.</p>
  <mat-card class="card mat-elevation-z3">
    <mat-form-field
      class="dlform2"
      appearance="outline"
    >
      <mat-label>Cell Phone Number</mat-label>
      <input
        type="tel"
        autocomplete="tel-national"
        matInput
        placeholder="555-555-1234"
        formControlName="phoneNumber"
        mask="(000) 000-0000"
        data-cy="register__phone-input"
      />
      <mat-icon matSuffix>phone</mat-icon>
      <mat-hint>Include area code</mat-hint>
      <mat-error
        *ngIf="this.phoneNumber.hasError('startingOne')"
        data-cy="register__phone--inv-number"
      >
        Phone Number can't start with +1
      </mat-error>
      <mat-error
        *ngIf="this.phoneNumber.hasError('minlength')"
        data-cy="register__phone--inv-minlength"
      >
        Phone Number is to short
      </mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="sms" data-cy="sms-checkbox">
      <span class="card__sms-text">
        You agree to receive automated SMS messages from Zoom Tan.  Standard text messaging and data rates may apply.    You can reply STOP at any time to end.
      </span>
    </mat-checkbox>
    <button
      [disabled]="!onfidoRegistrationForm.valid"
      (click)="submit()"
      mat-raised-button
      class="card__button"
      data-cy="register__confirm-button"
    >
      Confirm
    </button>
  </mat-card>
</form>
