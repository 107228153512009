import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class JulyMonthlyComponent extends PromoComponent {
  // id
  static path = 'zt070421mp';
  static promoCode = 'J4';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.MONTHLY;

  // display
  imageFileDesktop = '/assets/images/promos/july-monthly-desktop.jpg';
  imageFileMobile = '/assets/images/promos/july-monthly-mobile.jpg';
  header = 'Get 40% OFF your first month of unlimited Spray and/or UV tanning!';
  text = 'In celebration of July 4th, we have an exclusive online offer just for you! New and returning members can try us out and save 40% on their first tanning month! We do not require any long-term commitments or contracts. Cancellation is FREE and can be completed online any time after just 10 days from the start date. Members who LOVE our service can continue after the first month at regular pricing or opt to cancel and use the remainder of the tanning month that they purchased.';
}
