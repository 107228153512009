<div class="dialog">
  <h1
    [class.dialog__title--error]="data.error"
    [class.dialog__title--success]="!data.error"
    class="dialog__title"
  >
  {{ data.title }}
  </h1>
  <p class="dialog__description">{{ data.description }}</p>
  <br/>
  <button
    [class.dialog__button--error]="data.error"
    [class.dialog__button--success]="!data.error"
    (click)="closeDialog()"
    class="dialog__button"
    mat-raised-button
  >
    back
  </button>
</div>
