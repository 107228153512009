<div class="overlay">
  <div class="spinner-wrapper">
    <div class="flex-spacer"></div>
    <div>
      <mat-spinner></mat-spinner>
    </div>
    <div class="flex-spacer">
      <p *ngIf="showMessage$ | async">{{message}}</p>
    </div>
  </div>
</div>
