import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Name, License } from '@hyp2/graphql';

@Injectable({
  providedIn: 'root',
})
export class RegisterClientService {
  constructor(private apollo: Apollo) {}

  registerClient(
    name: Name,
    dob: string,
    cell: string,
    license: License
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: register,
      variables: {
        input: {
          mode: 'MANUAL',
          name: name,
          dob: dob,
          cell: cell,
          license: license,
        },
      },
    });
  }
}

export const clientFields = `
  newClient
  texted
  client {
    id
    name {
      first
      last
    }
    address {
      line1
      line2
      city
      state
      zip
    }
    contact {
      email {
        address
        allowed
        bad
      }
      cell {
        number
        allowed
        bad
        pendingVerify
        verified
        verifiedAt
      }
    }
    dob
    gender
    license {
      number
      passport
      state
      documentId
    }
    createdAt
    active
    balance
    eftBalance
    chargedBack
    promos {
      used
      restricted
    }
    terminated
    bypasses
    clientMemberships {
      activeMembership {
        ...registerClientMembershipFields
      }
      memberships {
        ...registerClientMembershipFields
      }
    }
    tan {
      history(take: 2) {
        edges {
          ... on SprayHistory {
            id
            type
            createdAt
            solution {
              id
              name
              desc
              type
            }
            level
          }
          ... on UvHistory {
            id
            type
            createdAt
            minutes
          }
        }
        totalCount
      }
    }
    transactions(query: null) {
      id
      transactionId
      createdAt
      amount
    }

    billing {
      billingName
      cardInfo {
        lastFourCardNumber
        cardType
        expireMonth
        expireYear
      }
      badCard
      deadCard
      expiredCard
    }
    packages {
      id
      packageId
      total
      expireDate
      package {
        id
        type
        name
        price
        pricePlusTax
        sale {
          active
          price
          startDate
          endDate
          saved
        }
        desc {
          short
          long
        }
      }
    }
  }
`;

export const registerClientMembershipFields = `
  fragment registerClientMembershipFields on ClientMembership {
    id
    membership_id
    membership {
      id
      mode
      type
      expirationDays
      name
      price
      taxedPrice
      desc {
        shortDescrip
        longDescrip
      }
      sale {
        active
        startDate
        endDate
        salePrice
        taxedSalePrice
        saved
        extraDays
        start
        end
        previousPromoRestrictions
        clientRestrictions
        promo
        promoId
      }
      portal
      children {
        id
        mode
        type
        expirationDays
        name
        price
        taxedPrice
        desc {
          shortDescrip
          longDescrip
        }
        sale {
          active
          salePrice
          taxedSalePrice
          saved
          extraDays
          start
          end
          previousPromoRestrictions
          clientRestrictions
          promo
          promoId
        }
        portal
      }
    }
    dates {
      start
      end
      renewal
    }
    status {
      active
      canceled
      offline
      previousChange
      changeable
    }
    prices {
      purchaseAmount
    }
    billingDayOfMonth
    createdAt
  }
`;

const register = gql`
  ${registerClientMembershipFields}

  mutation register($input: RegistrationInput!) {
    register(input: $input) {
      ${clientFields}
    }
  }
`;
