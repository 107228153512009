import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ClientMembership } from '@hyp2/graphql';

@Component({
  selector: 'hyp2-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss'],
})
export class CancelComponent {
  @Input() form: string;
  @Input() success: boolean;
  @Input() error: string;
  @Input() currentMembership: ClientMembership;
  @Input() memExists: boolean;
  @Input() isPendingBalance: boolean;
  @Output() buttonEvent = new EventEmitter();

  public cancelMsg =
    'Please read through the following information carefully and press "Cancel" below to finish.';
  public cancelErr =
    'We encountered an issue when canceling your membership. Please read below for more details.';
  public cancelWarning =
    'The membership you are canceling is no longer being sold and you will not be able to get it again.';
  public yearlyMembershipError =
    'If you have a yearly membership please note you can not cancel yearly memberships.';
  public cancelConfirmationMessage = `All online cancellations are confirmed by an immediate text message response stating
  that you have successfully canceled your membership. Please do not assume
                                      that you are canceled without receiving the text message confirmation.`;
}
