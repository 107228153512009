import { createAction, props } from '@ngrx/store';

export const cancelMembershipRequest = createAction(
  '[Profile] Cancel Membership Request',
  props<{ fulfill: boolean }>()
);
export const cancelMembershipSuccess = createAction(
  '[Profile] Cancel Membership Success',
  props<{ form: string; done: boolean }>()
);
export const cancelMembershipFailure = createAction(
  '[Profile] Cancel Membership Failure',
  props<{ error: string }>()
);
