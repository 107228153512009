import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CancelMembershipLogService {
  constructor(private apollo: Apollo) {}

  cancelMembershipLog(
    license: string,
    cell_number: string,
    success: number,
    message: string,
    data?: any
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: cancelMembershipLog,
      variables: {
        input: {
          license,
          cell_number,
          success: success,
          message,
          data: data,
        },
      },
    });
  }
}

const cancelMembershipLog = gql`
  mutation cancelMembershipLog($input: LogCancelData!) {
    cancelMembershipLog(input: $input)
  }
`;
