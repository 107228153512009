import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckoutComponent, CheckoutSuccessfulComponent } from './containers';
import { CheckoutGuardService, CheckoutSuccessfulGuardService } from './services';

const routes: Routes = [
  {
    path: '',
    canActivate: [CheckoutGuardService],
    component: CheckoutComponent
  },
  {
    path: 'checkout-successful',
    canActivate: [CheckoutSuccessfulGuardService],
    component: CheckoutSuccessfulComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CheckoutRoutingModule { }
