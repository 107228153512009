import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { take, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import * as fromAuth from '../../auth/reducers';

@Injectable({
  providedIn: 'root',
})
export class TokenHttpInterceptorService implements HttpInterceptor {
  constructor(
    private authStore: Store<fromAuth.State>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authStore.pipe(take(1), select(fromAuth.getJwt)).pipe(
      switchMap((jwt) => {
        if (!jwt) {
          if (isPlatformBrowser(this.platformId)) {
            jwt = sessionStorage.getItem('token') || '';
          }
        }
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        return next.handle(authReq);
      })
    );
  }
}
