<div class="container">
  <hyp2-overlay-spinner *ngIf="!(loaded$ | async)"></hyp2-overlay-spinner>
  <hyp2-cancel
    *ngIf="loaded$ | async"
    [form]="form$ | async"
    [success]="success$ | async"
    [error]="error$ | async"
    [memExists]="memExist$ | async"
    [currentMembership]="activeClientMembership$ | async"
    [isPendingBalance]="(client$ | async)?.balance > 0"
    (buttonEvent)="cancelMembership()"
    data-cy="cancel-membership"
  ></hyp2-cancel>
</div>
