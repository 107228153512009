import { Component, Output, EventEmitter, Input } from '@angular/core';
import { StartChange } from '../../interface';

@Component({
  selector: 'hyp2-start-change',
  templateUrl: './start-change.component.html',
  styleUrls: ['./start-change.component.scss'],
})
export class StartChangeComponent {
  @Input() startChangeData: StartChange;
  @Output() buttonEvent = new EventEmitter();
}