import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../shared/shared.module';
import * as fromOnfido from './reducers/onfido.reducer';

import { StoreModule } from '@ngrx/store';
import { MaterialModule } from '../shared/material';

import { OnfidoComponent } from './components/onfido.component';
import { OnfidoRegisterComponent } from './components/register/onfido-register.component';
import { OnfidoFlowComponent } from './components/flow/onfido-flow.component';
import { OnfidoEffects } from './effects/onfido.effects';
import { OnfidoRoutingModule } from './onfido-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    OnfidoFlowComponent,
    OnfidoRegisterComponent,
    OnfidoComponent,
  ],
  imports: [
    OnfidoRoutingModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature(fromOnfido.onfidoFeatureKey, fromOnfido.reducers),
    EffectsModule.forFeature([
      OnfidoEffects
    ]),
  ],
})
export class OnfidoModule {}
