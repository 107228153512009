import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class ZFGoogleComponent extends PromoComponent {
  // id
  static path = 'zf0122sbdp';
  static promoCode = 'ZF';

  // memberships
  brand = Brands.ZOOMFIT;
  billingType = BillingTypes.MONTHLY;

  // display
  imageFileDesktop = '/assets/images/promos/zoomfit-desktop.jpg';
  imageFileMobile = '/assets/images/promos/zoomfit-mobile.jpg';
  header = 'Try Out One Of The Most Technologically Advanced Gyms For Only $19!';
  text = 'Get your first month of 24/7 Zoom Fit access for only $19! If you love it (and you will), you can opt to stay on as a member at our regular price of $55 per month. Members enjoy amazing benefits like 24/7 gym access, MAX UV & Spray tanning benefits from Zoom Tan, 24/7 UV tanning, monthly InBody full body composition analyses, Hydromassage, Infrared Sauna, & more! Find us today at 1201 Piper Blvd #4, Naples, FL 34110! We\'re located on the corner of Airport Rd and Immokalee Rd next to Zoom Tan in Piper\'s Crossing Plaza! Front desk hours are from 10 AM to 8 PM (M-F) & from 10 AM to 5 PM (Sa & Su). Members enjoy 24/7 gym access!';
}
