import { createAction, props } from '@ngrx/store';

export const cancelMembershipLogRequest = createAction(
  '[Cancel] Cancel Membership Log Request',
  props<{ license: string; cell: string; message: string; success: number }>()
);
export const cancelMembershipLogSuccess = createAction(
  '[Cancel] Cancel Membership Log Success',
  props<{ cancelMembershipLog: boolean }>()
);

export const cancelMembershipLogFailure = createAction(
  '[Cancel] Cancel Membership Log Failure'
);
