import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { BillingInput } from '@hyp2/graphql';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

const types = require('creditcards-types');

@Component({
  selector: 'hyp2-card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss'],
})
export class CardFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paymentForm') paymentForm: FormGroupDirective;

  @Input() optionalSave = false;
  @Input() cancelable;
  @Output() billing = new EventEmitter();
  @Output() cancelButtonEvent = new EventEmitter();

  public cardForm: FormGroup;
  private activeSub: Subscription;

  public billingName: FormControl;
  public cardNumber: FormControl;
  public expireDate: FormControl;
  public cardCVV: FormControl;
  public save: FormControl;
  public confirmed = false;

  constructor() {}

  ngOnDestroy(): void {
    this.activeSub?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.activeSub = this.paymentForm.statusChanges
      .pipe(withLatestFrom(this.paymentForm.valueChanges))
      .subscribe(([status, formValues]) => {
        if (status === 'VALID') {
          this.billing.emit(this.mapBillingInput(formValues));
        } else {
          this.billing.emit(null);
        }
      });
  }

  ngOnInit(): void {
    this.billingName = new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z- ]*$'),
    ]);
    this.cardNumber = new FormControl('', [
      Validators.required,
      Validators.minLength(16),
      Validators.maxLength(16),
      Validators.pattern('^[0-9]*$'),
      this.cardTypeTest,
    ]);
    this.expireDate = new FormControl('', [
      Validators.minLength(3),
      Validators.required,
      this.expiredTest,
    ]);
    this.cardCVV = new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(3),
      Validators.pattern('^[0-9]*$'),
    ]);
    // puts the opposite into the save control until you click to checkbox
    this.save = new FormControl(!this.optionalSave);

    this.cardForm = new FormGroup({
      billingName: this.billingName,
      cardNumber: this.cardNumber,
      expireDate: this.expireDate,
      cardCVV: this.cardCVV,
      save: this.save,
    });
  }

  mapBillingInput(form): BillingInput {
    const dateArr = String(form.expireDate).split('/');
    return {
      billingName: form.billingName,
      cardNumber: form.cardNumber,
      expireMonth: dateArr[0],
      expireYear: dateArr[1],
      cardCVV: form.cardCVV,
      save: form.save || false,
      cardType: this.getCardType(form.cardNumber),
    };
  }

  getCardType(cardNumber): string {
    const fullCardType = cardNumber
      ? types.find((type) => type.test(cardNumber, true))
      : { name: '' };
    switch (fullCardType?.name) {
      case 'Visa':
        return 'VI';
      case 'Mastercard':
        return 'MC';
      case 'Discover':
        return 'DI';
      default:
        return 'INVALID';
    }
  }

  expiredTest(c: FormControl) {
    if (c.value.includes('/')) {
      const [month, year]: [string?, string?] = c.value.split('/');

      if (!month || !year) {
        return {
          isNotValidYear: true,
        }
      }
      
      const today = moment();
      const currentYearFirst2 = today.format('YYYY').substr(0, 2);
      const formattedYear = year.length === 2 ? `${currentYearFirst2}${year}` : year;
      const formattedMonth = month.length === 1 ? `0${month}` : month;
      const checkMonthStr = `${formattedYear}-${formattedMonth}-01`;
      const currentMonthStr = today.format('YYYY-MM-01');
      if (moment(checkMonthStr).isBefore(moment(currentMonthStr))) {
        return {
          isNotValidYear: true,
        };
      }

      return null;
    }
  }

  cardTypeTest(c: FormControl) {
    const cardNumber = c.value;
    const cardType = types.find((type) => type.test(cardNumber, true))?.name;
    const acceptedTypes = ['Visa', 'Mastercard', 'Discover'];
    if (!acceptedTypes.includes(cardType)) {
      return {
        isNotValidCard: true,
      };
    } else {
      return null;
    }
  }

  cancel() {
    this.cancelButtonEvent.emit(true);
  }
}
