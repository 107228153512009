import { createSelector, createFeatureSelector } from '@ngrx/store';
export * from './pay-balance.reducer';

import * as fromPayBalance from './pay-balance.reducer';

export const payBalanceFeatureKey = 'payBalance';

export const selectPayBalanceState = createFeatureSelector<
  fromPayBalance.State
>(payBalanceFeatureKey);

export const getPayBalanceLoading = createSelector(
  selectPayBalanceState,
  fromPayBalance.getLoading
);
