import { createReducer, on } from '@ngrx/store';
import { PortalMembershipsActions } from '../actions';
import { Membership } from '../models';

export const portalMembershipsFeatureKey = 'portalMemberships';
export const portalPromoMembershipsFeatureKey = 'portalPromoMemberships';

export interface State {
  memberships: Membership[];
  loaded: boolean;
  error: string;
}

export const initialState: State = {
  memberships: undefined,
  loaded: false,
  error: null,
};

export const promoReducer = createReducer(
  initialState,
  on(PortalMembershipsActions.getPromoMembershipsRequest, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    PortalMembershipsActions.getPromoMembershipsSuccess,
    (state, { memberships }) => ({ ...state, memberships, loaded: true })
  )
);

export const reducer = createReducer(
  initialState,
  on(PortalMembershipsActions.getMembershipsRequest, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),

  on(
    PortalMembershipsActions.getMembershipsSuccess,
    (state, { memberships }) => ({ ...state, memberships, loaded: true })
  ),
  on(PortalMembershipsActions.getMembershipsFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  }))
);

export const getPortalMemberships = (state: State) => state.memberships;
export const getMonthlyMemberships = (memberships: Membership[]) =>
  memberships?.map(m => m.children[0]);
export const getZoomTanPortalMemberships = (state: State) =>
  state.memberships?.filter((mem) => !mem.isZoomFit);
export const getZoomFitPortalMemberships = (state: State) =>
  state.memberships
    ?.filter((mem) => mem.isZoomFit)
    .sort((a, b) => (a.price < b.price ? -1 : 1));
export const getPortalMembershipIds = (state: State) =>
  state?.memberships?.map((memberships) => {
    const membershipIDs = [memberships?.id, memberships?.children[0].id];
    return membershipIDs;
  });
export const getMembershipById = (id) => (
  (state: State) => {
    if (!state.memberships) return;
    for (const membership of state.memberships) {
      if (membership.id === id) {
        return membership;
      } else {
        const matchingChild = membership.children.find((c) => c.id === id)
        if (matchingChild) return matchingChild;
      }
    }
  }
);

export const getPromoMembershipById = (id) => (
  (state: State) => {
    const m = getMembershipById(id)(state);
    if (m?.sale.promoId) return m;
  }
);

export const getPortalMembershipsLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;
