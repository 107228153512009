<div class="container">
  <h1 class="header" data-cy="not-supported__header">Your browser is not&nbsp;supported</h1>
  <p class="description">
    It looks like you may be using an unsupported or out-of-date web browser.
    Please make sure you're using the most recent version of your browser, or try
    using one of these supported browsers in order to continue to buy.zoomtan.com.
  </p>
  <div class="browser-icons-container">
    <div class="browser-icon-container" *ngFor="let browser of this.browsers">
      <a href="{{browser.link.href}}" title="Download an updated version of {{browser.link.title}}"><img class="browser-icon" alt="{{browser.img.alt}}" src="{{browser.img.href}}" /></a>
      <div class="browser-text-container">
        <div class="browser-name"><a href="{{browser.link.href}}" title="Download an updated version of {{browser.link.title}}">{{browser.link.title}}</a></div>
        <div class="browser-version">{{browser.version}}</div>
      </div>
    </div>
  </div>
  <p class="back-message">Go back to <a href="https://www.zoomtan.com">www.zoomtan.com</a></p>
</div>
