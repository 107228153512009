import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { UtilitiesService } from '../../../shared/services/utilities.service';
import { OnfidoActions } from '../../actions';
import * as fromOnfido from '../../reducers/onfido.reducer';

@Component({
  selector: 'hyp2-onfido-register',
  templateUrl: './onfido-register.component.html',
  styleUrls: ['./onfido-register.component.scss'],
})
export class OnfidoRegisterComponent implements OnInit {
  public phoneNumber: FormControl;
  public sms: FormControl;
  public onfidoRegistrationForm: FormGroup;

  constructor(
    private readonly onfidoStore: Store<fromOnfido.OnfidoState>,
    private readonly utilService: UtilitiesService
  ) {

  }

  ngOnInit() {
    this.phoneNumber = new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
      this.utilService.phoneNumberCheck,
    ]);

    this.sms = new FormControl(false, Validators.requiredTrue);

    this.onfidoRegistrationForm = new FormGroup({
      phoneNumber: this.phoneNumber,
      sms: this.sms,
    });
  }

  submit() {
    this.onfidoStore.dispatch(
      OnfidoActions.onfidoPhoneNumberSubmitted({ phoneNumber: this.phoneNumber.value })
    );
  }
}
