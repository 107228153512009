import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, switchMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { VerifyClientActions } from '../actions';
import { RegisterClientActions } from '../actions';
import { RegisterClientService } from '../services';
import {
  VerifyCellDialogService,
  ResponseDialogService,
  SnackbarService,
} from '../../shared/services';

@Injectable()
export class RegisterClientEffects {
  registerClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterClientActions.registerClientRequest),
      switchMap((action) =>
        this.registerClientService
          .registerClient(action.name, action.dob, action.cell, action.license)
          .pipe(
            map((response) =>
              RegisterClientActions.registerClientSuccess({
                newClient: response.data.register.newClient,
                texted: response.data.register.texted,
                client: response.data.register.client,
              })
            ),
            catchError((error) =>
              of(
                RegisterClientActions.registerClientFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );

  registeredClientSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterClientActions.registerClientSuccess),
      tap((action) => {
        if (action.client.chargedBack || action.client.terminated) {
          const data = {
            title: 'Chargeback or Terminated Account Detected',
            description:
              'Your account has been terminated and you may not continue. You may contact our customer service department during business hours at (877) 966-6826 or visit www.zoomtan.com and chat with an available agent.',
            error: true,
          };
          this.responseDialogService.openDialog(data);
          this.responseDialogService.dialogRef.afterClosed().pipe(
            map(() => {
              this.router.navigate(['/']);
            })
          );
        } else {
          const activeMembership =
            action.client.clientMemberships?.activeMembership;
          if (
            !activeMembership &&
            action.client.contact.cell.verified !== true
          ) {
            this.verifyCellService.openDialog({
              title: 'Verify Phone Number',
              description:
                'We have sent a 6-digit code to the entered phone number. Please enter the 6-digit code below.',
            });
          }
        }
      }),
      map((action) =>
        VerifyClientActions.verifyClientRequest({
          license: action.client.license.number,
          cell: action.client.contact.cell.number,
          returnUrl: '/checkout',
        })
      )
    )
  );
  registeredClientFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RegisterClientActions.registerClientFailure),
        tap((action) => {
          this.snackBar.open(
            'Unable to create Client. Please check fields',
            'Close',
            5000,
            ['error-snackbar']
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private verifyCellService: VerifyCellDialogService,
    private registerClientService: RegisterClientService,
    private snackBar: SnackbarService,
    private responseDialogService: ResponseDialogService
  ) {}
}
