import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { History, ClientPackages } from '../../../auth/models';
import { DetailsDialogService } from '../../services/details-dialog.service';
import { ClientMembership, Client } from '@hyp2/graphql';
import { MembershipType } from '@libs/shared/enums/membership';
import moment from 'moment';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'hyp2-mat-card-profile',
  templateUrl: './mat-card-profile.component.html',
  styleUrls: ['./mat-card-profile.component.scss'],
})
export class MatCardProfileComponent implements OnInit {
  @Output() buttonEvent = new EventEmitter();
  @Input() categoryKey: string;
  @Input() memberships: ClientMembership;
  @Input() history: History;
  @Input() packages: ClientPackages;
  @Input() client: Client;

  constructor(
    private router: Router,
    private detailsDialogService: DetailsDialogService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe
  ) {}

  private isMembershipActive: boolean;
  private isMembershipValid: boolean;
  public isChangeMembershipAllowed: boolean;

  get changeButtonText() {
    if (this.memberships.membership.id === MembershipType.Employee) {
      return 'Cannot change Employee Membership';
    }
    if (this.memberships.status.changeable) {
      return 'Change Membership';
    }
    if (this.memberships.membership.mode.toString() === 'YEARLY') {
      return 'You cannot change your yearly membership more than once';
    }
    if (this.memberships.dates.end) {
      return 'This membership is unavailable to change';
    }
    if (this.memberships?.dates?.renewal) {
      return `Cannot change membership until ${moment(
        this.memberships?.dates?.renewal
      )?.format('LL')}`;
    }
  }

  get shouldShowChangeMembershipButton() {
    return (
      !this.memberships?.status?.canceled &&
      !this.client?.terminated &&
      this.categoryKey === 'membership'
    );
  }

  get cancelButtonText() {
    return 'Cancel Membership';
  }

  get shouldShowCancelMembershipButton() {
    return (
      !this.memberships?.status?.canceled &&
      !this.client?.terminated &&
      this.categoryKey === 'membership' &&
      this.isMembershipActive &&
      this.memberships.membership.mode !== 'YEARLY' &&
      this.memberships.membership.id !== MembershipType.Employee
    );
  }

  get nextPaymentDisplay(): string {
    if (this.memberships?.membership?.mode !== 'EFT') return;
    if (this.memberships?.status?.canceled) return 'NA - Membership Cancelled';
    if (this.memberships?.dates?.end) return 'NA - Membership Changed';

    return this.isMembershipValid &&
      this.memberships.membership.id !== MembershipType.Employee
      ? `${this.currencyPipe.transform(
          this.client?.billing?.recurringFee
        )} + taxes/fees on ${this.datePipe.transform(
          this.memberships?.dates?.renewal
        )}`
      : null;
  }

  openDetailsDialog() {
    this.detailsDialogService.openProfileDialog(
      this.categoryKey,
      this.memberships,
      this.packages
    );
  }

  ngOnInit(): void {
    this.populateVariables();
  }

  populateVariables(): void {
    this.isMembershipValid =
      this.categoryKey === 'membership' &&
      !this.client?.chargedBack &&
      !this.client?.terminated;

    this.isMembershipActive =
      this.isMembershipValid &&
      this.memberships?.status?.active &&
      !this.memberships?.status?.canceled;

    this.isChangeMembershipAllowed =
      this.memberships?.status?.changeable &&
      this.isMembershipActive &&
      this.memberships.membership.id !== MembershipType.Employee;
  }
}
