import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';


@Injectable({
  providedIn: 'root'
})
export class VerifyCellService {
  jwt: string;

  constructor(
    private apollo: Apollo,
    ) { }

  verifyClientCell(pin: string): Observable<any> {
    return this.apollo.mutate({
      mutation: verifyCell,
      variables: { 
        input: {
          code: +pin
        }
      }
    });
  }
}

const verifyCell = gql`
  mutation verifyCell($input: VerifyCellInput!) {
    verifyCell(input: $input)
  }
`;
