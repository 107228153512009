import { createReducer, on } from "@ngrx/store";
import { ProfileActions } from '../actions';

export const profileDataFeatureKey = 'profileData';
export const profileSelectedTab = 0;

export interface State {
  loaded: boolean;
  error: string;
  selectedTabIndex: number;
};

export const initialState: State = {
  loaded: false,
  error: null,
  selectedTabIndex:0
};

export const reducer = createReducer(
  initialState,
  on(ProfileActions.profileRequest, (state) => ({ ...state, error: null, loaded: false })),
  on(ProfileActions.profileSuccess, (state) => ({ ...state, loaded: true })),
  on(ProfileActions.profileFailure, (state, { error }) => ({ ...state, error, loaded: false })),
  );

export const getLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;
