import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class LivefestComponent extends PromoComponent {
  // id
  static path = 'livefest';
  static promoCode = 'LF';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.MONTHLY;

  // display
  imageFileDesktop = '/assets/images/promos/livefest-desktop.jpg';
  imageFileMobile = '/assets/images/promos/livefest-mobile.jpg';
  header = 'Save BIG on your 1st month of any monthly membership!';
  text = 'We have an exclusive Live Fest offer just for you! New and returning members can try us out and save $19.01 on the first month of any monthly membership we offer! We do not require any long-term commitments or contracts. Members who LOVE our service can continue after the first month at regular pricing or opt to cancel and use the remainder of the tanning month that they purchased. Cancellation is FREE and can be completed online any time after just 10 days from the start date.';
}
