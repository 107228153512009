import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import * as promoComponents from '.';

const routes: Routes = Object.values(promoComponents).map((component) => ({
  path: component.path,
  component: component,
}));

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PromosRoutingModule {}
