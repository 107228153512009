import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers,
} from '@ngrx/store';

import * as fromVerifyClient from './verify-client.reducer';
import * as fromRegisterClient from './register-client.reducer';
import * as fromTextResend from './text-resend.reducer';
import * as fromRoot from '../../reducers';

export const authFeatureKey = 'auth';

export interface AuthState {
  [fromVerifyClient.clientFeatureKey]: fromVerifyClient.State;
  [fromRegisterClient.registerFeatureKey]: fromRegisterClient.State;
  [fromTextResend.textResendFeatureKey]: fromTextResend.State;
}

export interface State extends fromRoot.State {
  [authFeatureKey]: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
  return combineReducers({
    [fromVerifyClient.clientFeatureKey]: fromVerifyClient.reducer,
    [fromTextResend.textResendFeatureKey]: fromTextResend.reducer,
    [fromRegisterClient.registerFeatureKey]: fromRegisterClient.reducer,
  })(state, action);
}

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

// Client State Selectors //
export const selectClientState = createSelector(
  selectAuthState,
  (state: AuthState) => state?.client
);
export const getClient = createSelector(
  selectClientState,
  fromVerifyClient.getClient
);
export const getIsAuthenticated = createSelector(
  selectClientState,
  fromVerifyClient.getIsAuthenticated
);
export const getJwt = createSelector(
  selectClientState,
  fromVerifyClient.getJwt
);
export const getClientForms = createSelector(
  selectClientState,
  fromVerifyClient.getClientForms
);
export const getClientReceipts = createSelector(
  selectClientState,
  fromVerifyClient.getClientReceipts
);
export const getClientHistory = createSelector(
  selectClientState,
  fromVerifyClient.getClientHistory
);
export const getClientBalance = createSelector(
  selectClientState,
  fromVerifyClient.getClientBalance
);
export const getClientPackages = createSelector(
  selectClientState,
  fromVerifyClient.getClientPackages
);
export const getClientBilling = createSelector(
  selectClientState,
  fromVerifyClient.getClientBilling
);
export const getFilteredClientMemberships = createSelector(
  selectClientState,
  fromVerifyClient.getFilteredClientMemberships
);
export const getActiveClientMembership = createSelector(
  selectClientState,
  fromVerifyClient.getActiveClientMembership
);
export const getLoginLoading = createSelector(
  selectClientState,
  fromVerifyClient.getLoading
);
export const getIsBadClientBilling = createSelector(
  selectClientState,
  fromVerifyClient.getIsBadClientBilling
);
// Register Client State Selectors //
export const selectRegisterClientState = createSelector(
  selectAuthState,
  (state: AuthState) => state.register
);
export const getRegisteredClient = createSelector(
  selectRegisterClientState,
  fromRegisterClient.getClient
);
export const getIsNewClient = createSelector(
  selectRegisterClientState,
  fromRegisterClient.getIsNewClient
);
export const getClientTexted = createSelector(
  selectRegisterClientState,
  fromRegisterClient.getClientTexted
);
export const getRegisterLoading = createSelector(
  selectRegisterClientState,
  fromRegisterClient.getLoading
);

// Text Resend State Selectors
export const selectTextResendState = createSelector(
  selectAuthState,
  (state: AuthState) => state.textResend
);

export const getTextVerificationCode = createSelector(
  selectTextResendState,
  fromTextResend.getTextVerificationCode
);
