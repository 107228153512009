<div class="memberships">
  <div *ngIf="membership" class="memberships__card">
    <mat-card class="card mat-elevation-z3" [class.restricted]="restricted">
      <mat-radio-button
        color="primary"
        [value]="membership"
        [checked]="isDefault && !restricted"
        [disabled]="restricted"
        data-cy="membership__radio-button--monthly"
      ></mat-radio-button>
      <div class="flex-spacer"></div>
      <div class="card__info">
        <span class="card__info--top" data-cy="membership__name--monthly">{{
          membership?.newMembership?.name
        }}</span>
        <span class="card__info--bottom"
          >change today for as low as
          <span
            class="card__info--bottom-red"
            data-cy="membership__cost--yearly"
            >{{ membership.changeMembershipInfo[0]?.chargeAmount | currency}}</span
          ></span
        >
        <span *ngIf="membership?.newMembership?.sale?.promo" class="card__info--bottom card__info--bottom-promo">{{
          offerDetails(membership?.changeMembershipInfo[0])
        }}</span>
      </div>
      <div class="flex-spacer"></div>
      <button *ngIf="!restricted"
        class="card__details"
        (click)="
          membership.newMembership.mode === 'EFT'
            ? openMonthlyDetails()
            : openYearlyDetails()
        "
        data-cy="membership__details--monthly"
      >
        Details
      </button>
      <button *ngIf="restricted"
              class="card__details"
              (click)="openRestrictedDetails()"
              data-cy="membership__details--restricted"
      >
        ?
      </button>
    </mat-card>
  </div>
</div>
