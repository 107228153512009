import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import * as fromAuth from '../../auth/reducers';

import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ChangeMembershipGuardService implements CanActivate {
  constructor(
    private router: Router,
    private store$: Store<fromAuth.AuthState>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }
    return this.store$.select(fromAuth.getActiveClientMembership).pipe(
      withLatestFrom(this.store$.pipe(select(fromAuth.getClientBalance))),
      map(([activeCM, balance]) => {
        if (balance > 0) {
          this.router.navigate(['/pay-balance']);
          return false;
        }
        if (activeCM) {
          return true;
        } else {
          this.router.navigate(['/my-account']);
          return false;
        }
      })
    );
  }
}
