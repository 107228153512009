import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { take, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class SentryHttpInterceptorService implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // setup the transaction id so Sentry can group FE and BE events together.
    const transactionId = Math.random().toString(36).substr(2, 9);
    Sentry.configureScope((scope) => {
      scope.setTag('transaction_id', transactionId);
    });
    const sentryReq = req.clone({
      setHeaders: {
        'X-Transaction-ID': transactionId,
      },
    });
    return next.handle(sentryReq);
  }
}
