import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material';
import { PromosRoutingModule } from './promos-routing.module';
import { PromosService } from './promos.service';
import * as promos from '.';

@NgModule({
  declarations: [
    promos.SocialComponent,
    promos.GoogleComponent,
    promos.AudacyComponent,
    promos.YearlyComponent,
    promos.PrimeComponent,
    promos.Prime2Component,
    promos.ZFGoogleComponent,
    promos.ZFSocialComponent,
    promos.JulyMonthlyComponent,
    promos.JulyYearlyComponent,
    promos.LabordayMonthlyComponent,
    promos.LabordayYearlyComponent,
    promos.BlackFridayMonthlyComponent,
    promos.BlackFridayYearlyComponent,
    promos.CyberMondayMonthlyComponent,
    promos.CyberMondayYearlyComponent,
    promos.LivefestComponent,
    promos.FiveDollarFridayMonthlyComponent,
    promos.FiveDollarFridayYearlyComponent,
    promos.RadioComponent,
  ],
  providers: [PromosService],
  imports: [CommonModule, SharedModule, MaterialModule, PromosRoutingModule],
})
export class PromosModule {}
