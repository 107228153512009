import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromCancelMembership from './cancel-membership.reducer';

export const cancelMembershipFeatureKey = 'cancel';

export interface CancelMembershipState {
  [fromCancelMembership.cancelMembershipFeatureKey]: fromCancelMembership.State;
}

export interface State extends fromRoot.State {
  [cancelMembershipFeatureKey]: CancelMembershipState;
}

export function reducers(
  state: CancelMembershipState | undefined,
  action: Action
) {
  return combineReducers({
    [fromCancelMembership.cancelMembershipFeatureKey]:
      fromCancelMembership.reducer,
  })(state, action);
}

export const selectState = createFeatureSelector<CancelMembershipState>(
  cancelMembershipFeatureKey
);
// Cancel Memberships Selectors
export const selectCancelMembershipState = createSelector(
  selectState,
  (state: CancelMembershipState) => state.cancelMembership
);

export const getCancelMembershipForm = createSelector(
  selectCancelMembershipState,
  fromCancelMembership.getCancelMembershipForm
);

export const getCancelMembershipLoaded = createSelector(
  selectCancelMembershipState,
  fromCancelMembership.getLoaded
);

export const getCancelMembershipDone = createSelector(
  selectCancelMembershipState,
  fromCancelMembership.getCancelMembershipDone
);

export const getCancelMembershipError = createSelector(
  selectCancelMembershipState,
  fromCancelMembership.getError
);
