<div>
  <form [formGroup]="cardForm" #paymentForm="ngForm">
    <mat-card class="card mat-elevation-z3">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          placeholder="Full name"
          name="ccname"
          autocomplete="cc-name"
          id="frmNameCC"
          formControlName="billingName"
          data-cy="cardName"
          [ngClass]="{
            'card-control-disabled': confirmed
          }"
        />
        <mat-error *ngIf="this.billingName.hasError('pattern')">
          Only letters(a-z A-Z) and spaces Allowed
        </mat-error>
        <mat-icon matSuffix>payment</mat-icon>
        <mat-hint>Enter full name on card</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Credit Card Number</mat-label>
        <input
          matInput
          autocomplete="cc-number"
          name="cardnumber"
          id="frmCCNum"
          placeholder="Credit Card Number"
          formControlName="cardNumber"
          mask="0000-0000-0000-0000"
          data-cy="cardNumber"
          [ngClass]="{
            inspectletIgnore: true,
            'card-control-disabled': confirmed
          }"
        />
        <mat-icon matSuffix>payment</mat-icon>
        <mat-hint>Visa/Master/Discover card only</mat-hint>
        <mat-error *ngIf="this.cardNumber.hasError('minlength')">
          Minimum length is 16
        </mat-error>
        <mat-error *ngIf="this.cardNumber.hasError('isNotValidCard')">
          Not a Valid Card Type
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Expiration Date</mat-label>
        <input
          matInput
          placeholder="MM/YY"
          name="cc-exp"
          id="frmCCExp"
          mask="M0/0000"
          autocomplete="cc-exp"
          [dropSpecialCharacters]="false"
          formControlName="expireDate"
          data-cy="cardExp"
          [ngClass]="{
            'card-control-disabled': confirmed
          }"
        />
        <mat-icon matSuffix>payment</mat-icon>
        <mat-error *ngIf="this.expireDate.hasError('minlength')">
          Not a valid length
        </mat-error>
        <mat-error *ngIf="this.expireDate.hasError('isNotValidYear')">
          Expired Card
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>CVV</mat-label>
        <input
          matInput
          placeholder="CVV"
          name="cvc"
          autocomplete="cc-csc"
          id="frmCCCVC"
          mask="000"
          formControlName="cardCVV"
          data-cy="cardCVV"
          [ngClass]="{
            inspectletIgnore: true,
            'card-control-disabled': confirmed
          }"
        />
        <mat-error *ngIf="this.cardCVV.hasError('minlength')">
          Minimum length is 3
        </mat-error>
        <mat-icon matSuffix>payment</mat-icon>
        <mat-hint>3-digit number on the back</mat-hint>
      </mat-form-field>
      <div *ngIf="optionalSave" class="card__checkbox">
        <mat-checkbox formControlName="save"           data-cy="optional-save-card-checkbox">
          <span class="card__save-text"
            >Save this card information to my account for all future payments &
            auto drafts (where applicable)</span
          >
        </mat-checkbox>
      </div>
      <div class="card__confirm">
        <button
          *ngIf="cancelable"
          (click)="this.cancel()"
          mat-raised-button
          class="card__button cancel"
          data-cy="cardCancel"
        >
          <span class="dont-change-button-text"> Don't Change </span>
        </button>
      </div>
    </mat-card>
  </form>
</div>
