import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Client } from '@hyp2/graphql';

@Component({
  selector: 'hyp2-member-info-card',
  templateUrl: './member-info-card.component.html',
  styleUrls: ['./member-info-card.component.scss']
})
export class MemberInfoCardComponent implements OnInit {
  @Input() client: Client;
  @Output() openVerify = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
