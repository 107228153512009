<h3 mat-dialog-title>{{ this.data.title }}</h3>
<mat-dialog-content
  ><strong data-cy="dialog-description">{{
    this.data.description
  }}</strong></mat-dialog-content
>
<mat-dialog-actions>
  <button *ngIf="this.data?.hasCancel" mat-button mat-dialog-close>
    Cancel
  </button>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
