import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';

import * as fromAuth from '../../../auth/reducers';
import * as fromProfile from '../../../profile/reducers';
import { SelectedItem } from '../../../checkout/models';
import { ClientMembership, BillingInput } from '@hyp2/graphql';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { UpdateBillingActions } from '../../../profile/actions';

export interface DialogData {
  type: string;
  title: string;
  description: string;
  successTitle: string;
  successDescription: string;
  showSuccess: boolean;
  recurringFee: number;
}

@Component({
  selector: 'hyp2-account-info-dialog',
  templateUrl: './account-info-dialog.component.html',
  styleUrls: ['./account-info-dialog.component.scss'],
})
export class AccountInfoDialogComponent implements OnInit, OnDestroy {
  @ViewChild('dialogContent', { static: false }) dialogContent;

  type = this.data.type;
  title = this.data.title;
  description = this.data.description;
  successTitle = this.data.successTitle;
  successDescription = this.data.successDescription;
  showSuccess = this.data.showSuccess;

  client$ = this.authStore.pipe(select(fromAuth.getClient));
  balance$ = this.authStore.pipe(select(fromAuth.getClientBalance));
  loading$: Observable<Boolean | boolean>;

  public clientBilling: BillingInput;
  public item: SelectedItem;
  public agreed = false;

  private clientSub: Subscription;

  constructor(
    private authStore: Store<fromAuth.AuthState>,
    private profileStore: Store<fromProfile.ProfileState>,
    public dialogRef: MatDialogRef<AccountInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    combineLatest([
      this.authStore.pipe(select(fromAuth.getFilteredClientMemberships)),
      this.authStore.pipe(select(fromAuth.getClientBilling)),
    ])
      .pipe(take(1))
      .subscribe(([memberships, billing]) => {
        const currentMem = memberships.find(this.isCurrentMembership);
        this.item = {
          title: currentMem.membership.name,
          categoryInfo: currentMem.membership.desc.shortDescrip,
          membershipId: currentMem.membership.id,
          image: '',
          cost: billing?.recurringFee || currentMem.membership.price,
          taxedCost: currentMem.membership.taxedPrice,
          eft: currentMem.membership.mode === 'EFT',
        };
      });
  }

  ngOnDestroy() {
    this.clientSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.loading$ = combineLatest([
      this.authStore.select(fromAuth.getLoginLoading),
      this.profileStore.select(fromProfile.getUpdateBillingLoading),
    ]).pipe(
      map(([clientLoading, billingLoading]) => clientLoading || billingLoading),
    );
    this.client$ = this.authStore.select(fromAuth.getClient);
    this.balance$ = this.authStore.pipe(select(fromAuth.getClientBalance));

    this.clientSub = this.authStore
      .select(fromAuth.getClient)
      .pipe(
        tap(_ => {
          this.agreed = false;
        }),
      )
      .subscribe();
  }

  isCurrentMembership = (cm: ClientMembership): Boolean => {
    return (
      (cm.membership.mode === 'EFT' && !cm.status.canceled) ||
      (cm.membership.mode === 'YEARLY' &&
        cm.status.active &&
        !cm.status.canceled)
    );
  };

  cardFormEvent($event: any) {
    this.clientBilling = $event;
    this.agreed = false;
    if ($event) {
      this.dialogContent.nativeElement.scrollTop = this.dialogContent.nativeElement.scrollHeight;
    }
  }

  confirmAgreed($event) {
    if ($event) {
      this.agreed = true;
    } else {
      this.agreed = false;
    }
  }

  submit() {
    this.profileStore.dispatch(
      UpdateBillingActions.updateBillingRequest({
        billing: this.clientBilling,
      })
    );
  }

  closeSuccess() {
    this.dialogRef.close();
  }
}
