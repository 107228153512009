<mat-card class="card mat-elevation-z6">
  <mat-card-title class="card__title" data-cy="login__title">{{
    cardTitle
  }}</mat-card-title>
  <mat-card-content class="card__forms">
    <mat-form-field appearance="outline" formGroupName="clientAuth">
      <mat-label>Drivers License Number</mat-label>
      <input
        class="form-control"
        formControlName="license"
        name="license"
        id="license"
        required
        matInput
        placeholder="Enter Drivers License Number"
        autocomplete="on"
        data-cy="login__license-input"
      />
      <mat-icon matSuffix>picture_in_picture</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline" formGroupName="clientAuth">
      <mat-label>Cell Phone Number</mat-label>
      <input
        type="tel"
        autocomplete="tel-national"
        [pattern]="mobnumPattern"
        matInput
        placeholder="555-555-1234"
        formControlName="cell"
        mask="(000) 000-0000"
        data-cy="login__phone-number-input"
      />
      <mat-icon matSuffix>phone</mat-icon>
      <mat-hint>Include area code</mat-hint>
      <mat-error *ngIf="this.cell.hasError('startingOne')">
        Phone Number can't start with +1
      </mat-error>
      <mat-error *ngIf="this.cell.hasError('minlength')">
        Phone number is to short
      </mat-error>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions class="card__actions">
    <button
      class="card__button"
      [disabled]="this.cell.errors || this.license.errors || loading"
      mat-raised-button
      (click)="buttonEvent.emit()"
      data-cy="login__submit"
    >
      {{ buttonText }}
    </button>
  </mat-card-actions>
  <div class="card__info">
    <p>{{ cardInfo }}</p>
  </div>
</mat-card>
