import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeMembershipTo } from '@hyp2/graphql';
import { MembershipChanges } from '../../models';

@Component({
  selector: 'hyp2-change-membership-choices',
  templateUrl: './change-membership-choices.component.html',
  styleUrls: ['./change-membership-choices.component.scss'],
})
export class ChangeMembershipChoicesComponent{
  @Output() membershipSelected = new EventEmitter();
  @Input() showMonthlyMemberships: boolean
  @Input() membershipChanges: ChangeMembershipTo[];
  @Input() defaultSelection: ChangeMembershipTo;

  newMembership: ChangeMembershipTo;
  isDefault(membership: MembershipChanges) {
    return membership === this.defaultSelection;
  }
}
