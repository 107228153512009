import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Client } from '@hyp2/graphql';

@Injectable({ providedIn: 'root' })
export class MonitorClientService {
  setClient(client: Client): void {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: client.id,
      });
    });
  }
}
