import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

import * as fromAuth from '../../../auth/reducers';
import * as fromPayBalance from '../../../pay-balance/reducers';
import { PayBalanceActions } from '../../actions';
import { SelectedItem } from '../../../checkout/models';
import { BillingInput } from '@hyp2/graphql';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'hyp2-pay-balance',
  templateUrl: './pay-balance.component.html',
  styleUrls: ['./pay-balance.component.scss'],
})
export class PayBalanceComponent implements OnInit {
  title = 'Make a Payment';
  description = 'Your current balance is shown below.';

  client$ = this.authStore.pipe(select(fromAuth.getClient));
  balance$ = this.authStore.pipe(select(fromAuth.getClientBalance));
  loading$ = this.profileStore.pipe(
    select(fromPayBalance.getPayBalanceLoading)
  );

  public clientBilling$ = new BehaviorSubject<BillingInput>(null);
  public item: SelectedItem;

  public agreed = false;

  constructor(
    private metaService: Meta,
    private profileStore: Store<fromPayBalance.State>,
    private authStore: Store<fromAuth.AuthState>,
  ) {
    combineLatest([
      this.authStore.select(fromAuth.getFilteredClientMemberships),
      this.authStore.select(fromAuth.getClientBilling),
    ])
      .pipe(take(1))
      .subscribe(([memberships, billing]) => {
        const activeCm = memberships.find((mem) => mem.status.active);
        this.item = {
          title: activeCm?.membership?.name,
          categoryInfo: activeCm?.membership?.desc?.shortDescrip,
          membershipId: activeCm?.membership?.id,
          image: '',
          cost: billing?.recurringFee || activeCm?.membership?.price,
          taxedCost: activeCm?.membership?.taxedPrice,
          eft: false,
        };
      });
  }

  ngOnInit(): void {
    this.metaService.addTags([
      {
        name: 'description',
        content:
          'Pay Your Zoom Tan Balance Here. Login With Your Cell Phone And Driver’s License Numbers To Check Your Balance, Pay Your Balance, Update Your Card Information & More',
      },
    ]);
  }

  setClientBilling(billing?): void {
    this.clientBilling$.next(billing);
  }

  get clientBilling(): BillingInput{
    return this.clientBilling$.value
  }

  paymentCardEvent($event: any) {
    this.setClientBilling($event);
    this.agreed = false;
  }

  confirmAgreed($event) {
    if ($event) {
      this.agreed = true;
    } else {
      this.agreed = false;
    }
  }

  submit() {
    this.profileStore.dispatch(
      PayBalanceActions.payBalanceRequest({
        billing: {
          ...this.clientBilling,
          save: this.item?.membershipId ? this.clientBilling?.save : false,
        },
      })
    );
  }
}
