<div class="dialog">
  <h1 class="dialog__title" data-cy="dialog__title">{{ data.title }}</h1>
  <p class="dialog__description">{{ data.description }}</p>
  <br />
  <div class="dialog__buttons">
    <button
      [mat-dialog-close]
      routerLink="/my-account"
      class="dialog__button"
      mat-raised-button
      data-cy="activeMem__my-account-button"
    >
      My Account
    </button>
    <button
      *ngIf="!data?.current && !data?.cancelled && client$ | async as client"
      [mat-dialog-close]
      routerLink="/change-membership"
      [queryParams]="{ promo: data.promo }"
      class="dialog__button"
      color="accent"
      mat-raised-button
      data-cy="activeMem__change-button"
    >
      Change
    </button>
    <button
      *ngIf="data?.year && !data?.itemEft"
      color="primary"
      [mat-dialog-close]
      class="dialog__button"
      mat-raised-button
      data-cy="activeMem__continue-button"
    >
      Continue
    </button>
  </div>
</div>
