import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { UpdateBillingActions } from '../actions';
import { UpdateBillingService } from '../services';
import { AccountInfoDialogService } from '../../shared/services';
import { EMPTY, empty, of } from 'rxjs';
import { RefreshClientActions } from '../../auth/actions';

@Injectable()
export class UpdateBillingEffects {
  private readonly FORM_MISMATCH_CODE = '4110';

  updateBilling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateBillingActions.updateBillingRequest),
      switchMap((action) =>
        this.updateBillingService.updateBilling(action.billing).pipe(
          switchMap((response) =>
            of(
              UpdateBillingActions.updateBillingSuccess({
                response: response.data,
              })
            )
          ),
          catchError((error) =>
            of(
              UpdateBillingActions.updateBillingFailure({
                error: error,
                errorCode: error.graphQLErrors.map(
                  (x) => x.extensions?.code
                )[0],
              })
            )
          )
        )
      )
    )
  );

  updateBillingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateBillingActions.updateBillingSuccess),
        tap((action) => {
          if (action.response.updateBilling) {
            this.accountInfoDialogService.success();
          }
        })
      ),
    { dispatch: false }
  );

  updateBillingFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateBillingActions.updateBillingFailure),
      switchMap((action) => {
        if (action.errorCode === this.FORM_MISMATCH_CODE) {
          return of(RefreshClientActions.refreshClientRequest());
        }
        return EMPTY;
      })
    )
  );

  constructor(
    private actions$: Actions,
    private updateBillingService: UpdateBillingService,
    private accountInfoDialogService: AccountInfoDialogService
  ) {}
}
