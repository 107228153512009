import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { CancelMembershipService } from '../services';

import * as fromAuth from '../../auth/reducers';
import {
  CancelMembershipActions,
  CancelMembershipLogActions,
} from '../actions';

@Injectable()
export class CancelMembershipEffects {
  cancelMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CancelMembershipActions.cancelMembershipRequest),
      switchMap((action) =>
        this.cancelMembershipService.cancelMembership(action.fulfill).pipe(
          map((response) =>
              CancelMembershipActions.cancelMembershipSuccess({
                form: response.data.cancelMembership.form,
                done: response.data.cancelMembership.done,
              })
          ),
          catchError((error) =>
            of(
              CancelMembershipActions.cancelMembershipFailure({
                error:
                  error?.graphQLErrors?.shift()?.message ||
                  'Contact Customer Service',
              })
            )
          )
        )
      )
    )
  );

  cancelMembershipSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CancelMembershipActions.cancelMembershipSuccess),
      withLatestFrom(
        this.authStore$.select(fromAuth.getClient),
        this.authStore$.select(fromAuth.getActiveClientMembership)
      ),
      switchMap(([action, client, cm]) =>
        of(
          CancelMembershipLogActions.cancelMembershipLogRequest({
            license: client.license.number,
            cell: client.contact.cell.number,
            message: action.done
              ? 'Client Successfully Cancelled'
              : `Cancellation for ${cm?.membership?.name} is being viewed`,
            success: action.done ? 1 : 0,
          })
        )
      )
    )
  );

  cancelMembershipFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CancelMembershipActions.cancelMembershipFailure),
      withLatestFrom(this.authStore$.select(fromAuth.getClient)),
      switchMap(([action, client]) =>
        of(
          CancelMembershipLogActions.cancelMembershipLogRequest({
            license: client.license.number,
            cell: client.contact.cell.number,
            message: action.error,
            success: 0,
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authStore$: Store<fromAuth.AuthState>,
    private cancelMembershipService: CancelMembershipService
  ) {}
}
