import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AccountInfoDialogComponent, DialogData } from '../components';

@Injectable({
  providedIn: 'root',
})
export class AccountInfoDialogService {
  dialogRef: MatDialogRef<AccountInfoDialogComponent>;
  successTitle: string;
  successDescription: string;

  constructor(public dialog: MatDialog) {}

  public openDialog(dialogData: DialogData) {
    this.dialogRef = this.dialog.open(AccountInfoDialogComponent, {
      data: {
        type: dialogData.type,
        title: dialogData.title,
        description: dialogData.description,
      },
    });
    this.successTitle = dialogData.successTitle;
    this.successDescription = dialogData.successDescription;
  }

  public success() {
    this.dialogRef.close();
    this.dialogRef = this.dialog.open(AccountInfoDialogComponent, {
      data: {
        showSuccess: true,
        successTitle: this.successTitle,
        successDescription: this.successDescription,
      },
    });
  }
}
