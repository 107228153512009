import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { UtilitiesService } from '../../services/utilities.service';
import moment from 'moment';
@Component({
  selector: 'hyp2-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class RegisterFormComponent implements OnInit {
  @Output() buttonEvent = new EventEmitter();
  @Input() loading: Boolean;
  registerForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  dob: FormControl;
  license: FormControl;
  phoneNumber: FormControl;
  sms: FormControl;
  clientData: FormGroup;
  pattern = new RegExp("^[aA-zZ]*?[aA-zZ'-]*$");
  customPatterns = { Y: { pattern: this.pattern }, Symbol: 'X' };

  constructor(
    private registerFormParent: FormGroupDirective,
    private utilService: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.registerFormParent.form;

    this.firstName = new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(30),
      Validators.pattern(this.pattern),
    ]);

    this.lastName = new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(30),
      Validators.pattern(this.pattern),
    ]);

    this.dob = new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      this.dobEarly,
      this.dobPastNow,
      this.dobYoungerThanFourteen,
    ]);

    this.license = new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.pattern('^[a-zA-Z0-9-]*$'),
    ]);

    this.phoneNumber = new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
      this.utilService.phoneNumberCheck,
    ]);

    this.sms = new FormControl(false, Validators.requiredTrue);

    this.clientData = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      dob: this.dob,
      license: this.license,
      phoneNumber: this.phoneNumber,
      sms: this.sms,
    });

    this.registerForm.addControl('clientData', this.clientData);
  }

  dobEarly(c: FormControl) {
    const dob = c.value;
    return moment(dob).isBefore(moment('1910-01-01'))
      ? { dobEarly: true }
      : null;
  }

  dobPastNow(c: FormControl) {
    const dob = c.value;
    return moment(dob).isAfter(moment()) ? { dobPastNow: true } : null;
  }

  dobYoungerThanFourteen(c: FormControl) {
    const dob = c.value;
    return moment().diff(dob, 'years') < 14 ? { dobYoungerThanFourteen: true } : null;
  }
}
