import { createReducer, on } from "@ngrx/store";
import { UpdateBillingActions } from '../actions';

export const updateBillingFeatureKey = 'updateBilling';

export interface State {
  response: boolean;
  loading: boolean;
  error: string;
};

export const initialState: State = {
  response: undefined,
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(UpdateBillingActions.updateBillingRequest, (state) => ({ ...state, error: null, loading: true })),
  on(UpdateBillingActions.updateBillingSuccess, (state, { response }) => ({ ...state, response, loading: false })),
  on(UpdateBillingActions.updateBillingFailure, (state, { error }) => ({ ...state, loading: false })),
  );

export const getResponse = (state: State) => state.response;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
