import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class OnfidoEnabledGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (
      environment.onfidoEnabled ||
      window.localStorage.forceOnfido === 'true'
    ) {
      return true;
    } else {
      this.router.navigate(['/checkout']);
      return false;
    }
  }
}
