import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { TextResendActions } from '../../../auth/actions';
import * as fromAuth from '../../../auth/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

export interface VerifyCellDialogData {
  title: string;
  description: string;
}

@Component({
  selector: 'hyp2-verify-cell-dialog',
  templateUrl: './verify-cell-dialog.component.html',
  styleUrls: ['./verify-cell-dialog.component.scss'],
})
export class VerifyCellDialogComponent implements OnInit {
  pin: string;
  pinField: FormControl;
  constructor(
    public dialogRef: MatDialogRef<VerifyCellDialogComponent>,
    private authStore: Store<fromAuth.State>,
    @Inject(MAT_DIALOG_DATA) public data: VerifyCellDialogData
  ) {}

  message$: Observable<string>;

  ngOnInit(): void {
    this.pinField = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.pattern('^[0-9]*$'),
    ]);
  }
  textResend() {
    this.authStore.dispatch(TextResendActions.textResendRequest());
  }
}
