<div class="container">
  <h1 class="title">Receipts</h1>
  <mat-card class="card mat-elevation-z6">
    <h2 class="card__title" data-cy="receipts__title">All Receipts</h2>
    <mat-card-content>
      <mat-list data-cy="receipt-list">
        <div *ngFor="let receipt of receipts" data-cy="receipt-item">
          <mat-divider></mat-divider>
          <mat-list-item data-cy="receipts__createdAt">{{
            receipt.createdAt | date
          }}</mat-list-item>
          <mat-list-item class="card__data">
            <span data-cy="receipts__price">{{
              receipt.amount | currency
            }}</span>
            <button
              (click)="buttonEvent.emit(receipt.agreement)"
              class="card__view-button"
              data-cy="receipts__view-button"
            >
              View
            </button>
          </mat-list-item>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
