import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { DetailsDialogService } from '../../../shared/services';
import { MembershipChanges } from '../../models';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../shared/components';
import { Router } from '@angular/router';
import { PromosService } from '../../../promo/promos.service';
import { PromoComponent } from '../../../promo/main/promo.component';
import { ChangeMembershipInfo } from '@hyp2/graphql';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'hyp2-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss'],
})
export class ChangeComponent implements OnInit, OnDestroy {
  @Input() membership: MembershipChanges;
  @Input() isDefault: boolean;
  notAvailable = 'No monthly memberships available';
  offerDetails(change: ChangeMembershipInfo) {
    const { saved, extraDays } = change;
    return PromoComponent.offerDetails(saved, extraDays);
  }
  restricted: boolean;
  data: {};

  promoAvailableSub: Subscription;

  constructor(
    private detailsDialogService: DetailsDialogService,
    private dialog: MatDialog,
    private router: Router,
    private promosService: PromosService,
  ) {}

  ngOnInit() {
    const { start, end, promo, previousPromoRestrictions, clientRestrictions } = this.membership.newMembership.sale;
    this.promoAvailableSub = combineLatest(
      PromoComponent.checkOfferAvailable(start, end),
      this.promosService.checkClientPromoStatus(promo, previousPromoRestrictions, clientRestrictions),
    )
    .subscribe(([available, promoUsedData]) => {
      if (!available) {
        this.restricted = true;
        this.data = PromosService.promoUnavailableData;
      } else if (promoUsedData) {
        this.restricted = true;
        this.data = promoUsedData;
      }
    });
  }

  ngOnDestroy() {
    this.promoAvailableSub?.unsubscribe();
  }

  openMonthlyDetails() {
    this.detailsDialogService.openDialog(this.membership.newMembership, null);
  }

  openYearlyDetails() {
    this.detailsDialogService.openDialog(null, this.membership.newMembership);
  }

  openRestrictedDetails() {
    this.dialog.open(DialogComponent, {
      data: this.data
    });
  }
}
