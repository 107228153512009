import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import * as fromCancelMembership from '../../../cancel-membership/reducers';
import * as fromAuth from '../../../auth/reducers';
import * as fromCore from '../../../core/reducers';
import { CancelMembershipActions } from '../../actions';
import { PortalMembershipsActions } from '../../../core/actions';
import { SEOService } from '../../../../../src/app/shared/services/SEO.service';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'hyp2-cancel-membership',
  templateUrl: './cancel-membership.component.html',
  styleUrls: ['./cancel-membership.component.scss'],
})
export class CancelMembershipComponent implements OnInit {
  form$ = this.cancelMembershipStore.pipe(
    select(fromCancelMembership.getCancelMembershipForm)
  );
  loaded$: Observable<Boolean>;
  success$ = this.cancelMembershipStore.pipe(
    select(fromCancelMembership.getCancelMembershipDone)
  );
  error$ = this.cancelMembershipStore.pipe(
    select(fromCancelMembership.getCancelMembershipError)
  );
  warning$: Observable<boolean>;
  client$ = this.authStore.pipe(select(fromAuth.getClient));
  memberships$ = this.coreStore.pipe(select(fromCore.getMembershipIds));
  clientMemberships$ = this.authStore.pipe(select(fromAuth.getFilteredClientMemberships));
  activeClientMembership$ = this.authStore.pipe(select(fromAuth.getActiveClientMembership));
  memExist$: Observable<boolean>;

  constructor(
    private seo: SEOService,
    private cancelMembershipStore: Store<
      fromCancelMembership.CancelMembershipState
    >,
    private authStore: Store<fromAuth.AuthState>,
    private coreStore: Store<fromCore.CoreState>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (!isPlatformServer(this.platformId)) {
      this.coreStore.dispatch(
        PortalMembershipsActions.getMembershipsRequest({ totalCount: 20 })
      );
      this.cancelMembershipStore.dispatch(
        CancelMembershipActions.cancelMembershipRequest({
          fulfill: false,
        })
      );
    }
  }

  ngOnInit() {
    this.seo.generateTags([
      {
        title: 'Cancel Membership',
        description:
          'Cancel Your Zoom Tan Membership Here With The Driver’s License & Cell Phone Number Associated With Your Zoom Tan Account',
      },
    ]);
    this.loaded$ = combineLatest([
      this.cancelMembershipStore.pipe(
        select(fromCancelMembership.getCancelMembershipLoaded)
      ),
      this.coreStore.pipe(select(fromCore.getPortalMembershipsLoaded)),
    ]).pipe(map(([cancel, portal]) => cancel && portal));

    this.memExist$ = this.getMemExist();
  }

  cancelMembership() {
    this.cancelMembershipStore.dispatch(
      CancelMembershipActions.cancelMembershipRequest({ fulfill: true })
    );
  }

  getMemExist(): Observable<boolean> {
    return this.activeClientMembership$.pipe(
      map((clientMembership) => {
        return !!clientMembership?.membership?.portal;
      })
    );
  }
}
