import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Details {
  title: string;
  description: string[];
}
export interface DetailsDialog {
  monthly: Details;
  yearly: Details;
  package: Details;
}

@Component({
  selector: 'hyp2-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss']
})
export class DetailsDialogComponent implements OnInit {
  monthlyDetails = this.data.monthly;
  yearlyDetails = this.data.yearly;
  packageDetails = this.data.package;
  buttonText = 'Okay';

  constructor(
    public dialogRef: MatDialogRef<DetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DetailsDialog
  ) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
