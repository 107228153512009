import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  constructor() {}
  private upperCaseFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  private lowerCaseAllWordsExceptFirstLetters = (string) => {
    return string.replace(/\w\S*/g, function (word) {
      return word.charAt(0) + word.slice(1).toLowerCase();
    });
  };
  public fixCapitalize (input) {
    return this.upperCaseFirstLetter(
      this.lowerCaseAllWordsExceptFirstLetters(input)
    );
  };
  public phoneNumberCheck(c: FormControl){
    const number: string = c.value;
    return (+number?.charAt(0) === 1) ?  {startingOne: true} : null
  }
}
