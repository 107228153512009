import { Client } from '@hyp2/graphql';
import { createAction, props } from '@ngrx/store';
import { OnfidoDocument } from '../types/OnfidoDocument';

export const requestOnfidoSdkToken = createAction('[Onfido] Request Onfido SDK Token');
export const requestOnfidoSdkTokenSuccess = createAction('[Onfido] Request Onfido SDK Token Success', props<{ token: string, applicantId: string }>());
export const onfidoFlowComplete = createAction('[Onfido] Flow complete', props<{ documents: OnfidoDocument[], livePhotoId: string }>());
export const onfidoPhoneNumberSubmitted = createAction('[Onfido] Phone Number Submitted', props<{ phoneNumber: string }>());
export const onfidoUserRegistered = createAction('[Onfido] User Registered', props<{ newClient: boolean, texted: boolean, client: Client }>());
export const onfidoFlowFailed = createAction('[Onfido] Flow Failed');
export const onfidoFlowRestarted = createAction('[Onfido] Flow Restarted');
export const onfidoStateReset = createAction('[Onfido] Reset State');
