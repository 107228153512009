import { NgModule, PLATFORM_ID, Inject } from '@angular/core';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { environment } from '../environments/environment';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../schema/fragmentTypes.json';
import { SnackbarService } from './shared/services/snackbar.service';
import { errMap, errorMapExclude, defaultError } from '../schema';
import {
  makeStateKey,
  TransferState,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';

const uri = environment.graphqlEndpoint;
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const STATE_KEY = makeStateKey<any>('apollo.state');

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  imports: [TransferHttpCacheModule, BrowserTransferStateModule],
})
export class GraphQLModule {
  cache: InMemoryCache;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private apollo: Apollo,
    private httpLink: HttpLink,
    private snackbarService: SnackbarService,
    private readonly transferState: TransferState
  ) {
    this.cache = new InMemoryCache({ fragmentMatcher });
    const link = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors?.length > 0) {
        graphQLErrors.map(({ message, extensions }) => {
          const errorMessage = this.getErrorMessage(message, extensions);
          const errorExcluded = this.isErrorExcludedFromSnackBar(extensions);
          if (!errorExcluded) this.snackbarService.open(errorMessage);
        });
      } else if (networkError) {
        return this.snackbarService.open('Network Error');
      }
    });

    const isBrowser = isPlatformBrowser(this.platformId);
    const hasStateKey = this.transferState.hasKey<any>(STATE_KEY);

    this.apollo.create(
      {
        link: ApolloLink.from([link, this.httpLink.create({ uri })]),
        cache: this.cache,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
          },
          query: {
            fetchPolicy: 'no-cache',
          },
          mutate: {
            fetchPolicy: 'no-cache',
          },
        },
        ...(isBrowser ? { ssrForceFetchDelay: 200 } : { ssrMode: true }),
      },
      'default'
    );
    if (isBrowser && hasStateKey) {
      this.onBrowser();
    } else if (isPlatformServer) {
      this.onServer();
    }
  }
  onServer() {
    this.transferState.onSerialize(STATE_KEY, () => this.cache.extract());
  }

  onBrowser() {
    const state = this.transferState.get<NormalizedCacheObject>(
      STATE_KEY,
      null
    );
    this.cache.restore(state);
  }
  private getErrorMessage(
    message: string,
    extensions: { [key: string]: any }
  ): string {
    if (
      extensions.code === 'INTERNAL_SERVER_ERROR' &&
      !environment.production
    ) {
      return message;
    } else if (
      extensions.code === 'INTERNAL_SERVER_ERROR' &&
      environment.production
    ) {
      return 'Unknown Error: It seems something went wrong. Please try again.';
    } else {
      const errorMessage = errMap[extensions.serviceName][extensions.code];
      return errorMessage !== ('' || null || undefined)
        ? errorMessage
        : defaultError;
    }
  }

  private isErrorExcludedFromSnackBar(extensions: {
    [key: string]: any;
  }): boolean {
    const errors = errorMapExclude[extensions.serviceName];
    if (errors !== undefined) {
      return errors.find((code) => code === extensions.code);
    }
  }
}
