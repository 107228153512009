import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { UtilitiesService } from '../../../shared/services/utilities.service';

@Component({
  selector: 'hyp2-verify-client',
  templateUrl: './verify-client.component.html',
  styleUrls: ['./verify-client.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class VerifyClientComponent implements OnInit {
  @Output() buttonEvent = new EventEmitter();
  @Input() loading = false;

  private authForm: FormGroup;
  cardTitle = 'access profile';
  mobnumPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  buttonText = 'login';
  cardInfo =
    "Please login to our member portal with the driver's license and phone number that you provided for your Zoom Tan account.  No registration is required for current or previous members.";
  license: FormControl;
  cell: FormControl;
  constructor(
    private authParent: FormGroupDirective,
    private utilService: UtilitiesService
  ) {}

  ngOnInit() {
    this.authForm = this.authParent.form;

    this.license = new FormControl('');
    this.cell = new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9 ]*$'),
      this.utilService.phoneNumberCheck,
    ]);
    const clientAuth = new FormGroup({
      license: this.license,
      cell: this.cell,
    });

    this.authForm.addControl('clientAuth', clientAuth);
  }
}
