import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BrowserService {
  constructor(private platform: Platform) {}
  public isSupportedBrowser() {
    return !this.platform.TRIDENT;
  }
}
