import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class GoogleComponent extends PromoComponent {
  // id
  static path = 'zt0222sbdp';
  static promoCode = 'P2';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.MONTHLY;

  // display
  imageFileDesktop = '/assets/images/promos/social-desktop.jpg';
  imageFileMobile = '/assets/images/promos/social-mobile.jpg';
  header = 'SAVE BIG ON A MONTH OF UNLIMITED UV AND/OR SPRAY TANNING!';
  text = 'With this 2022 exclusive special offer, new and returning members can try us out and save up to 72% on their first tanning month! We do not require any long-term commitments or contracts. Cancellation is FREE and can be completed online any time after just 10 days from the start date. Members who LOVE our service can continue after the first month at regular pricing or opt to cancel and use the remainder of the tanning month that they purchased.';
}
