import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../auth/services';

import { PayBalanceComponent, PayBalanceSuccessComponent } from './containers';
import { PayBalanceGuardService } from './services';

const routes: Routes = [
  {
    path: '',
    component: PayBalanceComponent,
    canActivate: [AuthGuardService, PayBalanceGuardService],
    pathMatch: 'full',
    data: {
      title: 'Pay Balance',
      description:
        'Pay Your Zoom Tan Balance Here. Login With Your Cell Phone And Driver’s License Numbers To Check Your Balance, Pay Your Balance, Update Your Card Information & More',
    },
  },
  {
    path: 'success',
    pathMatch: 'full',
    component: PayBalanceSuccessComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Pay Balance Success',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PayBalanceRoutingModule {}
