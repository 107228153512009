import { createReducer, on } from '@ngrx/store';
import {
  ChangeMembershipInfoActions,
  ChangeMembershipActions,
} from '../actions';
import { LogoutActions } from '../../auth/actions';
import { ChangeMembership } from '@hyp2/graphql';

export const changeMembershipInfoFeatureKey = 'changeInfo';

export interface State {
  changeMembershipInfo: ChangeMembership;
  loading: boolean;
  error: string;
}

export const initialState: State = {
  changeMembershipInfo: undefined,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(ChangeMembershipInfoActions.changeMembershipInfoRequest, (state) => ({
    ...state,
    error: null,
    loading: true,
  })),
  on(
    ChangeMembershipInfoActions.changeMembershipInfoSuccess,
    (state, { changeMembershipInfo }) => ({
      ...state,
      changeMembershipInfo,
      loading: false,
    })
  ),
  on(
    ChangeMembershipInfoActions.changeMembershipInfoFailure,
    (state, { error }) => ({ ...state, error, loading: false })
  ),
  on(ChangeMembershipActions.changeMembershipSuccess, (state) => initialState),
  on(LogoutActions.logoutClient, (state) => initialState)
);

export const getAllChangeMembershipInfo = (state: State) =>
  state.changeMembershipInfo;
export const getMembershipChanges = (state: State) =>
  state?.changeMembershipInfo?.changeMembershipTo;

export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
