import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import gql from 'graphql-tag';
import { TermsAndConditionsService } from '../../../services/terms-and-conditions.service';
import { SelectedItem } from '../../../../checkout/models';

import { BillingInput, ClientMembership, Client } from '@hyp2/graphql';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

const GET_MEMBERSHIP_FORM = gql`
  query getMembershipForms(
    $billingName: String!
    $cardNumber: String!
    $membershipId: ID!
  ) {
    getMembershipForms(
      billingName: $billingName
      cardNumber: $cardNumber
      membershipId: $membershipId
    ) {
      membershipForm
      uvSprayForm
    }
  }
`;
@Component({
  selector: 'hyp2-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  @Input() client: Client;
  @Input() clientBilling: BillingInput;
  @Input() item: SelectedItem;
  @Input() agreed: Boolean;
  @Input() showAgreement = false;
  @Output() buttonEvent = new EventEmitter();
  public currentMembership: ClientMembership[];
  private querySub: Subscription;

  constructor(
    private termsAndConditionsService: TermsAndConditionsService,
    private apollo: Apollo
  ) {}

  ngOnInit(): void {
    this.currentMembership = this.client?.clientMemberships?.memberships?.filter(
      (mem) =>
        mem?.status?.active &&
        !mem.status.canceled &&
        mem.membership.mode === 'EFT'
    );
  }

  emitEvent($event) {
    this.buttonEvent.emit($event.checked);
  }

  async openTermsAndConditions() {
    let billingName, cardNumber: string;
    const membershipId =
      this.item?.membershipId ?? this.currentMembership[0]?.id;

    if (!this.clientBilling) {
      billingName = this.client?.billing?.billingName;
      cardNumber = this.client?.billing?.cardInfo?.lastFourCardNumber;
    } else {
      billingName = this.clientBilling?.billingName;
      cardNumber = this.clientBilling?.cardNumber;
    }
    this.querySub = this.apollo
      .query<any>({
        query: GET_MEMBERSHIP_FORM,
        variables: {
          billingName: billingName,
          cardNumber: cardNumber.substr(cardNumber.length - 4),
          membershipId,
        },
      })
      .pipe(first())
      .subscribe((data) => {
        this.termsAndConditionsService?.closeDialog();
        this.termsAndConditionsService.openDialog([
          this.showAgreement
            ? data?.data?.getMembershipForms?.membershipForm
            : null,
          data?.data?.getMembershipForms?.uvSprayForm,
        ]);
      });
  }
  ngOnDestroy() {
    this.querySub?.unsubscribe();
  }
}
