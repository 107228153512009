import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { clientFields, registerClientMembershipFields } from '../../auth/services';
import { OnfidoDocument } from '../types/OnfidoDocument';

@Injectable({
  providedIn: 'root',
})
export class OnfidoService {
  constructor(private readonly apollo: Apollo) {}

  getSdkToken(): Observable<any> {
    return this.apollo.mutate({
      mutation: generateOnfidoSdkToken,
    });
  }

  registerUser(applicantId: string, documents: OnfidoDocument[], livePhotoId: string, phoneNumber: string): Observable<any> {
    return this.apollo.mutate({
      mutation: registerOnfidoUser,
      variables: {
        input: {
          applicantId,
          documents,
          livePhotoId,
          cell: phoneNumber,
        },
      },
    });
  }
}

const generateOnfidoSdkToken = gql`
  mutation {
    generateOnfidoSdkToken {
      token,
      applicantId
    }
  }
`;

const registerOnfidoUser = gql`
  ${registerClientMembershipFields}

  mutation registerOnfidoUser($input: OnfidoRegistrationInput!) {
    registerOnfidoUser(input: $input) {
      ${clientFields}
    }
  }
`;
