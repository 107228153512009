import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: any;
  /**
   * A date string, such as 2007-12-03, compliant with the `full-date` format
   * outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
   */
  Date: any;
  LicenseNumber: any;
  /** Floats that will have a value of 0 or more. */
  UnsignedFloat: any;
  /** Floats that will have a value greater than 0. */
  PositiveFloat: any;
  /** A field value for US phone number. */
  PhoneNumber: any;
  /**
   * A field whose value conforms to the standard internet email address format as
   * specified in RFC822: https://www.w3.org/Protocols/rfc822/.
   */
  EmailAddress: any;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  GUID: any;
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: any;
  /** A field whose value is a CSS HSL color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSL: any;
  /** A field whose value is a CSS HSLA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSLA: any;
  /** A field whose value is a IPv4 address: https://en.wikipedia.org/wiki/IPv4. */
  IPv4: any;
  /** A field whose value is a IPv6 address: https://en.wikipedia.org/wiki/IPv6. */
  IPv6: any;
  /** A field whose value is a ISBN-10 or ISBN-13 number: https://en.wikipedia.org/wiki/International_Standard_Book_Number. */
  ISBN: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: any;
  /** A field whose value is a IEEE 802 48-bit MAC address: https://en.wikipedia.org/wiki/MAC_address. */
  MAC: any;
  /** Floats that will have a value less than 0. */
  NegativeFloat: any;
  /** Integers that will have a value less than 0. */
  NegativeInt: any;
  /** Floats that will have a value of 0 or less. */
  NonPositiveFloat: any;
  /** Integers that will have a value of 0 or less. */
  NonPositiveInt: any;
  /**
   * A field whose value is a valid TCP port within the range of 0 to 65535:
   * https://en.wikipedia.org/wiki/Transmission_Control_Protocol#TCP_ports
   */
  Port: any;
  /** Integers that will have a value greater than 0. */
  PositiveInt: any;
  /**
   * A field whose value conforms to the standard postal code formats for United
   * States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands,
   * Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg.
   */
  PostalCode: any;
  /** A field whose value is a CSS RGB color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGB: any;
  /** A field whose value is a CSS RGBA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGBA: any;
  /**
   * A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format
   * outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
   */
  Time: any;
  /** Integers that will have a value of 0 or more. */
  UnsignedInt: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: any;
  /** A currency string, such as $21.25 */
  USCurrency: any;
};

export type Auth = {
  __typename?: 'Auth';
  authenticated: Scalars['Boolean'];
  perms?: Maybe<Array<Maybe<Perm>>>;
  message?: Maybe<Scalars['String']>;
  jwt?: Maybe<Scalars['String']>;
  nextChallenge?: Maybe<Array<Challenge>>;
  user?: Maybe<User>;
};

export type Balances = {
  __typename?: 'Balances';
  prepaid: Scalars['Float'];
  bonusBucks: Scalars['Float'];
  balance: Scalars['Float'];
};

export type BillingInput = {
  billingName: Scalars['String'];
  cardNumber: Scalars['String'];
  cardType: Scalars['String'];
  cardCVV: Scalars['String'];
  expireMonth: Scalars['String'];
  expireYear: Scalars['String'];
  save: Scalars['Boolean'];
};

export type CardInfo = {
  __typename?: 'CardInfo';
  lastFourCardNumber?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  expireMonth?: Maybe<Scalars['String']>;
  expireYear?: Maybe<Scalars['String']>;
};

export type Cell = {
  __typename?: 'Cell';
  number: Scalars['String'];
  allowed: Scalars['Boolean'];
  bad: Scalars['Boolean'];
  pendingVerify?: Maybe<Scalars['Boolean']>;
  verified: Scalars['Boolean'];
  verifiedAt?: Maybe<Scalars['DateTime']>;
};

export type Challenge = {
  __typename?: 'Challenge';
  name: Scalars['String'];
  type: ChallengeType;
  params: Array<Scalars['String']>;
};

export enum ChallengeType {
  Text = 'text',
  Password = 'password',
  Phone = 'phone',
  License = 'license'
}

export type ChangeMembership = {
  __typename?: 'ChangeMembership';
  lastPaid?: Maybe<Scalars['UnsignedFloat']>;
  paidDate?: Maybe<Scalars['Date']>;
  pricePerDay?: Maybe<Scalars['UnsignedFloat']>;
  daysLeft?: Maybe<Scalars['Int']>;
  amountOfValueLeft?: Maybe<Scalars['UnsignedFloat']>;
  previousChange?: Maybe<Scalars['Boolean']>;
  changeMembershipTo?: Maybe<Array<Maybe<ChangeMembershipTo>>>;
};

export type ChangeMembershipInfo = {
  __typename?: 'ChangeMembershipInfo';
  newMemPriceForDaysLeft?: Maybe<Scalars['UnsignedFloat']>;
  preTaxChargeAmount?: Maybe<Scalars['UnsignedFloat']>;
  chargeAmount?: Maybe<Scalars['UnsignedFloat']>;
  newEndDate?: Maybe<Scalars['Date']>;
  saved?: Maybe<Scalars['Float']>;
  extraDays?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['UnsignedFloat']>;
  pricePerDay?: Maybe<Scalars['UnsignedFloat']>;
  now?: Maybe<Scalars['Boolean']>;
  nameOfAction?: Maybe<Scalars['String']>;
};

export type ChangeMembershipTo = {
  __typename?: 'ChangeMembershipTo';
  newMembership?: Maybe<Membership>;
  changeMembershipInfo?: Maybe<Array<Maybe<ChangeMembershipInfo>>>;
};

export type Client = User & {
  __typename?: 'Client';
  id: Scalars['ID'];
  name: Name;
  address?: Maybe<ClientAddress>;
  contact: Contact;
  dob?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  license?: Maybe<License>;
  createdAt?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
  balance?: Maybe<Scalars['Float']>;
  eftBalance?: Maybe<Scalars['Float']>;
  appBalances?: Maybe<Scalars['Float']>;
  registrationMode?: Maybe<RegistrationMode>;
  chargedBack: Scalars['Boolean'];
  terminated: Scalars['Boolean'];
  bypasses: Scalars['Int'];
  billing?: Maybe<ClientBilling>;
  promos?: Maybe<Promos>;
  /** @deprecated Use `promos` field */
  promosUsed?: Maybe<Promos>;
  clientMemberships?: Maybe<ClientMemberships>;
  tan?: Maybe<Tan>;
  forms?: Maybe<Array<Maybe<Form>>>;
  receipts?: Maybe<Array<Maybe<Receipt>>>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  packages?: Maybe<Array<Maybe<ClientPackages>>>;
};


export type ClientFormsArgs = {
  withAgreement?: Maybe<Scalars['Boolean']>;
};


export type ClientReceiptsArgs = {
  query?: Maybe<ReceiptQueryEnum>;
};


export type ClientTransactionsArgs = {
  query?: Maybe<TransactionQueryEnum>;
};


export type ClientPackagesArgs = {
  active?: Maybe<Scalars['Boolean']>;
};

export type ClientAddress = {
  __typename?: 'ClientAddress';
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ClientAddressInput = {
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ClientBilling = {
  __typename?: 'ClientBilling';
  billingName?: Maybe<Scalars['String']>;
  cardInfo?: Maybe<CardInfo>;
  badCard?: Maybe<Scalars['Boolean']>;
  deadCard?: Maybe<Scalars['Boolean']>;
  expiredCard?: Maybe<Scalars['Boolean']>;
  recurringFee?: Maybe<Scalars['UnsignedFloat']>;
};

export type ClientCursor = {
  __typename?: 'ClientCursor';
  edges: Array<Maybe<Client>>;
  totalCount: Scalars['Int'];
};

export type ClientMembership = {
  __typename?: 'ClientMembership';
  id: Scalars['ID'];
  membership_id?: Maybe<Scalars['ID']>;
  membership?: Maybe<Membership>;
  dates?: Maybe<StartEndDates>;
  status?: Maybe<ClientMembershipStatus>;
  prices?: Maybe<ClientMembershipPricing>;
  billingDayOfMonth?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  yearly?: Maybe<Scalars['Boolean']>;
};

export type ClientMembershipPricing = {
  __typename?: 'ClientMembershipPricing';
  /** Currency Field */
  purchaseAmount: Scalars['UnsignedFloat'];
  originalAmount: Scalars['UnsignedFloat'];
  recurringFee?: Maybe<Scalars['UnsignedFloat']>;
};

export type ClientMemberships = {
  __typename?: 'ClientMemberships';
  memberships?: Maybe<Array<Maybe<ClientMembership>>>;
  activeMembership?: Maybe<ClientMembership>;
  endmostMembership?: Maybe<ClientMembership>;
  firstNonCancelledMembership?: Maybe<ClientMembership>;
};

export type ClientMembershipStatus = {
  __typename?: 'ClientMembershipStatus';
  active?: Maybe<Scalars['Boolean']>;
  expired?: Maybe<Scalars['Boolean']>;
  canceled?: Maybe<Scalars['Boolean']>;
  offline?: Maybe<Scalars['Boolean']>;
  previousChange?: Maybe<Scalars['Boolean']>;
  changeable?: Maybe<Scalars['Boolean']>;
};

export type ClientPackages = {
  __typename?: 'ClientPackages';
  id: Scalars['ID'];
  packageId: Scalars['ID'];
  total?: Maybe<Scalars['Int']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  package?: Maybe<Package>;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Email>;
  cell: Cell;
};

export type CreateAndSignForm = {
  templateId: Scalars['ID'];
  clientId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  viewedLocation?: Maybe<Scalars['String']>;
  signedIp?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
};

export type CreateFormFromTemplateInput = {
  templateId: Scalars['ID'];
  clientId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  viewedLocation?: Maybe<Scalars['String']>;
  signedIp?: Maybe<Scalars['String']>;
};

export type CredentialInput = {
  method?: Maybe<Array<Maybe<LoginMethod>>>;
  cc?: Maybe<CreditCardInput>;
  license?: Maybe<Scalars['LicenseNumber']>;
  phone?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  face?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  app: Scalars['String'];
};

export type CreditCard = {
  __typename?: 'CreditCard';
  lastFour: Scalars['String'];
  month: Scalars['String'];
  year: Scalars['String'];
  type: CreditCardTypes;
  lastName: Scalars['String'];
};

export type CreditCardInput = {
  lastFour: Scalars['String'];
  month: Scalars['String'];
  year: Scalars['String'];
  type: CreditCardTypes;
  lastName: Scalars['String'];
};

export enum CreditCardTypes {
  Vi = 'VI',
  Mc = 'MC',
  Ax = 'AX',
  Di = 'DI'
}



export type Email = {
  __typename?: 'Email';
  address?: Maybe<Scalars['String']>;
  allowed: Scalars['Boolean'];
  bad: Scalars['Boolean'];
};


export type Employee = User & {
  __typename?: 'Employee';
  id: Scalars['ID'];
  name: Name;
  address?: Maybe<ClientAddress>;
  contact: Contact;
  dob?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  license?: Maybe<License>;
  createdAt?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
  balance?: Maybe<Scalars['Float']>;
  eftBalance?: Maybe<Scalars['Float']>;
  appBalances?: Maybe<Scalars['Float']>;
  clientId: Scalars['ID'];
  hr: Hr;
  code?: Maybe<Scalars['String']>;
  clientMemberships?: Maybe<ClientMemberships>;
  tan?: Maybe<Tan>;
  packages?: Maybe<Array<Maybe<ClientPackages>>>;
};


export type EmployeePackagesArgs = {
  active?: Maybe<Scalars['Boolean']>;
};

export type Form = {
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  formDescription?: Maybe<Scalars['String']>;
  formSource?: Maybe<Scalars['String']>;
  type: FormType;
  agreement?: Maybe<Scalars['String']>;
  viewedLocation?: Maybe<Scalars['String']>;
};

export type Forms = {
  __typename?: 'Forms';
  form?: Maybe<Scalars['String']>;
  uvSpray?: Maybe<Scalars['String']>;
};

export enum FormType {
  Signed = 'SIGNED',
  Pending = 'PENDING'
}

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export type GetHistoryByClientIdInput = {
  id: Scalars['ID'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};



export type Hr = {
  __typename?: 'HR';
  hiredAt?: Maybe<Scalars['Date']>;
  terminatedAt?: Maybe<Scalars['Date']>;
  rehire: Scalars['Boolean'];
  /** full time, part time, etc */
  hourStatus?: Maybe<Scalars['String']>;
  /** Terminated, walked off job, etc */
  status?: Maybe<Scalars['String']>;
};








export type KioskAuth = {
  __typename?: 'KioskAuth';
  authenticated: Scalars['Boolean'];
  jwt?: Maybe<Scalars['String']>;
  config?: Maybe<KioskConfig>;
};

export type KioskConfig = {
  __typename?: 'KioskConfig';
  workstationId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  chainId?: Maybe<Scalars['ID']>;
  offline?: Maybe<Scalars['Boolean']>;
  roomNumber?: Maybe<Scalars['String']>;
  roomMode?: Maybe<Scalars['String']>;
  sprayIp?: Maybe<Scalars['String']>;
  terminalVersion?: Maybe<Scalars['String']>;
  equipmentId: Scalars['ID'];
};

export type KioskConfigInput = {
  workstationId: Scalars['ID'];
  locationId: Scalars['ID'];
  chainId: Scalars['ID'];
  offline: Scalars['Boolean'];
  roomNumber: Scalars['Int'];
  roomMode: RoomMode;
  sprayIp: Scalars['String'];
  terminalVersion: Scalars['String'];
  authorized: Scalars['Boolean'];
  employeeId: Scalars['Int'];
  equipmentTypeId: Scalars['Int'];
  equipmentLevelId: Scalars['Int'];
  maxExposure: Scalars['Int'];
  equipmentId: Scalars['Int'];
};

export type License = {
  __typename?: 'License';
  /** Driver's License/Passport # */
  number: Scalars['LicenseNumber'];
  passport?: Maybe<Scalars['Boolean']>;
  /** Physical State (e.g: FL) */
  state?: Maybe<Scalars['String']>;
  /** Document ID from Onfido */
  documentId?: Maybe<Scalars['ID']>;
};

export type LicenseInput = {
  /** Driver's License/Passport # */
  number: Scalars['LicenseNumber'];
  passport?: Maybe<Scalars['Boolean']>;
  /** Physical State (e.g: FL) */
  state?: Maybe<Scalars['String']>;
  /** Document ID from Onfido */
  documentId?: Maybe<Scalars['ID']>;
};


export type LogCancelData = {
  license?: Maybe<Scalars['String']>;
  cell_number?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  data?: Maybe<Scalars['String']>;
};

export type LoginMethod = {
  name: Scalars['String'];
  value: Scalars['String'];
};



export type Membership = {
  id: Scalars['ID'];
  mode: MembershipMode;
  type: MembershipTanType;
  parentTitle?: Maybe<Scalars['String']>;
  parentDescrip?: Maybe<Scalars['String']>;
  expirationDays: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  useFormId?: Maybe<Scalars['ID']>;
  taxedPrice: Scalars['Float'];
  desc?: Maybe<MembershipDescription>;
  image?: Maybe<Scalars['String']>;
  sale?: Maybe<MembershipPortalSale>;
  portal?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<Maybe<Membership>>>;
  tax_groups?: Maybe<Scalars['String']>;
  ztName?: Maybe<Scalars['String']>;
  taxesApplied?: Maybe<Array<Maybe<TaxesApplied>>>;
  isZoomFit?: Maybe<Scalars['Boolean']>;
};

export type MembershipCancelData = {
  __typename?: 'MembershipCancelData';
  form?: Maybe<Scalars['String']>;
  done?: Maybe<Scalars['Boolean']>;
};

export type MembershipCursor = {
  __typename?: 'MembershipCursor';
  edges?: Maybe<Array<Maybe<Membership>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MembershipDescription = {
  __typename?: 'MembershipDescription';
  shortDescrip: Scalars['String'];
  longDescrip: Scalars['String'];
};

export type MembershipEft = Membership & {
  __typename?: 'MembershipEft';
  id: Scalars['ID'];
  mode: MembershipMode;
  type: MembershipTanType;
  parentTitle?: Maybe<Scalars['String']>;
  parentDescrip?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  expirationDays: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  useFormId?: Maybe<Scalars['ID']>;
  taxedPrice: Scalars['Float'];
  desc?: Maybe<MembershipDescription>;
  sale?: Maybe<MembershipPortalSale>;
  portal?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<Maybe<Membership>>>;
  tax_groups?: Maybe<Scalars['String']>;
  taxesApplied?: Maybe<Array<Maybe<TaxesApplied>>>;
  ztName?: Maybe<Scalars['String']>;
  isZoomFit?: Maybe<Scalars['Boolean']>;
  /** EFT data is used when mode === EFT */
  recurringFee: Scalars['Float'];
  eft?: Maybe<MembershipRecurring>;
};

export type MembershipForms = {
  __typename?: 'MembershipForms';
  membershipForm?: Maybe<Scalars['String']>;
  uvSprayForm: Scalars['String'];
};

export enum MembershipMode {
  Yearly = 'YEARLY',
  Eft = 'EFT'
}

export type MembershipPortalSale = {
  __typename?: 'MembershipPortalSale';
  active?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  salePrice?: Maybe<Scalars['Float']>;
  taxedSalePrice?: Maybe<Scalars['Float']>;
  saved?: Maybe<Scalars['Float']>;
  extraDays?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  previousPromoRestrictions?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientRestrictions?: ClientRestrictions;
  promo?: Maybe<Scalars['String']>;
  promoId?: Maybe<Scalars['ID']>;
};

export enum ClientRestrictions {
  Allow = 'ALLOW',
  Deny = 'DENY',
  Shareable = 'SHAREABLE',
  None = 'NONE'
}

export type MembershipRecurring = {
  __typename?: 'MembershipRecurring';
  /** Currency field */
  recurringFee: Scalars['UnsignedFloat'];
  signupFees?: Maybe<Array<Maybe<SignupFee>>>;
};

export enum MembershipTanType {
  Spray = 'SPRAY',
  Uv = 'UV',
  Both = 'BOTH'
}

export type MembershipYearly = Membership & {
  __typename?: 'MembershipYearly';
  id: Scalars['ID'];
  mode: MembershipMode;
  type: MembershipTanType;
  parentTitle?: Maybe<Scalars['String']>;
  parentDescrip?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  expirationDays: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  useFormId?: Maybe<Scalars['ID']>;
  taxedPrice: Scalars['Float'];
  desc?: Maybe<MembershipDescription>;
  sale?: Maybe<MembershipPortalSale>;
  portal?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<Maybe<Membership>>>;
  tax_groups?: Maybe<Scalars['String']>;
  taxesApplied?: Maybe<Array<Maybe<TaxesApplied>>>;
  ztName?: Maybe<Scalars['String']>;
  isZoomFit?: Maybe<Scalars['Boolean']>;
};

export type MinutePackage = Package & {
  __typename?: 'MinutePackage';
  id: Scalars['ID'];
  type: PackageType;
  name: Scalars['String'];
  price: Scalars['UnsignedFloat'];
  pricePlusTax: Scalars['UnsignedFloat'];
  sale?: Maybe<SalePrice>;
  desc: PackageDescription;
  minutes?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login: Auth;
  logout?: Maybe<Auth>;
  kioskLogin: KioskAuth;
  kioskConfig: Scalars['Boolean'];
  register?: Maybe<RegistrationResponse>;
  verifyCell?: Maybe<Scalars['Boolean']>;
  textVerificationCode: Scalars['Boolean'];
  generateOnfidoSdkToken?: Maybe<OnfidoSdkTokenResponse>;
  registerOnfidoUser?: Maybe<RegistrationResponse>;
  cancelMembership?: Maybe<MembershipCancelData>;
  cancelMembershipLog?: Maybe<Scalars['Boolean']>;
  signForm?: Maybe<Scalars['Boolean']>;
  createFormFromTemplate?: Maybe<Scalars['ID']>;
  createSignFormPortal?: Maybe<Scalars['ID']>;
  checkout?: Maybe<Scalars['Boolean']>;
  changeMembership?: Maybe<Scalars['Boolean']>;
  payBalance?: Maybe<Scalars['Boolean']>;
  updateBilling?: Maybe<Scalars['Boolean']>;
};


export type MutationLoginArgs = {
  input: CredentialInput;
};


export type MutationLogoutArgs = {
  jwt: Scalars['String'];
};


export type MutationKioskLoginArgs = {
  workstationId: Scalars['String'];
};


export type MutationKioskConfigArgs = {
  config: KioskConfigInput;
};


export type MutationRegisterArgs = {
  input: RegistrationInput;
};


export type MutationVerifyCellArgs = {
  input: VerifyCellInput;
};


export type MutationRegisterOnfidoUserArgs = {
  input: OnfidoRegistrationInput;
};


export type MutationCancelMembershipArgs = {
  fulfill?: Maybe<Scalars['Boolean']>;
};


export type MutationCancelMembershipLogArgs = {
  input: LogCancelData;
};


export type MutationSignFormArgs = {
  input?: Maybe<SignFormInput>;
};


export type MutationCreateFormFromTemplateArgs = {
  input?: Maybe<CreateFormFromTemplateInput>;
};


export type MutationCreateSignFormPortalArgs = {
  input?: Maybe<CreateAndSignForm>;
};


export type MutationCheckoutArgs = {
  membershipId?: Maybe<Scalars['ID']>;
  useOnFileBilling?: Maybe<Scalars['Boolean']>;
  billing?: Maybe<BillingInput>;
  promo?: Maybe<Scalars['ID']>;
};


export type MutationChangeMembershipArgs = {
  toMembershipId?: Maybe<Scalars['ID']>;
  billing?: Maybe<BillingInput>;
  startNow?: Maybe<Scalars['Boolean']>;
  useOnFileBilling?: Maybe<Scalars['Boolean']>;
  promo?: Maybe<Scalars['ID']>;
};


export type MutationPayBalanceArgs = {
  billing?: Maybe<BillingInput>;
  useOnFileBilling?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateBillingArgs = {
  billing: BillingInput;
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String'];
  last: Scalars['String'];
};

export type NameInput = {
  first: Scalars['String'];
  last: Scalars['String'];
};





export type OnfidoDocument = {
  id: Scalars['String'];
  type: Scalars['String'];
  side?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type OnfidoRegistrationInput = {
  cell: Scalars['PhoneNumber'];
  documents: Array<OnfidoDocument>;
  applicantId: Scalars['String'];
  livePhotoId?: Maybe<Scalars['String']>;
};

export type OnfidoSdkTokenResponse = {
  __typename?: 'OnfidoSdkTokenResponse';
  token: Scalars['String'];
  applicantId: Scalars['String'];
};

export type Package = {
  id: Scalars['ID'];
  type: PackageType;
  name: Scalars['String'];
  price: Scalars['UnsignedFloat'];
  pricePlusTax: Scalars['UnsignedFloat'];
  sale?: Maybe<SalePrice>;
  desc: PackageDescription;
};

export type PackageDescription = {
  __typename?: 'PackageDescription';
  short?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
};

export enum PackageType {
  Session = 'SESSION',
  Minute = 'MINUTE'
}

export type PendingForm = Form & {
  __typename?: 'PendingForm';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  formDescription?: Maybe<Scalars['String']>;
  formSource?: Maybe<Scalars['String']>;
  type: FormType;
  agreement?: Maybe<Scalars['String']>;
  viewedLocation?: Maybe<Scalars['String']>;
};

export type Perm = {
  __typename?: 'Perm';
  name: Scalars['String'];
  access: Scalars['String'];
};






export type PriceInput = {
  clientId: Scalars['ID'];
  type: Array<TanType>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Array<Maybe<ProductType>>>;
};

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Promos = {
  __typename?: 'Promos';
  used?: Maybe<Array<Maybe<Scalars['String']>>>;
  restricted?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Query = {
  __typename?: 'Query';
  placeholder?: Maybe<Scalars['Boolean']>;
  getClientRefresh?: Maybe<Client>;
  getClientById?: Maybe<Client>;
  getClients?: Maybe<ClientCursor>;
  getMembershipById: Membership;
  getClientMemberships?: Maybe<Array<Maybe<ClientMembership>>>;
  getAllPortalMemberships: MembershipCursor;
  getAllChangeMembershipInfo?: Maybe<ChangeMembership>;
  getHistoryByClientId?: Maybe<Array<Maybe<TanHistory>>>;
  getPrice?: Maybe<Scalars['Int']>;
  getTanBehaviors?: Maybe<TanBehaviors>;
  getTanBehaviorsByEquipmentId?: Maybe<Array<Maybe<TanBehavior>>>;
  getFormsByClientId?: Maybe<Array<Maybe<Form>>>;
  getReceiptsByClientId?: Maybe<Array<Maybe<Scalars['String']>>>;
  getFormsByMembershipId?: Maybe<Array<Maybe<TemplateForm>>>;
  getFormsById?: Maybe<Forms>;
  getMembershipForms: MembershipForms;
  getTransactionsByClientId?: Maybe<Array<Maybe<Transaction>>>;
  getTaxes?: Maybe<Taxes>;
};


export type QueryGetClientByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetClientsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetMembershipByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetAllPortalMembershipsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  promo?: Maybe<Scalars['String']>;
};


export type QueryGetAllChangeMembershipInfoArgs = {
  promo?: Maybe<Scalars['ID']>;
};


export type QueryGetHistoryByClientIdArgs = {
  input?: Maybe<GetHistoryByClientIdInput>;
};


export type QueryGetPriceArgs = {
  input?: Maybe<PriceInput>;
};


export type QueryGetTanBehaviorsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetTanBehaviorsByEquipmentIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetFormsByClientIdArgs = {
  clientId: Scalars['String'];
  withAgreement?: Maybe<Scalars['Boolean']>;
};


export type QueryGetReceiptsByClientIdArgs = {
  clientId: Scalars['String'];
  query?: Maybe<ReceiptQueryEnum>;
};


export type QueryGetFormsByMembershipIdArgs = {
  membershipId: Scalars['String'];
};


export type QueryGetFormsByIdArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetMembershipFormsArgs = {
  billingName: Scalars['String'];
  cardNumber: Scalars['String'];
  membershipId: Scalars['ID'];
};


export type QueryGetTransactionsByClientIdArgs = {
  clientId: Scalars['String'];
  query?: Maybe<TransactionQueryEnum>;
};


export type QueryGetTaxesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type Receipt = {
  __typename?: 'Receipt';
  id: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  agreement?: Maybe<Scalars['String']>;
};

export enum ReceiptQueryEnum {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  Last_7Days = 'LAST_7_DAYS',
  Last_30Days = 'LAST_30_DAYS',
  Last_3Months = 'LAST_3_MONTHS',
  All = 'ALL'
}

export type RegistrationInput = {
  mode: RegistrationMode;
  name: NameInput;
  cell: Scalars['PhoneNumber'];
  dob: Scalars['Date'];
  license: LicenseInput;
  gender?: Maybe<Gender>;
  address?: Maybe<ClientAddressInput>;
};

export enum RegistrationMode {
  Scan = 'SCAN',
  Manual = 'MANUAL'
}

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  newClient: Scalars['Boolean'];
  /** Did we text the client a verification code? */
  texted: Scalars['Boolean'];
  client?: Maybe<Client>;
};



export enum RoomMode {
  P = 'P',
  L = 'L',
  U = 'U',
  S = 'S'
}

export type SalePrice = {
  __typename?: 'SalePrice';
  active?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['UnsignedFloat']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  saved?: Maybe<Scalars['Float']>;
};

export type SendNotificationInput = {
  clientIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  employeeId: Scalars['ID'];
  locationId: Scalars['ID'];
  administrativeType: Scalars['Int'];
  message: Scalars['String'];
  phoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageType?: Maybe<Scalars['String']>;
};

export type SessionPackage = Package & {
  __typename?: 'SessionPackage';
  id: Scalars['ID'];
  type: PackageType;
  name: Scalars['String'];
  price: Scalars['UnsignedFloat'];
  pricePlusTax: Scalars['UnsignedFloat'];
  sale?: Maybe<SalePrice>;
  desc: PackageDescription;
  sessions?: Maybe<Scalars['Int']>;
};

export type SignedForm = Form & {
  __typename?: 'SignedForm';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  formDescription?: Maybe<Scalars['String']>;
  formSource?: Maybe<Scalars['String']>;
  type: FormType;
  agreement?: Maybe<Scalars['String']>;
  viewedLocation?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['DateTime']>;
  viewedLocationKioskId?: Maybe<Scalars['Int']>;
};

export type SignFormInput = {
  locationId?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  signatureId: Scalars['ID'];
  clientId: Scalars['ID'];
};

export type SignupFee = {
  __typename?: 'SignupFee';
  /** Currency field */
  fee: Scalars['UnsignedFloat'];
  /**
   * This is the order they are charged this fee.
   * E.g: 1st enrollment fee: $25
   * This would charge them an extra $25 fee on their first enrollment(order 1)
   * Only needed if type == ORDERED
   */
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<SignupFeeType>;
};

export enum SignupFeeType {
  Ordered = 'ORDERED',
  Subsequent = 'SUBSEQUENT'
}

export type Solution = {
  __typename?: 'Solution';
  id: Scalars['ID'];
  name: Scalars['String'];
  desc?: Maybe<Scalars['String']>;
  type?: Maybe<SolutionType>;
};

export enum SolutionType {
  Bronze = 'BRONZE',
  Clear = 'CLEAR',
  Premium = 'PREMIUM',
  Ultra = 'ULTRA'
}

export type SprayHistory = TanHistory & {
  __typename?: 'SprayHistory';
  id: Scalars['ID'];
  type?: Maybe<TanType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  solution?: Maybe<Solution>;
  level?: Maybe<Scalars['Int']>;
  soldBy?: Maybe<Employee>;
};

export type StartEndDates = {
  __typename?: 'StartEndDates';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  renewal?: Maybe<Scalars['Date']>;
};

export type Tan = {
  __typename?: 'Tan';
  history?: Maybe<TanHistoryCursor>;
};


export type TanHistoryArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type TanBehavior = {
  __typename?: 'TanBehavior';
  id: Scalars['ID'];
  equipmentTypeId: Scalars['ID'];
  objectId: Scalars['ID'];
  objectType: Scalars['String'];
  useCost: Scalars['String'];
  minutes?: Maybe<TanBehaviorMinutes>;
  fee?: Maybe<TanBehaviorFee>;
  sessions?: Maybe<TanBehaviorSessions>;
  doubleDip?: Maybe<TanBehaviorDoubleDip>;
  walkIn?: Maybe<TanBehaviorWalkIn>;
  multi?: Maybe<TanBehaviorMulti>;
  levels?: Maybe<TanBehaviorLevels>;
};

export type TanBehaviorDoubleDip = {
  __typename?: 'TanBehaviorDoubleDip';
  doubleDip?: Maybe<Scalars['String']>;
  useDoubleDip?: Maybe<Scalars['String']>;
};

export type TanBehaviorFee = {
  __typename?: 'TanBehaviorFee';
  preMaxFlatFee?: Maybe<Scalars['String']>;
  postMaxFlatFee?: Maybe<Scalars['String']>;
};

export type TanBehaviorLevels = {
  __typename?: 'TanBehaviorLevels';
  one?: Maybe<Scalars['String']>;
  two?: Maybe<Scalars['String']>;
  three?: Maybe<Scalars['String']>;
  four?: Maybe<Scalars['String']>;
  five?: Maybe<Scalars['String']>;
};

export type TanBehaviorMinutes = {
  __typename?: 'TanBehaviorMinutes';
  maxMinutes?: Maybe<Scalars['String']>;
  preMaxPerMinutePrice?: Maybe<Scalars['String']>;
  postMaxPerMinutePrice?: Maybe<Scalars['String']>;
};

export type TanBehaviorMulti = {
  __typename?: 'TanBehaviorMulti';
  uv?: Maybe<Scalars['String']>;
  spray?: Maybe<Scalars['String']>;
};

export type TanBehaviors = {
  __typename?: 'TanBehaviors';
  edges?: Maybe<Array<Maybe<TanBehavior>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TanBehaviorSessions = {
  __typename?: 'TanBehaviorSessions';
  preMaxSession?: Maybe<Scalars['String']>;
  postMaxSession?: Maybe<Scalars['String']>;
};

export type TanBehaviorWalkIn = {
  __typename?: 'TanBehaviorWalkIn';
  uv?: Maybe<Scalars['String']>;
  spray?: Maybe<Scalars['String']>;
};

export type TanHistory = {
  id: Scalars['ID'];
  type?: Maybe<TanType>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TanHistoryCursor = {
  __typename?: 'TanHistoryCursor';
  edges: Array<Maybe<TanHistory>>;
  totalCount: Scalars['Int'];
};

export enum TanType {
  Spray = 'SPRAY',
  Uv = 'UV'
}

export type Tax = {
  __typename?: 'Tax';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  isTanTax?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  stateTax?: Maybe<Scalars['String']>;
  offline?: Maybe<Scalars['Int']>;
};

export type Taxes = {
  __typename?: 'Taxes';
  edges?: Maybe<Array<Maybe<Tax>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TaxesApplied = {
  __typename?: 'TaxesApplied';
  tax?: Maybe<Scalars['String']>;
  taxRateId?: Maybe<Scalars['String']>;
  taxName?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['String']>;
};

export type TemplateForm = {
  __typename?: 'templateForm';
  form: Scalars['String'];
};


export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  transactionId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
};

export enum TransactionQueryEnum {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  Last_7Days = 'LAST_7_DAYS',
  Last_30Days = 'LAST_30_DAYS',
  Last_3Months = 'LAST_3_MONTHS',
  All = 'ALL'
}





export type User = {
  id: Scalars['ID'];
  name: Name;
  address?: Maybe<ClientAddress>;
  contact: Contact;
  dob?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  license?: Maybe<License>;
  createdAt?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
  balance?: Maybe<Scalars['Float']>;
  eftBalance?: Maybe<Scalars['Float']>;
  appBalances?: Maybe<Scalars['Float']>;
  clientMemberships?: Maybe<ClientMemberships>;
  tan?: Maybe<Tan>;
  packages?: Maybe<Array<Maybe<ClientPackages>>>;
};


export type UserPackagesArgs = {
  active?: Maybe<Scalars['Boolean']>;
};

export type UvHistory = TanHistory & {
  __typename?: 'UvHistory';
  id: Scalars['ID'];
  type?: Maybe<TanType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  minutes?: Maybe<Scalars['Int']>;
  soldBy?: Maybe<Employee>;
};

export type VerifyCellInput = {
  code: Scalars['Int'];
};
