import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import * as fromAuth from '../../../auth/reducers';
import {
  AccountInfoDialogService,
  VerifyCellDialogService,
} from '../../../shared/services';

@Component({
  templateUrl: './account.component.html',
})
export class AccountComponent implements OnInit {
  membershipKey = 'membership';
  packageKey = 'package';

  client$ = this.authStore.pipe(select(fromAuth.getClient));
  memberships$ = this.authStore.pipe(
    select(fromAuth.getFilteredClientMemberships)
  );
  packages$ = this.authStore.pipe(select(fromAuth.getClientPackages));
  history$ = this.authStore.pipe(select(fromAuth.getClientHistory));
  balance$ = this.authStore.pipe(select(fromAuth.getClientBalance));
  updateBillingEligible$: Observable<boolean>;

  constructor(
    private router: Router,
    private authStore: Store<fromAuth.AuthState>,
    private accountInfoDialogService: AccountInfoDialogService,
    private verifyDialogCellService: VerifyCellDialogService
  ) {}

  ngOnInit(): void {
    this.updateBillingEligible$ = this.getBillingUpdateEligibility();
  }

  editBillingDialog($event: any) {
    this.accountInfoDialogService.openDialog($event);
  }

  payBalance() {
    this.router.navigate(['/pay-balance']);
  }

  getBillingUpdateEligibility(): Observable<boolean> {
    return combineLatest([this.memberships$, this.client$]).pipe(
      map(([memberships, client]) => {
        if (memberships?.length > 0) {
          const nonCanceledMembership = memberships?.filter(
            (mems) => !mems?.status?.canceled
          );
          return (
            nonCanceledMembership?.length === 0 ||
            client.terminated ||
            client.chargedBack
          );
        }
        return true;
      })
    );
  }
  openVerify() {
    this.verifyDialogCellService.openResendVerifyCell();
  }
}
