import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, tap, map } from 'rxjs/operators';

import { CheckoutPurchaseActions } from '../actions';
import { CheckoutPurchaseService } from '../services';
import { EMPTY, of } from 'rxjs';
import { Router } from '@angular/router';
import { RefreshClientActions } from '../../auth/actions';

@Injectable()
export class CheckoutPurchaseEffects {
  private readonly FORM_MISMATCH_CODE = '4110';

  checkoutPurchase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutPurchaseActions.checkoutPurchaseRequest),
      switchMap((action) => {
        return this.checkoutPurchaseService
          .checkout(
            action.membershipId,
            action.clientBilling,
            action.useOnFileBilling,
            action.promo,
          )
          .pipe(
            map((response) =>
              CheckoutPurchaseActions.checkoutPurchaseSuccess({
                response: response.data,
              })
            ),
            catchError((error) =>
              of(
                CheckoutPurchaseActions.checkoutPurchaseFailure({
                  error: error.graphQLErrors.map((x) => x.message || x.msg)[0],
                  errorCode: error.graphQLErrors.map(
                    (x) => x.extensions?.code
                  )[0],
                })
              )
            )
          );
      })
    )
  );
  checkoutPurchaseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutPurchaseActions.checkoutPurchaseSuccess),
        tap((action) => {
          if (action.response) {
            this.router.navigate(['/checkout/checkout-successful']);
          }
        })
      ),
    { dispatch: false }
  );

  checkoutPurchaseFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutPurchaseActions.checkoutPurchaseFailure),
      switchMap((action) => {
        if (action.errorCode === this.FORM_MISMATCH_CODE) {
          return of(RefreshClientActions.refreshClientRequest());
        }
        return EMPTY;
      })
    )
  );

  constructor(
    private actions$: Actions,
    private checkoutPurchaseService: CheckoutPurchaseService,
    private router: Router
  ) {}
}
