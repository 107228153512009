import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { DataService } from '../services';
import { PortalMembershipsActions } from '../actions';

@Injectable()
export class PortalMembershipsEffects {
  getPortalMemberships$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortalMembershipsActions.getMembershipsRequest),
      switchMap((action) =>
        this.dataService.getPortalMemberships(action.totalCount).pipe(
          switchMap((response) =>
            of(
              PortalMembershipsActions.getMembershipsSuccess({
                memberships: response.data.getAllPortalMemberships.edges,
              })
            )
          ),
          catchError((error) =>
            of(
              PortalMembershipsActions.getMembershipsFailure({
                error: error.message,
              })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private dataService: DataService) {}
}
