import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class ClientReceiptsService {
  constructor(private apollo: Apollo) {}

  clientReceipts(clientId: string, query: string): Observable<any> {
    return this.apollo.query({
      query: clientReceipts,
      variables: { clientId, query },
    });
  }
}

const clientReceipts = gql`
  query clientReceipts($clientId: String!, $query: ReceiptQueryEnum) {
    getReceiptsByClientId(clientId: $clientId, query: $query)
  }
`;
