<ng-container *ngIf="this.availableMemberships$ | async as memberships">
<div *ngIf="!memberships.length" class="not-available">
  <div class="banner">
    <h1>Sorry this offer is no longer available!</h1>
  </div>
  <button (click)="this.promosService.routeToBuyMembership()">
    Click to Buy Memberships
  </button>
</div>
<div *ngIf="memberships.length">
  <header (click)="this.promosService.scroll(target)">
    <section>
      <aside>
        <img
          [src]="imageFileMobile"
          class="mobile"
          alt="Zoom Tan Membership Special Offer Below"
        />
        <img
          [src]="imageFileDesktop"
          class="desktop"
          alt="Zoom Tan Membership Special Offer Below"
        />
      </aside>
      <article>
        <h1>{{ header }}</h1>
        <p>
          {{ text }}
          <ng-container *ngIf="Brand.ZOOMFIT" >
            <a target="_blank" href="https://www.google.com/search?q=google+maps+directions+1201+Piper+Blvd+%234%2C+Naples%2C+FL+34110&oq=google+maps+directions+1201+Piper+Blvd+%234%2C+Naples%2C+FL+34110">Click here for directions</a> or to learn more please visit <a href="https://www.zoom.fit" target="_blank">www.zoom.fit</a>.
          </ng-container>
        </p>
        <small>*Restrictions may apply.</small>
      </article>
    </section>
  </header>
  <div #target class="banner" [class.banner-zoomfit]="Brand.ZOOMFIT">
    <h2 *ngIf="Brand.ZOOMTAN">Memberships can be used at any zoom tan nationwide</h2>
    <h2 *ngIf="Brand.ZOOMFIT">Currently only available in Rochester, New York and Naples, Florida. More locations coming soon!</h2>
  </div>
  <main
    data-cy="memberships"
  >
    <div *ngFor="let membership of memberships">
      <mat-card class="card mat-elevation-z6">
        <mat-card-content>
          <aside>
            <img *ngIf="Brand.ZOOMTAN" src="{{
              membership?.type === 'UV' ? '/assets/images/promos/uv_sale.png' :
              membership?.type === 'SPRAY' ? '/assets/images/promos/spray_sale.png' :
              membership?.type === 'BOTH' ? '/assets/images/promos/both_sale.png' : ''
            }}"/>
            <img *ngIf="Brand.ZOOMFIT" src="{{
              membership?.type === 'UV' ? '/assets/images/zoomfit/zfuv.png' :
              membership?.type === 'SPRAY' ? '/assets/images/zoomfit/zfspray.png' :
              membership?.type === 'BOTH' ? '/assets/images/zoomfit/zfboth.png' :
              '/assets/images/zoomfit/zfonly.png'
            }}"/>
          </aside>
          <div>
            <h3>{{ membership.parentTitle }}</h3>
            <strong>Membership</strong>
            <p>{{ membership.desc.shortDescrip }}</p>
          </div>
          <button (click)="this.promosService.openDetailsDialog(membership)">
            Details
          </button>
        </mat-card-content>
      </mat-card>

      <button
        *ngIf="BillingType.MONTHLY"
        (click)="this.promosService.membershipClicked(membership, this.promoCode)"
        data-cy="membership-button"
      >
        <span>
          <strong>Special Monthly Offer</strong>
          <br/>
          {{ offerDetails(membership) }}
        </span>
        <span>
          <strong data-cy="membership-price">${{ formatPrice(membership?.price) }}</strong>
          <br/>
          First Month Only
        </span>
      </button>

      <button
        *ngIf="BillingType.YEARLY"
        (click)="this.promosService.membershipClicked(membership, this.promoCode)"
        data-cy="membership-button"
      >
        <span>
          <strong>Special Yearly Offer</strong>
          <br/>
          {{ offerDetails(membership) }}
        </span>
        <span>
          <strong data-cy="membership-price">${{ formatPrice(membership?.price) }}</strong>
        </span>
      </button>

    </div>
    <section *ngIf="Brand.ZOOMFIT">
      <p>
        At Zoom Fit there is NO commitment or contract for future months and you can join
        or cancel online easily at your convenience!  Our unique, personalized 2-part concept
        of strength training & anaerobic cardio combines highly researched and proven workout
        methods for achieving your goals faster, safer & more effectively.  Each part can be
        completed in as little as 15-minutes so you can get the results you desire without
        spending hours in the gym!  Our smart fitness technology takes out all the guesswork
        and will prescribe you a workout routine that is most effective for targeting your
        selected fitness goal.  You simply swipe your Zoom Fit wristband on any machine to
        begin and our equipment will handle everything else - even self-adjusting to your personalized
        settings!  Unlike your standard gym, Zoom Fit provides you a way to make the most out
        of your time while actually having fun with our game-like machine interfaces!
        On top of that, each membership includes special advanced fitness perks that will
        help you fully understand & track your fitness. These features include a FREE monthly
        InBody full body composition analysis to find out what you're really made of and track
        your health improvements.  Members also get access to our premium, integrated Zoom Fit
        Member App that allows you to follow your progress, muscle balances, BioAge, gym ranking,
        goal completions and so much more!
      </p>
    </section>
  </main>
</div>
</ng-container>
