<div class="container">
  <h1 class="title">My Account Information</h1>
  <button *ngIf="!client?.clientMemberships?.activeMembership"
    class="container__action-button"
    mat-raised-button
    routerLink='{{routeToBuyMembership}}'
    data-cy="account-buy-membership-button"
  >
    BUY MEMBERSHIP
  </button>
  <mat-card class="card mat-elevation-z6">
    <h2 class="card__title" data-cy="account-info__title">
      Account Summary
    </h2>
    <mat-card-content>
      <mat-list>
        <mat-divider></mat-divider>
        <mat-list-item>Name</mat-list-item>
        <mat-list-item class="card__data">
          <strong data-cy="account-info__name"
            >{{ client?.name.first | titlecase }} {{ client?.name?.last | titlecase }}</strong
          >
        </mat-list-item>
        <mat-divider></mat-divider>
        <br />
        <mat-list-item *ngIf="client?.contact?.cell?.number">Phone</mat-list-item>
        <mat-list-item *ngIf="client?.contact?.cell?.number" class="card__data">
          <strong data-cy="account-info__number">{{
            client?.contact?.cell?.number | mask: '(000) 000-0000'
          }}</strong> 
                    <button
            *ngIf="verifyCell"
            (click)="verifyCellEvent.emit()"
            class="card__edit-button"
            data-cy="account-info__verify-button"
          >verify
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <br />
        <div *ngIf="client?.contact?.email?.address">
          <mat-list-item>Email</mat-list-item>
          <mat-list-item class="card__data">
            <strong data-cy="account-info__email">{{ client?.contact?.email?.address }}</strong>
          </mat-list-item>
          <mat-divider></mat-divider>
        </div>
        <br />
        <mat-list-item>Card</mat-list-item>
        <mat-list-item class="card__data">
          <strong data-cy="account-info__billing">
            {{ cardText }}
          </strong>
          <button
            *ngIf="client?.billing && !locked"
            (click)="editBilling()"
            class="card__edit-button"
            data-cy="account-info__edit-button"
          >
            {{ updateBillingText }}
          </button>
        </mat-list-item>
        <div *ngIf="client?.balance > 0">
          <mat-divider></mat-divider>
          <br />
          <mat-list-item class="card__data--red">
            <strong data-cy="account-info__balance"
              >Balance Due: {{ client?.balance | currency }}</strong
            >
          </mat-list-item>
          <mat-list-item class="card__data">
            <button
              (click)="balanceEvent.emit()"
              class="card__button"
              mat-raised-button
              data-cy="account-info__pay-balance-button"
            >
              pay now
            </button>
          </mat-list-item>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
