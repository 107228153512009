import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(private title: Title, private meta: Meta) {}

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description });
  }

  generateTags(config) {
    config = {
      title: 'Zoom Buy | Online Store & Member Portal',
      description:
        'Purchase Your Spray or UV Zoom Tan Membership Online In Just Minutes! Look & Feel Beautiful In Your Sun-kissed Skin EVERY DAY With Our Unlimited Tanning Member Privileges. No Contracts, No Commitments, Cancel Online',
      image: '../assets/zoomtan/zoom_tan_buy_2.jpg',
      slug: '',
      ...config,
    };

    this.meta.updateTag({ name: 'twitter:card', content: 'website' });
    this.meta.updateTag({ name: 'twitter:site', content: '@zoomtan' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({
      name: 'twitter:description',
      content: config.description,
    });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });
    this.meta.updateTag({ name: 'article:author', content: 'Zoom Tan, LLC' });
    this.meta.updateTag({
      name: 'Copyright',
      content: 'Copyright Zoom Tan, LLC. All Rights Reserved',
    });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({
      property: 'og:site_name',
      content: 'Zoom Buy',
    });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({
      property: 'og:description',
      content: config.description,
    });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:image:height', content: '162' });
    this.meta.updateTag({ property: 'og:image:width', content: '300' });
    this.meta.updateTag({
      property: 'og:url',
      content: `https://zoomtan.com/${config.slug}`,
    });
  }
}
