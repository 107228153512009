<div class="container">
  <h2 class="container__title" data-cy="membership-info__title">
    Member Information
  </h2>
  <mat-card class="card mat-elevation-z6">
    <h3 class="card__title" data-cy="membership-info__card-title">
      Account
    </h3>
    <mat-card-content>
      <mat-list>
        <!-- Name -->
        <mat-divider></mat-divider>
        <mat-list-item>
          Name
        </mat-list-item>
        <mat-list-item class="card__data">
          <strong data-cy="membership-info__name">{{
            client.name.first + ' ' + client.name.last
          }}</strong>
        </mat-list-item>
        <mat-divider></mat-divider>
        <br />
        <!-- License -->
        <span *ngIf="client.license.number.length > 0">
          <mat-divider></mat-divider>
          <mat-list-item>
            License
          </mat-list-item>
          <mat-list-item class="card__data">
            <strong data-cy="membership-info__license">{{
              client.license.number
            }}</strong>
          </mat-list-item>
          <mat-divider></mat-divider>
          <br />
        </span>
        <!-- DOB -->
        <mat-divider></mat-divider>
        <mat-list-item>
          DOB
        </mat-list-item>
        <mat-list-item class="card__data">
          <strong data-cy="membership-info__dob">{{
            client.dob | date: 'longDate'
          }}</strong>
        </mat-list-item>
        <mat-divider></mat-divider>
        <br />
        <!-- Phone -->
        <mat-divider></mat-divider>
        <mat-list-item>
          Phone
        </mat-list-item>
        <mat-list-item class="card__data">
          <strong data-cy="membership-info__phone">{{
            client.contact.cell.number | mask: '(000) 000-0000'
          }}</strong>
          &nbsp;
          <button
            class="card__verify-cta"
            *ngIf="!client?.contact?.cell?.verified"
            (click)="openVerify.emit()"
            data-cy="membership-info__verify-button"
          >
            Verify
          </button>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
