import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OnfidoActions } from '../actions';
import { OnfidoService } from '../services/onfido.service';
import * as fromOnfido from '../reducers/onfido.reducer';
import { RegisterClientActions } from '../../auth/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../shared/services';
import { of } from 'rxjs';

@Injectable()
export class OnfidoEffects {
  constructor(
    private readonly actions: Actions,
    private readonly onfidoService: OnfidoService,
    private readonly onfidoStore: Store<fromOnfido.OnfidoState>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: SnackbarService
  ) {}

  requestSdkTokenEffect = createEffect(() =>
    this.actions.pipe(
      ofType(OnfidoActions.requestOnfidoSdkToken),
      switchMap(() =>
        this.onfidoService.getSdkToken().pipe(
          map((response) => {
            const data = response.data.generateOnfidoSdkToken;
            return OnfidoActions.requestOnfidoSdkTokenSuccess({ ...data });
          }),
          catchError((_) => of(OnfidoActions.onfidoFlowFailed()))
        )
      )
    )
  );

  phoneNumberSubmittedEffect = createEffect(() =>
    this.actions.pipe(
      ofType(OnfidoActions.onfidoPhoneNumberSubmitted),
      withLatestFrom(
        this.onfidoStore.select(fromOnfido.selectApplicantId),
        this.onfidoStore.select(fromOnfido.selectDocuments),
        this.onfidoStore.select(fromOnfido.selectLivePhotoId),
        this.onfidoStore.select(fromOnfido.selectPhoneNumber)
      ),
      switchMap(([_, applicantId, documents, livePhotoId, phoneNumber]) =>
        this.onfidoService
          .registerUser(applicantId, documents, livePhotoId, phoneNumber)
          .pipe(
            map((response) => {
              const data = response.data.registerOnfidoUser;
              return OnfidoActions.onfidoUserRegistered({ ...data });
            }),
            catchError((_) => of(OnfidoActions.onfidoFlowFailed()))
          )
      )
    )
  );

  onfidoUserRegisteredEffect = createEffect(() =>
    this.actions.pipe(
      ofType(OnfidoActions.onfidoUserRegistered),
      map((action) =>
        RegisterClientActions.registerClientSuccess({
          newClient: action.newClient,
          client: action.client,
          texted: action.texted,
        })
      )
    )
  );

  onfidoFlowFailedEffect = createEffect(() =>
    this.actions.pipe(
      ofType(OnfidoActions.onfidoFlowFailed),
      switchMap(async (_) => {
        await this.router.navigate(['/checkout']);
        this.snackBar.open(
          'An error occurred with automatic registration. Please enter your information manually.',
          'Close',
          10000
        );
        return OnfidoActions.onfidoStateReset();
      })
    )
  );

  onfidoFlowRestartedEffect = createEffect(
    () =>
      this.actions.pipe(
        ofType(OnfidoActions.onfidoFlowRestarted),
        map(async () => {
          await this.router.navigate(['/register']);
          this.snackBar.open(
            'Supplied ID was expired. Please try again with a valid ID.',
            'Close',
            10000
          );
        })
      ),
    { dispatch: false }
  );
}
