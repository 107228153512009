import { createReducer, on } from '@ngrx/store';
import { TextResendActions } from '../actions';

export const textResendFeatureKey = 'textResend';

export interface State {
  textVerificationCode: boolean;
  success: boolean;
  loading: boolean;
  error: string;
}

export const initialState: State = {
  textVerificationCode: undefined,
  success: false,
  error: null,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(TextResendActions.textResendRequest, (state, {}) => ({
    ...state,
    error: null,
    loading: true,
  })),
  on(
    TextResendActions.textResendSuccess,
    (state, { textVerificationCode }) => ({
      ...state,
      textVerificationCode,
      success: true,
      error: null,
      loading: false,
    })
  ),
  on(TextResendActions.textResendFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
);

export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getTextVerificationCode = (state: State) =>
  state.textVerificationCode;
