import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SelectedItemService } from '../../../checkout/services';

import * as fromCore from '../../../core/reducers';
import { SelectedItem } from '../../../checkout/models';
import { Membership } from '../../../core/models';
import { PortalMembershipsActions } from '../../../core/actions';
import * as fromAuth from '../../../auth/reducers';
import { take } from 'rxjs/operators';

@Component({
  selector: 'hyp2-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  zoomFitMemberships$: Observable<Membership[]> = this.coreStore.pipe(
    select(fromCore.getZoomFitMemberships)
  );
  zoomTanMemberships$: Observable<Membership[]> = this.coreStore.pipe(
    select(fromCore.getZoomTanMemberships)
  );

  public item: SelectedItem;
  public tabIndex = 0;

  constructor(
    private router: Router,
    private coreStore: Store<fromCore.CoreState>,
    private authStore: Store<fromAuth.AuthState>,
    private selectedItemService: SelectedItemService
  ) {}

  ngOnInit() {
    if (this.router.url === '/zoomfit/fitness-memberships') {
      this.tabIndex = 1;
    }
    this.coreStore.dispatch(
      PortalMembershipsActions.getMembershipsRequest({ totalCount: 20 })
    );
  }

  membershipClicked(membership: Membership) {
    this.selectedItemService.selectMembership(membership);
    this.navigateToRegisterOrCheckout();
  }

  navigateToRegisterOrCheckout() {
    this.authStore
      .pipe(take(1), select(fromAuth.getClient))
      .subscribe((client) => {
        if (!client) {
          this.router.navigate(['/register']);
        } else {
          this.router.navigate(['/checkout']);
        }
      });
  }
}
