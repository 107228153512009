<div class="container" data-cy="membership-container">
  <mat-card class="card mat-elevation-z6">
    <h2 class="card__title">
      {{ categoryKey === 'membership' ? 'Membership' : 'Package' }}
      <button (click)="openDetailsDialog()" class="card__open-dialog-text">
        Details
      </button>
    </h2>
    <mat-card-content>
      <mat-list>
        <mat-divider></mat-divider>
        <mat-list-item>
          {{ categoryKey === 'membership' ? 'Membership' : 'Package' }}
        </mat-list-item>
        <mat-list-item class="card__data">
          <strong>{{
            categoryKey === 'membership'
              ? memberships?.membership?.name
              : packages?.package?.name
          }}</strong>
        </mat-list-item>
        <mat-divider></mat-divider>
        <br />
        <div *ngIf="categoryKey === 'membership'">
          <mat-list-item>Start Date</mat-list-item>
          <mat-list-item class="card__data">
            <strong>{{ memberships?.dates?.start | date }}</strong>
          </mat-list-item>
          <mat-divider></mat-divider>
          <br />
          <mat-list-item>End Date</mat-list-item>
          <mat-list-item class="card__data">
            <strong data-cy="membership-end-date">{{
              !memberships?.dates?.end
                ? 'auto-renew'
                : (memberships?.dates?.end | date)
            }}</strong></mat-list-item
          >
        </div>
        <div *ngIf="nextPaymentDisplay">
          <mat-divider></mat-divider>
          <!-- Next Payment -->
          <mat-list-item>Next Payment </mat-list-item>
          <mat-list-item
            class="card__data"
            data-cy="profile__billing-next-payment"
          >
            <strong>
              {{ nextPaymentDisplay }}
            </strong>
          </mat-list-item>
        </div>
        <mat-divider *ngIf="shouldShowChangeMembershipButton"></mat-divider>
        <div *ngIf="categoryKey === 'package'">
          <mat-divider></mat-divider>
          <mat-list-item>Expires</mat-list-item>
          <mat-list-item class="card__data">
            <strong>{{
              packages?.expireDate ? (packages?.expireDate | date) : ''
            }}</strong>
          </mat-list-item>
          <mat-divider></mat-divider>
          <br />
          <mat-list-item>Purchased</mat-list-item>
          <mat-list-item class="card__data">
            <strong>{{ packages?.total }}</strong>
          </mat-list-item>
        </div>
      </mat-list>
    </mat-card-content>
    <button
      *ngIf="shouldShowChangeMembershipButton"
      class="card__button"
      mat-raised-button
      data-cy="membership-change"
      [disabled]="!isChangeMembershipAllowed"
      routerLink="/change-membership"
    >
      {{ changeButtonText }}
    </button>
    <button
      *ngIf="shouldShowCancelMembershipButton"
      class="card__button card__cancel-button"
      mat-raised-button
      data-cy="membership-cancel"
      routerLink="/cancel-membership"
    >
      {{ cancelButtonText }}
    </button>
  </mat-card>
</div>
