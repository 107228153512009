import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {
  CanActivate,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map,} from 'rxjs/operators';

import * as fromAuth from '../../auth/reducers';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PayBalanceGuardService implements CanActivate {
  constructor(
    private router: Router,
    private store$: Store<fromAuth.AuthState>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canActivate(): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }
    return this.store$.select(fromAuth.getClientBalance)
      .pipe(
        map((balance) => {
          if (balance > 0) {
            return true;
          } else {
            this.router.navigate(['/my-account']);
            return false;
          }
        })
      );
  }
}
