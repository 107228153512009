<div class="container" *ngIf="client$ | async as client">
  <hyp2-overlay-spinner *ngIf="loading$ | async"></hyp2-overlay-spinner>
  <div class="container__header">
    <h1 class="container__header-title" data-cy="change-membership__title">
      Change Membership
    </h1>
    <p
      class="container__header-desc"
      data-cy="change-membership__desc"
      *ngIf="!(showConfirmChange$ | async)"
    >
      {{ title }}
    </p>
  </div>

  <!-- Current Membership | New Membership table -->
  <hyp2-change-membership-table
    class="container__current-and-new"
    [currentMembership]="currentMembership$ | async"
    [newMembership]="(newMembershipChange$ | async)?.newMembership"
  ></hyp2-change-membership-table>
  <!-- Change-Membership view -->
  <div
    *ngIf="membershipChangeInfo$ | async"
    class="container__select-membership"
  >
    <!-- Change-Membership choice actions -->
    <div class="memberships__header" *ngIf="showChoices || showStartChoices">
      <h2
        class="memberships__header-title"
        data-cy="change-membership__config-title"
      >
        {{ smallTitle }}
      </h2>
      <div class="flex-spacer"></div>
      <div></div>
      <span *ngIf="showChoices">
        <button
          (click)="changeMembershipType(true)"
          [ngClass]="[
            'memberships__header-button',
            (showMonthlyMemberships$ | async) ? 'active' : ''
          ]"
          mat-raised-button
          data-cy="change-membership__monthly-button"
        >
          Monthly
        </button>
        <button
          (click)="changeMembershipType(false)"
          [ngClass]="[
            'memberships__header-button',
            (showMonthlyMemberships$ | async) ? '' : 'active'
          ]"
          mat-raised-button
          data-cy="change-membership__yearly-button"
        >
          Yearly
        </button>
      </span>
    </div>

    <hyp2-change-membership-choices
      *ngIf="showChoices"
      [membershipChanges]="filteredMembershipChanges$ | async"
      [defaultSelection]="newMembershipChange$ | async"
      [showMonthlyMemberships]="showMonthlyMemberships$ | async"
      (membershipSelected)="membershipClicked($event)"
    >
    </hyp2-change-membership-choices>

    <!-- Change-Membership start date -->
    <mat-radio-group>
      <hyp2-start-change
        *ngIf="showStartChoices"
        [startChangeData]="startChangeData$ | async"
        (buttonEvent)="changeMembershipStartDate($event)"
        data-cy="change-membership__start-change"
      >
      </hyp2-start-change>
    </mat-radio-group>

    <!-- Change-Membership confirm -->
    <hyp2-confirm-change
      *ngIf="
        client && (showConfirmChange$ | async) && confirmChangeInformation$
          | async as changeInformation
      "
      [confirmChangeInformation]="changeInformation"
      (paymentCardEvent)="paymentCardEvent($event)"
      (newCardEvent)="newCardEvent($event)"
      (useOnFileBillingEvent)="useOnFileBilling = $event"
      data-cy="change-membership__confirm-change"
    ></hyp2-confirm-change>

    <!-- Terms & Conditions -->
      <hyp2-terms-and-conditions
        *ngIf="(showTos$ | async) && (clientBilling$ | async) as clientBilling"
        [client]="client"
        [item]="itemSelected$ | async"
        [agreed]="agreed"
        [clientBilling]="
          clientBilling?.save || !client.billing ? clientBilling : null
        "
        [showAgreement]="
          clientBilling.save || (itemSelected$ | async)?.eft || !!client.billing
        "
        (buttonEvent)="confirmAgreed($event)"
        data-cy="change-membership__tos"
      ></hyp2-terms-and-conditions>
  </div>

  <!-- Error Card -->
  <hyp2-no-change-available
    class="container__select-membership"
    *ngIf="!(membershipChanges$ | async) && !(loading$ | async)"
    [currentMembership]="currentMembership$ | async"
  ></hyp2-no-change-available>

  <!-- Change-Membership actions -->
  <div class="container__action">
    <button
      (click)="back()"
      class="container__action-button"
      mat-raised-button
      data-cy="change-membership__back-button"
    >
      Back
    </button>
    <button
      *ngIf="!(showConfirmChange$ | async) && membershipChangeInfo$ | async"
      class="container__action-button"
      mat-raised-button
      data-cy="change-membership__continue-button"
      [disabled]="!isContinueButtonEnabled"
      (click)="continue()"
    >
      Continue
    </button>
    <button
      [disabled]="!this.agreed || (loading$ | async)"
      *ngIf="showConfirmChange$ | async"
      (click)="confirmChange()"
      class="container__action-button"
      mat-raised-button
      data-cy="change-membership__confirm-button"
    >
      Confirm
    </button>
  </div>
</div>
