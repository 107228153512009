import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class JulyYearlyComponent extends PromoComponent {
  // id
  static path = 'zt070421yp';
  static promoCode = '4J';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.YEARLY;

  // display
  imageFileDesktop = '/assets/images/promos/july-yearly-desktop.jpg';
  imageFileMobile = '/assets/images/promos/july-yearly-mobile.jpg';
  header = 'Get 4 extra months for only $4 with this special July 4th Offer!';
  text = 'In celebration of July 4th, we have an exclusive online offer just for you! You can get 4 extra months for only $4 when you purchase any of our yearly memberships. Yearly members already save big over monthly members but with this deal you can save up to an extra of $166.33. Don\'t miss out!';
}
