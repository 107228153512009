<div class="container">
  <hyp2-overlay-spinner
    *ngIf="(onfidoDocuments$ | async) && (loading$ | async)"
    [message]="
      'We are still working on verifying your document. This process could take several minutes. Please do not navigate away from this page.'
    "
    [messageTimeoutSeconds]="15"
  >
  </hyp2-overlay-spinner>

  <div class="row">
    <hyp2-onfido-flow class="full-width" *ngIf="!(onfidoDocuments$ | async)">
    </hyp2-onfido-flow>
    <hyp2-onfido-register
      *ngIf="(onfidoDocuments$ | async) && !(onfidoPhoneNumber$ | async)"
    >
    </hyp2-onfido-register>
  </div>

  <div class="row">
    <button
      *ngIf="!(onfidoDocuments$ | async) && !(loading$ | async)"
      routerLink="/checkout"
      data-cy="register-manually"
    >
      Having problems registering? Click here to register manually.
    </button>
  </div>
</div>
