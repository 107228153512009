import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';


import * as fromAuth from '../../reducers';
import { VerifyClientActions } from '../../actions';
import { ActivatedRoute } from '@angular/router';
import { InitTokenService } from '../../services';

@Component({
  selector: 'hyp2-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  authForm: FormGroup;
  returnUrl: string;

  loading$ = this.authStore.select(fromAuth.getLoginLoading);

  constructor(
    private route: ActivatedRoute,
    private authStore: Store<fromAuth.State>,
    private initTokenService: InitTokenService
  ) {
    this.authForm = new FormGroup({});
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/my-account';
  }

  ngOnInit() {
    this.initTokenService.initializeToken();
  }

  verifyClient() {
    this.authStore.dispatch(VerifyClientActions.verifyClientRequest({
      license: this.authForm.value.clientAuth.license,
      cell: this.authForm.value.clientAuth.cell,
      returnUrl: this.returnUrl,
    }))
  }
}
