import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class CyberMondayYearlyComponent extends PromoComponent {
  // id
  static path = 'cmyp';
  static promoCode = 'CMY';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.YEARLY;

  // display
  imageFileDesktop = '/assets/images/promos/cybermonday-yearly-desktop.jpg';
  imageFileMobile = '/assets/images/promos/cybermonday-yearly-mobile.jpg';
  header = 'Get 6 extra months FREE with this special Cyber Monday offer!';
  text = 'We have an exclusive online offer just for you this Cyber Monday! You can get 6 extra months for FREE when you purchase any of our yearly memberships. Yearly members already save big over monthly members but with this deal you can save up to an extra of $249.50. Don\'t miss out!';
}
