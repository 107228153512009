import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { onfidoStateReset } from '../actions/onfido-actions';
import * as fromOnfido from '../reducers/onfido.reducer';
import { OnfidoDocument } from '../types/OnfidoDocument';

@Component({
  selector: 'hyp2-onfido',
  templateUrl: './onfido.component.html',
  styleUrls: ['./onfido.component.scss'],
})
export class OnfidoComponent implements OnInit, OnDestroy {
  public onfidoDocuments$: Observable<OnfidoDocument[]>;
  public onfidoPhoneNumber$: Observable<string>;
  public loading$: Observable<boolean>;
  constructor(
    private readonly onfidoStore: Store<fromOnfido.OnfidoState>,
    private readonly route: ActivatedRoute
  ) {
  }

  ngOnDestroy(): void {
    this.onfidoStore.dispatch(onfidoStateReset());
  }

  ngOnInit() {
    this.loading$ = this.onfidoStore.select(fromOnfido.selectLoading);
    this.onfidoDocuments$ = this.onfidoStore.select(fromOnfido.selectDocuments);
    this.onfidoPhoneNumber$ = this.onfidoStore.select(
      fromOnfido.selectPhoneNumber
    );
  }
}
