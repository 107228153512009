import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Membership } from '../../../core/models';

abstract class MembershipsComponent {
  @Input() memberships: Membership[];
  @Output() buttonEvent = new EventEmitter();

  zoomTan = false;
  zoomFit = false;

  abstract title: string;
  abstract locationText: string;
  abstract data_cy: string;
  abstract membershipCategoryClass: string;

  get layoutType() {
    const count = this.memberships.length;

    const one = count === 1;
    const two = count === 2;
    const three = count === 3;
    const even = !(count % 2);

    const few = count <= 4;
    const many = count > 4;

    return (
      one ? 'one' :
      two ? 'two' :
      even && few ? 'double' :
      three || many ? 'triple' :
      ''
    );
  }
}

@Component({
  selector: 'hyp2-zoomtan-memberships',
  templateUrl: '../memberships/memberships.component.html',
  styleUrls: ['../memberships/memberships.component.scss'],
})
export class ZoomTanMembershipsComponent extends MembershipsComponent {
  zoomTan = true;
  title = 'Zoom Tan';
  locationText = null;
  data_cy = 'home__membership';
  membershipCategoryClass = 'container__ZoomTan';
}

@Component({
  selector: 'hyp2-zoomfit-memberships',
  templateUrl: '../memberships/memberships.component.html',
  styleUrls: ['../memberships/memberships.component.scss'],
})
export class ZoomFitMembershipsComponent extends MembershipsComponent {
  zoomFit = true;
  title = 'Zoom Fit';
  locationText = 'Currently only available in Rochester, New York and Naples, Florida. More locations coming soon!';
  data_cy = 'home__packages';
  membershipCategoryClass = 'container__ZoomFit';
}

