import { createAction, props } from '@ngrx/store';
import { BillingInput } from '@hyp2/graphql';

export const payBalanceRequest = createAction(
  '[Profile] Pay Balance Request',
  props<{ billing: BillingInput }>()
);
export const payBalanceSuccess = createAction(
  '[Profile] Pay Balance Success',
  props<{ response: any }>()
);
export const payBalanceFailure = createAction(
  '[Profile] Pay Balance Failure',
  props<{ error: any }>()
);
