import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckoutGuardService } from '../checkout/services';
import { OnfidoComponent } from './components/onfido.component';
import { OnfidoEnabledGuard } from './guards/onfido-enabled.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [CheckoutGuardService, OnfidoEnabledGuard],
    component: OnfidoComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OnfidoRoutingModule { }
