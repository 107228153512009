<div class="dialog" data-cy="dialog__container">
  <hyp2-overlay-spinner
    *ngIf="parsedClientForms?.length < 2"
  ></hyp2-overlay-spinner>
  <h1 class="dialog__header" mat-dialog-title>TERMS & CONDITIONS</h1>
  <p class="dialog__message">
    Please read and review Zoom Tan's terms & conditions below
  </p>
  <div mat-dialog-content>
    <div *ngFor="let form of parsedClientForms">
      <mat-card *ngIf="form?.length" class="card" data-cy="dialog__card">
        <div *ngIf="form?.length" [innerHTML]="form"></div>
      </mat-card>
    </div>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button class="dialog__button" [mat-dialog-close]="true" data-cy="button__dialog_back">
      Back
    </button>
  </mat-dialog-actions>
</div>
