import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class TextResendService {
  constructor(private apollo: Apollo) {}

  textResend(): Observable<any> {
    return this.apollo.mutate({
      mutation: textVerificationCode,
    });
  }
}
const textVerificationCode = gql`
  mutation textVerificationCode {
    textVerificationCode
  }
`;
