import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { BillingInput } from '@hyp2/graphql';

@Injectable({
  providedIn: 'root',
})
export class ChangeMembershipService {
  constructor(private apollo: Apollo) {}

  changeMembership(
    toMembershipId: number,
    billing: BillingInput,
    startNow: boolean,
    useOnFileBilling: boolean,
    promo: string,
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: changeMembership,
      variables: {
        toMembershipId,
        billing,
        startNow,
        useOnFileBilling,
        promo,
      },
    });
  }
}

const changeMembership = gql`
  mutation changeMembership(
    $toMembershipId: ID
    $billing: BillingInput
    $startNow: Boolean
    $useOnFileBilling: Boolean
    $promo: ID
  ) {
    changeMembership(
      toMembershipId: $toMembershipId
      billing: $billing
      startNow: $startNow
      useOnFileBilling: $useOnFileBilling
      promo: $promo
    )
  }
`;
