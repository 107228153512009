export interface Membership {
  id: string;
  mode: string;
  type: string;
  parentTitle: string;
  parentDescrip: string;
  image?: string;
  expirationDays: number;
  name: string;
  price: number;
  taxedPrice: number;
  desc: MembershipDescription;
  sale: MembershipSale;
  portal: boolean;
  children: [Membership];
  isZoomFit: boolean;
}

export interface MembershipSale {
  active: boolean;
  salePrice: number;
  taxedSalePrice: number;
  saved: number;
  extraDays: number;
  start: string;
  end: string;
  previousPromoRestrictions: string[];
  clientRestrictions: ClientRestrictions;
  promo: string;
  promoId: string;
}

export enum ClientRestrictions {
  Allow = 'ALLOW',
  Deny = 'DENY',
  Shareable = 'SHAREABLE',
  None = 'NONE'
}

export interface MembershipDescription {
  shortDescrip: string;
  longDescrip: string;
}
