import { Client } from '@hyp2/graphql';
import { Action, createReducer, createSelector, on } from "@ngrx/store";
import { LogoutActions } from '../../auth/actions';
import { OnfidoActions } from '../actions';
import { OnfidoDocument } from '../types/OnfidoDocument';

export const onfidoFeatureKey = 'onfido';

interface State {
  documents?: OnfidoDocument[];
  livePhotoId?: string;
  applicantId?: string;
  sdkToken?: string;
  phoneNumber?: string;
  client?: Client;
  loading: boolean;
}

const initialState: State = {
  loading: true,
};

export interface OnfidoState {
  [onfidoFeatureKey]: State,
}

export const reducer = createReducer(
  initialState,
  on(OnfidoActions.requestOnfidoSdkTokenSuccess, (state, { token, applicantId }) => ({ ...state, applicantId, sdkToken: token, loading: false })),
  on(OnfidoActions.onfidoFlowComplete, (state, { documents, livePhotoId }) => ({ ...state, documents, livePhotoId })),
  on(OnfidoActions.onfidoPhoneNumberSubmitted, (state, { phoneNumber }) => ({ ...state, phoneNumber, loading: true })),
  on(OnfidoActions.onfidoUserRegistered, (state, { client }) => ({ ...state, client, loading: false })),
  on(OnfidoActions.onfidoFlowRestarted, (state) => ({ ...initialState, applicantId: state.applicantId, sdkToken: state.sdkToken, loading: false })),
  on(OnfidoActions.onfidoFlowFailed, (state) => ({ ...state })),
  on(OnfidoActions.onfidoStateReset, (_) => initialState),
  on(LogoutActions.logoutClient, (_) => initialState),
);

export function reducers(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const selectSdkTokenState = createSelector(
  (state: OnfidoState) => state[onfidoFeatureKey],
  (state: State) => state.sdkToken,
);

export const selectLoading = createSelector(
  (state: OnfidoState) => state[onfidoFeatureKey],
  (state: State) => state.loading,
);

export const selectDocuments = createSelector(
  (state: OnfidoState) => state[onfidoFeatureKey],
  (state: State) => state?.documents,
);

export const selectLivePhotoId = createSelector(
  (state: OnfidoState) => state[onfidoFeatureKey],
  (state: State) => state?.livePhotoId,
);

export const selectApplicantId = createSelector(
  (state: OnfidoState) => state[onfidoFeatureKey],
  (state: State) => state?.applicantId,
);

export const selectPhoneNumber = createSelector(
  (state: OnfidoState) => state[onfidoFeatureKey],
  (state: State) => state?.phoneNumber,
);
