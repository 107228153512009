import { createReducer, on } from '@ngrx/store';
import { CancelMembershipActions } from '../actions';
import { LogoutActions } from '../../auth/actions';

export const cancelMembershipFeatureKey = 'cancelMembership';

export interface State {
  form: string;
  done: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: State = {
  form: undefined,
  done: false,
  loaded: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(CancelMembershipActions.cancelMembershipRequest, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    CancelMembershipActions.cancelMembershipSuccess,
    (state, { form, done }) => ({ ...state, form, done, loaded: true })
  ),
  on(CancelMembershipActions.cancelMembershipFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: true,
  })),
  on(LogoutActions.logoutClient, (state) => initialState)
);

export const getCancelMembershipForm = (state: State) => state.form;
export const getCancelMembershipDone = (state: State) => state.done;
export const getLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;
