import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { map, filter, withLatestFrom } from 'rxjs/operators';

import * as fromAuth from '../reducers';

import { isPlatformServer } from '@angular/common';
import { Observable, of } from 'rxjs';
import { InitTokenService } from './init-token.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedOutGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authStore: Store<fromAuth.AuthState>,
    private initTokenService: InitTokenService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  canActivate(route: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }
    this.initTokenService.initializeToken();
    return this.authStore.select(fromAuth.getJwt)
      .pipe(
        filter(jwt => !!jwt),
        withLatestFrom(
          this.authStore.select(fromAuth.getClient),
        ),
        map(([_, client]) => {
          const clientExists = !!client;
          if (clientExists) {
            const membership_id = route.queryParamMap.get('membership_id');
            const promo = route.queryParamMap.get('promo');
            this.router.navigate(['/checkout'], {
              queryParams: {
                membership_id,
                promo,
              },
            });
            return false;
          }
          return true;
        }),
      );
  }
}
