<mat-dialog-content class="dialog" data-cy="details">
  <div *ngIf="monthlyDetails">
    <h1 class="dialog__title" data-cy="details__monthly-title">{{ monthlyDetails.title }}</h1>
    <hr />
    <ul
      class="dialog__description"
      data-cy="details__monthly-desc"
      *ngFor="let description of monthlyDetails.description"
    >
      <li>{{ description }}</li>
    </ul>
  </div>
  <div *ngIf="yearlyDetails">
    <br />
    <h1 class="dialog__title" data-cy="details__yearly-title">{{ yearlyDetails.title }}</h1>
    <hr />
    <ul
      class="dialog__description"
      data-cy="details__yearly-desc"
      *ngFor="let description of yearlyDetails.description"
    >
      <li>{{ description }}</li>
    </ul>
  </div>
  <div *ngIf="packageDetails">
    <h1 class="dialog__title" data-cy="details__package-title">{{ packageDetails.title }}</h1>
    <hr />
    <ul
      class="dialog__description"
      data-cy="details__package-desc"
      *ngFor="let description of packageDetails.description"
    >
      <li>{{ description }}</li>
    </ul>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button (click)="closeDialog()" class="dialog__button" data-cy="details__close-button" mat-raised-button>
    {{ buttonText }}
  </button>
</mat-dialog-actions>