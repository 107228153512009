import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'hyp2-overlay-spinner',
  templateUrl: './overlay-spinner.component.html',
  styleUrls: ['./overlay-spinner.component.scss'],
})
export class OverlaySpinnerComponent implements OnInit, OnDestroy {
  private readonly MILLISECONDS_PER_SECOND = 1000;

  @Input()
  public messageTimeoutSeconds?: number;

  @Input()
  public message?: string;

  public showMessage$: Observable<boolean> = of(false);

  private timeout: NodeJS.Timeout;

  ngOnInit(): void {
    if (this.messageTimeoutSeconds > 0 && this.message) {
      this.timeout = setTimeout(() => {
        this.showMessage$ = of(true);
        clearTimeout(this.timeout);
      }, this.messageTimeoutSeconds * this.MILLISECONDS_PER_SECOND);
    }
    else if (this.message) {
      this.showMessage$ = of(true);
    }
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
