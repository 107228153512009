import { createAction, props } from '@ngrx/store';
import { Membership } from '../models';

export const getPromoMembershipsRequest = createAction(
  '[Portal Promo Memberships] Memberships Request',
  props<{ totalCount: number; promo?: string }>()
);
export const getPromoMembershipsSuccess = createAction(
  '[Portal Promo Memberships] Memberships Success',
  props<{ memberships: Membership[] }>()
);
export const getMembershipsRequest = createAction(
  '[Portal Memberships] Memberships Request',
  props<{ totalCount: number }>()
);
export const getMembershipsSuccess = createAction(
  '[Portal Memberships] Memberships Success',
  props<{ memberships: Membership[] }>()
);
export const getMembershipsFailure = createAction(
  '[Portal Memberships] Memberships Failure',
  props<{ error: any }>()
);
