<header>
  <div class="header-breadcrumb">
    <a
      href="https://zoomtan.com"
      class="header-breadcrumb__link"
      data-cy="zoomtan__url-link"
    >
      <mat-icon>arrow_left</mat-icon>
      <button class="header-breadcrumb__name">ZOOMTAN.COM</button> | Online Shop
      & Membership Portal
    </a>
  </div>
  <div class="header-content">
    <nav class="header-content__nav">
      <a class="header-content__alink">
        <img
          routerLink="/"
          class="header-content__logo"
          alt="ZOOMTAN/FIT BUY: Online Store & Member Portal"
          src="assets/images/zoomtan/zoomportal.png"
          alt="zt_buy_logo"
          data-cy="header__logo"
        />
      </a>
      <button
        mat-button
        *ngIf="message$ | async as message"
        [matMenuTriggerFor]="profile"
        data-cy="header__signed-in-button"
      >
        <span class="header-content__profile">
          <mat-icon class="header-content__icon--orange"
            >account_circle</mat-icon
          >
          <div class="header-content__message">
            {{ message }}
            <mat-icon>arrow_drop_down</mat-icon>
          </div>
        </span>
      </button>
      <mat-menu #profile="matMenu" data-cy="header__matMenu">
        <a>
          <button
            mat-menu-item
            (click)="routeToMyAccount()"
            data-cy="header__matMenu-profile"
          >
            My Account
          </button>
        </a>
        <a *ngIf="!(activeClientMembership$ | async)">
          <button
            mat-menu-item
            (click)="routeToBuyMembership()"
            data-cy="header__matMenu-buy-membership"
          >
            Buy Membership
          </button>
        </a>
        <a *ngIf="activeClientMembership$ | async">
          <button
            mat-menu-item
            (click)="routeToChangeMembership()"
            data-cy="header__matMenu-change-membership"
          >
            Change Membership
          </button>
        </a>
        <button
          mat-menu-item
          (click)="routeToCancelMembership()"
          data-cy="header__matMenu-cancel-membership"
        >
          Cancel Membership
        </button>
        <button
          mat-menu-item
          (click)="routeToMyReceipts()"
          data-cy="header__matMenu-receipts"
        >
          My Receipts
        </button>
        <button
          mat-menu-item
          routerLink="/"
          (click)="logout()"
          data-cy="header__matMenu-logout"
        >
          Logout
        </button>
      </mat-menu>
      <div class="header-content__profile--signin">
        <a
          *ngIf="isSupportedBrowser && !(message$ | async)"
          routerLink="/my-account"
          class="header-content__profile"
          data-cy="header__sign-in-button"
        >
          <mat-icon class="header-content__icon">account_circle</mat-icon>
          <div>Sign In</div>
        </a>
      </div>
    </nav>
  </div>
</header>
