import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResponseDialogComponent, ResponseDialogData } from '../components/response-dialog/response-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ResponseDialogService {
  dialogRef: MatDialogRef<ResponseDialogComponent>;

  constructor(private dialog: MatDialog) { }

  public openDialog(responseDialogData: ResponseDialogData) {
    this.dialogRef = this.dialog.open(ResponseDialogComponent, {
      data: responseDialogData,
      disableClose: true,
      hasBackdrop: true,
    });
  }
}
