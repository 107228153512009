import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Color } from '../../core/models/color';
import { isPlatformBrowser } from '@angular/common';
const Color = require('color');

@Injectable({
  providedIn: 'root',
})
export class PortalThemeService {
  //   $theme-colors: (
  //     "primary":#5C0F8C,
  //     "primary-light":#872AC1,
  //     "primary-dark":#470072,
  //     "secondary":#FFA400,
  //     "secondary-light":#FFB93A,
  //     "secondary-dark":#E68A00,
  //     "warning":#AC0000,
  //     "caution":#F26100,
  //     "information":#007BC3,
  //     "success":#009688,
  //     "sale":#D80000,
  //     "gray":#D5D5D5,
  //     "light-gray":#EEEEEE,
  //     "dark-gray":#333333,
  //     "med-gray":#8D8D8D,
  // );

  primaryColor = '#5C0F8C';
  primaryColorPalette: Color[] = [];

  accentColor = '#FFA400';
  accentColorPalette: Color[] = [];

  warnColor = '#AC0000';
  warnColorPalette: Color[] = [];

  foregroundColor = '#333333';
  foregroundColorPalette: Color[] = [];

  informationColor = '#007BC3';
  informationColorPalette: Color[] = [];

  successColor = '#009688';
  successColorPalette: Color[] = [];

  textColor = '#FFF';
  textColorPalette: Color[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  saveColor(colorPalette, themeType: string) {
    for (const color of colorPalette) {
      const key1 = `--theme-${themeType}-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-${themeType}-contrast-${color.name}`;
      const value2 = color.darkContrast ? 'rgba(black, 0.87)' : 'white';
      if (isPlatformBrowser(this.platformId)) {
        document.documentElement.style.setProperty(key1, value1);
        document.documentElement.style.setProperty(key2, value2);
      }
    }
  }

  savePrimaryColor() {
    this.primaryColorPalette = computeColors(this.primaryColor);
    this.saveColor(this.primaryColorPalette, 'primary');
  }

  saveAccentColor() {
    this.accentColorPalette = computeColors(this.accentColor);
    this.saveColor(this.accentColorPalette, 'accent');
  }

  saveWarnColor() {
    this.warnColorPalette = computeColors(this.warnColor);
    this.saveColor(this.warnColorPalette, 'warn');
  }

  saveForegroundColor() {
    this.foregroundColorPalette = computeColors(this.foregroundColor);
    this.saveColor(this.foregroundColorPalette, 'foreground');
  }

  saveInformationColor() {
    this.informationColorPalette = computeColors(this.informationColor);
    this.saveColor(this.informationColorPalette, 'information');
  }

  saveSuccessColor() {
    this.successColorPalette = computeColors(this.successColor);
    this.saveColor(this.successColorPalette, 'success');
  }

  saveTextColor() {
    this.textColorPalette = computeColors(this.textColor);
    this.saveColor(this.textColorPalette, 'text');
  }

  applyTheme() {
    this.savePrimaryColor();
    this.saveAccentColor();
    this.saveWarnColor();
    this.saveForegroundColor();
    this.saveInformationColor();
    this.saveSuccessColor();
    this.saveTextColor();
  }
}

function computeColors(hex: string): Color[] {
  return [
    getColorObject(Color(hex).lighten(52), '50'),
    getColorObject(Color(hex).lighten(37), '100'),
    getColorObject(Color(hex).lighten(26), '200'),
    getColorObject(Color(hex).lighten(12), '300'),
    getColorObject(Color(hex).lighten(6), '400'),
    getColorObject(Color(hex), '500'),
    getColorObject(Color(hex).darken(6), '600'),
    getColorObject(Color(hex).darken(12), '700'),
    getColorObject(Color(hex).darken(18), '800'),
    getColorObject(Color(hex).darken(24), '900'),
    getColorObject(Color(hex).lighten(50).saturate(30), 'A100'),
    getColorObject(Color(hex).lighten(30).saturate(30), 'A200'),
    getColorObject(Color(hex).lighten(10).saturate(15), 'A400'),
    getColorObject(Color(hex).lighten(5).saturate(5), 'A700'),
  ];
}

function getColorObject(value, name): Color {
  const c = Color(value);
  return {
    name: name,
    hex: c.hex(),
    darkContrast: c.isLight(),
  };
}
