<hyp2-account-information
  *ngIf="client$ | async as client"
  [client]="client"
  [locked]="updateBillingEligible$ | async"
  [verifyCell]="!client.contact.cell.verified"
  (verifyCellEvent)="openVerify()"
  (buttonEvent)="editBillingDialog($event)"
  (balanceEvent)="payBalance()"
  data-cy="profile__account-info"
></hyp2-account-information>
<hyp2-mat-card-profile
  *ngFor="let membership of memberships$ | async"
  [memberships]="membership"
  [history]="history$ | async"
  [categoryKey]="membershipKey"
  [client]="client$ | async"
  data-cy="profile__membership-info"
>
</hyp2-mat-card-profile>
<hyp2-mat-card-profile
  *ngFor="let package of packages$ | async"
  [packages]="package"
  [categoryKey]="packageKey"
  data-cy="profile__package-info"
></hyp2-mat-card-profile>
