import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class PrimeComponent extends PromoComponent {
  // id
  static path = 'zt0621prime';
  static promoCode = 'PP';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.MONTHLY;

  // availability
  enabled = false;

  // display
  imageFileDesktop = '/assets/images/promos/prime-desktop.jpg';
  imageFileMobile = '/assets/images/promos/prime-mobile.jpg';
  header = 'These "PRIME" deals will save you big on your first month of UV and/or Spray Tanning!';
  text = 'With this exclusive online-only special offer, new and returning members can try us out and save up to 67% on their first tanning month! We do not require any long-term commitments or contracts. Cancellation is FREE and can be completed online any time after just 10 days from the start date. Members who LOVE our service can continue after the first month at regular pricing or opt to cancel and use the remainder of the tanning month that they purchased.';
}
