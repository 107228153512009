import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { PayBalanceActions } from '../actions';
import { PayBalanceService } from '../services';

@Injectable()
export class PayBalanceEffects {
  payBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayBalanceActions.payBalanceRequest),
      switchMap((action) =>
        this.payBalanceService.payClientBalance(action.billing).pipe(
          switchMap((response) =>
            of(
              PayBalanceActions.payBalanceSuccess({
                response: response,
              })
            )
          ),
          catchError((error) =>
            of(PayBalanceActions.payBalanceFailure({ error: error.message }))
          )
        )
      )
    )
  );

  payBalanceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PayBalanceActions.payBalanceSuccess),
        tap((action) => {
          if (action.response.data.payBalance) {
            this.router.navigate(['pay-balance/success']);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private payBalanceService: PayBalanceService
  ) {}
}
