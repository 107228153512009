import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material';

import * as fromChangeMembership from './reducers';
import {
  ChangeMembershipInfoEffects,
  ChangeMembershipEffects,
} from './effects';
import { MatCardProfileComponent } from '../shared/components';
import {
  ChangeMembershipComponent,
  ChangeMemSuccessfulComponent,
} from './containers';
import {
  StartChangeComponent,
  ConfirmChangeComponent,
  CustomerServiceInfoComponent,
} from './components';
import { ChangeMembershipTableComponent } from './components/change-membership-table/change-membership-table.component';
import { ChangeMembershipChoicesComponent } from './components/change-membership-choices/change-membership-choices.component';
import { NoChangeAvailableComponent } from './components/no-change-available/no-change-available.component';
import { ChangeComponent } from './components/change/change.component';
import { ChangeMembershipRoutingModule } from './change-membership-routing.module';

export const COMPONENTS = [
  ChangeMemSuccessfulComponent,
  ChangeMembershipComponent,
  ChangeComponent,
  StartChangeComponent,
  ConfirmChangeComponent,
  CustomerServiceInfoComponent,
  ChangeMembershipTableComponent,
  ChangeMembershipChoicesComponent,
  NoChangeAvailableComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  entryComponents: [MatCardProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MaterialModule,
    ChangeMembershipRoutingModule,
    StoreModule.forFeature(
      fromChangeMembership.changeFeatureKey,
      fromChangeMembership.reducers
    ),
    EffectsModule.forFeature([
      ChangeMembershipInfoEffects,
      ChangeMembershipEffects,
    ]),
  ],
  providers: [CurrencyPipe, DatePipe],
})
export class ChangeMembershipModule {}
