import { createReducer, on } from "@ngrx/store";
import { RegisterClientActions, LogoutActions } from '../actions';
import { Client } from '@hyp2/graphql';

export const registerFeatureKey = 'register';

export interface State {
  newClient: boolean;
  texted: boolean;
  client: Client;
  error: string;
  loading: boolean;
}

export const initialState: State = {
  newClient: undefined,
  texted: undefined,
  client: undefined,
  error: null,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(RegisterClientActions.registerClientRequest, (state, { name, dob, cell }) => ({ ...state, name, dob, cell, error: null, loading: true })),
  on(RegisterClientActions.registerClientSuccess, (state, { newClient, texted, client }) => ({ ...state, newClient, texted, client, error: null, loading: false })),
  on(RegisterClientActions.registerClientFailure, (state, { error }) => ({ ...state, error, loading: false })),
  on(LogoutActions.logoutClient, (state) => (initialState)),
);

export const getIsNewClient = (state: State) => state.newClient;
export const getClientTexted = (state: State) => state.texted;
export const getClient = (state: State) => state.client;
export const getLoading = (state: State) => state.loading;
