import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BillingInput } from '@hyp2/graphql';

@Injectable({
  providedIn: 'root',
})
export class UpdateBillingService {
  constructor(private apollo: Apollo) {}

  updateBilling(billing: BillingInput): Observable<any> {
    return this.apollo.mutate({
      mutation: updateBilling,
      variables: {
        billing,
      },
    });
  }
}

const updateBilling = gql`
  mutation updateBilling($billing: BillingInput!) {
    updateBilling(billing: $billing)
  }
`;
