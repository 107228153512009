import { createAction, props } from '@ngrx/store';
import { BillingInput } from '@hyp2/graphql';

export const changeMembershipRequest = createAction(
  '[Profile] Change Membership Request',
  props<{
    toMembershipId: number;
    billing: BillingInput;
    startNow: boolean;
    useOnFileBilling: boolean;
    promo: string;
  }>()
);
export const changeMembershipSuccess = createAction(
  '[Profile] Change Membership Success',
  props<{ response: any }>()
);
export const changeMembershipFailure = createAction(
  '[Profile] Change Membership Failure',
  props<{ error: any, errorCode?: string }>()
);
