import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class ChangeMembershipInfoService {
  constructor(private apollo: Apollo) {}

  changeMembershipInfo(promo: string): Observable<any> {
    return this.apollo.query({
      query: getAllChangeMembershipInfo,
      variables: { promo },
    });
  }
}

const getAllChangeMembershipInfo = gql`
  query getAllChangeMembershipInfo($promo: ID) {
    getAllChangeMembershipInfo(promo: $promo) {
      lastPaid
      paidDate
      pricePerDay
      daysLeft
      amountOfValueLeft
      previousChange
      changeMembershipTo {
        newMembership {
          id
          name
          mode
          type
          price
          taxedPrice
          desc {
            shortDescrip
            longDescrip
          }
          sale {
            saved
            extraDays
            start
            end
            previousPromoRestrictions
            clientRestrictions
            promo
            promoId
          }
          ... on MembershipEft {
            recurringFee
          }
        }
        changeMembershipInfo {
          newMemPriceForDaysLeft
          chargeAmount
          preTaxChargeAmount
          newEndDate
          saved
          extraDays
          price
          pricePerDay
          now
          nameOfAction
        }
      }
    }
  }
`;
