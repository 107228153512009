import { Injectable } from '@angular/core';
import { DetailsDialogService } from '../shared/services';
import { ClientRestrictions, Membership } from '../core/models';
import { Observable } from 'rxjs';
import * as fromCore from '../core/reducers';
import * as fromAuth from '../auth/reducers';
import { select, Store } from '@ngrx/store';
import { take, map, combineLatest } from 'rxjs/operators';
import { PortalMembershipsActions } from '../core/actions';
import { SelectedItemService } from '../checkout/services';
import { Router } from '@angular/router';
import { DialogComponent } from '../shared/components';
import { MatDialog } from '@angular/material/dialog';
import { Client } from '@hyp2/graphql';
import { selectUnlessLoading } from '../shared/utils';
import moment from 'moment-timezone';

interface ModalData {
  title: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class PromosService {
  yearlyMemberships$: Observable<Membership[]> = this.coreStore.pipe(
    select(fromCore.getPromoMemberships),
  );
  monthlyMemberships$: Observable<Membership[]> = this.coreStore.pipe(
    select(fromCore.getMonthlyPromoMemberships),
  );
  zoomFitMemberships$: Observable<Membership[]> = this.coreStore.pipe(
    select(fromCore.getZoomFitPromoMemberships),
  );
  client$: Observable<Client> = this.authStore.pipe(
    selectUnlessLoading(fromAuth.getClient, fromAuth.getLoginLoading),
  );

  constructor(
    private router: Router,
    private detailsDialogService: DetailsDialogService,
    private coreStore: Store<fromCore.CoreState>,
    private authStore: Store<fromAuth.AuthState>,
    private selectedItemService: SelectedItemService,
    private dialog: MatDialog
  ) {}

  static promoUsed(client: Client, promo: string, previousPromoRestrictions: string[]): ModalData {
    for (const p of [promo, ...previousPromoRestrictions]) {
      if (client.promos?.used?.includes(p)) {
        return {
          title: 'Oops!',
          description: `You've already used this promo. Sorry you can only use it once.`,
        };
      }
    }
  }

  static promoNotAllowed(client: Client, promo: string, clientRestrictions: ClientRestrictions): ModalData {
    const allow = (clientRestrictions === ClientRestrictions.Allow);
    const deny = (clientRestrictions === ClientRestrictions.Deny);
    const shareable = (clientRestrictions === ClientRestrictions.Shareable);
    const specified = client.promos?.restricted?.includes(promo);
    const createdAt = moment.tz(client.createdAt, 'UTC').tz('America/New_York', true).valueOf();
    const newClient = Date.now() - createdAt < 1000*60*60*2;

    if ((allow && !specified) || (deny && specified) || (shareable && !specified && !newClient)) {
      return PromosService.promoNotAllowedData;
    }
  }

  static promoUnavailableData = {
    title: 'Promo Unavailable',
    description: 'This promo is no longer available. Memberships can be purchased at the normal price from the home page.',
  };

  static promoNotAllowedData = {
    title: 'Oops!',
    description: `This promo isn't available to your account.`,
  };

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  routeToBuyMembership() {
    this.router.navigate(['/']);
  }

  checkClientPromoStatus(promoCode: string, previousPromoRestrictions: string[], clientRestrictions: ClientRestrictions) {
    return this.client$.pipe(
      map((client) =>
        this.doValidate(client, promoCode, previousPromoRestrictions, clientRestrictions)
      ),
    );
  }

  checkClientPromoStatusByMembership(promoCode: string, memberships$: Observable<Membership[]>) {
    return memberships$.pipe(
      combineLatest(this.client$),
      map(([memberships, client]) =>
        memberships?.map((m) =>
          this.doValidate(client, promoCode, m.sale?.previousPromoRestrictions, m.sale?.clientRestrictions)
        ).find(data => data)
      ),
    )
  }

  private doValidate(client: Client, promoCode: string, previousPromoRestrictions: string[], clientRestrictions: ClientRestrictions) {
    if (!client || !promoCode) return;
    if (!previousPromoRestrictions) previousPromoRestrictions = [];
    return PromosService.promoUsed(client, promoCode, previousPromoRestrictions)
      || PromosService.promoNotAllowed(client, promoCode, clientRestrictions);
  }

  openPromoUsedDialog(data: {}) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data,
      disableClose: true,
    });
    dialogRef
    .afterClosed()
    .pipe(take(1))
    .subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  openPromoUnavailableDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: PromosService.promoUnavailableData,
      disableClose: true,
    });

    dialogRef
    .afterClosed()
    .pipe(take(1))
    .subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  public fetchMemberships(promo: string) {
    this.coreStore.dispatch(
      PortalMembershipsActions.getPromoMembershipsRequest({
        totalCount: 20,
        promo,
      })
    );
  }

  membershipClicked(membership: Membership, promo: string) {
    this.selectedItemService.selectMembership(membership);
    this.navigateToRegisterOrCheckout(promo);
  }

  navigateToRegisterOrCheckout(promo) {
    this.authStore
      .pipe(take(1), select(fromAuth.getClient))
      .subscribe((client) => {
        if (!client) {
          this.router.navigate(['/register']);
        } else {
          this.router.navigate(['/checkout']);
        }
      });
  }

  openDetailsDialog(membership) {
    this.detailsDialogService.openDialog(membership, null);
  }
}
