<div class="container">
  <mat-card class="card mat-elevation-z6">
    <mat-card-title
      class="card__title"
      data-cy="change-membership__success-title"
    >
      Pay Balance Successful
    </mat-card-title>
    <mat-card-content
      class="card__content"
      data-cy="change-membership__success-msg"
    >
      You have successfully payed your balance. Please visit the "receipts" tab
      on your profile to access your receipt.
    </mat-card-content>
    <mat-card-actions class="card__actions">
      <button
        class="card__actions-button"
        (click)="configureRoute()"
        mat-raised-button
        data-cy="change-membership__success-button"
      >
        Go To Profile
      </button>
    </mat-card-actions>
  </mat-card>
</div>
