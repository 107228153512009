import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BillingInput } from '@hyp2/graphql';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CheckoutPurchaseService {
  constructor(private apollo: Apollo, private route: ActivatedRoute) {}

  checkout(
    membershipId: string,
    clientBilling: BillingInput,
    useOnFileBilling: boolean,
    promo: string,
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: checkout,
      variables: {
        membershipId,
        useOnFileBilling,
        billing: clientBilling,
        promo,
      },
    });
  }
}

const checkout = gql`
  mutation checkout(
    $membershipId: ID
    $useOnFileBilling: Boolean
    $billing: BillingInput
    $promo: ID
  ) {
    checkout(
      membershipId: $membershipId
      useOnFileBilling: $useOnFileBilling
      billing: $billing
      promo: $promo
    )
  }
`;
