<div class="container">
  <mat-tab-group [selectedIndex]="this.tabIndex">
    <nav>
      <mat-tab label="Zoom Tan Memberships">
        <hyp2-zoomtan-memberships
          [memberships]="zoomTanMemberships$ | async"
          (buttonEvent)="membershipClicked($event)"
        ></hyp2-zoomtan-memberships>
      </mat-tab>
    </nav>
    <nav>
      <mat-tab label="Zoom Fit Memberships">
        <hyp2-zoomfit-memberships
          [memberships]="zoomFitMemberships$ | async"
          (buttonEvent)="membershipClicked($event)"
        ></hyp2-zoomfit-memberships>
      </mat-tab>
    </nav>
  </mat-tab-group>
</div>
