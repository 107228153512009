import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { ChangeMembershipInfoService } from '../services';

import * as fromAuth from '../../auth/reducers';
import { ChangeMembershipInfoActions } from '../actions';

@Injectable()
export class ChangeMembershipInfoEffects {
  changeMembershipInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeMembershipInfoActions.changeMembershipInfoRequest),
      switchMap((action) =>
        this.changeMembershipInfoService.changeMembershipInfo(action.promo).pipe(
          switchMap((response) =>
            of(
              ChangeMembershipInfoActions.changeMembershipInfoSuccess({
                changeMembershipInfo: response.data.getAllChangeMembershipInfo,
              })
            )
          ),
          catchError((error) =>
            of(
              ChangeMembershipInfoActions.changeMembershipInfoFailure({
                error: error.graphQLErrors.map((errors) => errors.message),
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authStore$: Store<fromAuth.AuthState>,
    private changeMembershipInfoService: ChangeMembershipInfoService
  ) {}
}
