<div
title="Change Membership Table"
*ngIf="currentMembership"
>
<table
  mat-table
  class="table mat-elevation-z8"
  [dataSource]="[currentMembership]"
  data-cy="change-membership__table"
>
  <!-- Current Membership -->
  <ng-container matColumnDef="currentMembership">
    <th class="table__title" mat-header-cell *matHeaderCellDef>
      Current Membership
    </th>
    <td class="table__content-plain" mat-cell *matCellDef="let membership">
      {{membership.name }}
    </td>
  </ng-container>

  <!-- New Membership -->
  <ng-container matColumnDef="newMembership">
    <th class="table__title" mat-header-cell *matHeaderCellDef>
      Select New Membership
    </th>
    <td class="table__content" mat-cell *matCellDef="let membership">
      {{ newMembership?.name }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
</div>
