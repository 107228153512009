import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromCheckout from '../reducers'
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class CheckoutSuccessfulGuardService  implements CanActivate {
  constructor(
    private checkoutStore: Store<fromCheckout.State>,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkoutStore
      .select(fromCheckout.getCheckoutSuccess)
      .pipe(
        map((item) => {
          return item ? true : false;
        })
      )
      .pipe(
        map((value) => {
          if (value) {
            return true;
          }
          this.router.navigate(['/']);
          return false;
        })
      );
  }
}
