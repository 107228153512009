import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  VerifyCellDialogComponent,
  VerifyCellDialogData,
} from '../components/verify-cell-dialog/verify-cell-dialog.component';
import { TextResendActions, VerifyCellActions } from '../../auth/actions';
import * as fromAuth from '../../auth/reducers';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VerifyCellDialogService {
  dialogRef: MatDialogRef<VerifyCellDialogComponent>;

  constructor(
    private authStore: Store<fromAuth.AuthState>,
    private dialog: MatDialog
  ) {}

  public openDialog(dialogData: VerifyCellDialogData) {
    this.dialogRef = this.dialog.open(VerifyCellDialogComponent, {
      data: dialogData,
    });
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result?.length === 6) {
          this.authStore.dispatch(
            VerifyCellActions.verifyCellRequest({ pin: result })
          );
        }
      });
  }
  public openResendVerifyCell() {
    this.authStore.dispatch(TextResendActions.textResendRequest());
    this.openDialog({
      title: 'Verify Phone Number',
      description:
        'We have sent a 6-digit code to the entered phone number. Please enter the 6-digit code below.',
    });
  }
}
