import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class LabordayYearlyComponent extends PromoComponent {
  // id
  static path = 'zt090621yp';
  static promoCode = 'LP';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.YEARLY;

  // display
  imageFileDesktop = '/assets/images/promos/laborday-yearly-desktop.jpg';
  imageFileMobile = '/assets/images/promos/laborday-yearly-mobile.jpg';
  header = 'Get 3 extra months for only $3 with this special Labor Day Offer!';
  text = 'In celebration of Labor Day, we have an exclusive online offer just for you! You can get 3 extra months for only $3 when you purchase any of our yearly memberships. Yearly membersalready save big over monthly members but with this deal you can save up to an extra of $121.75. Don\'t miss out!';
}
