<div class="container">
  <img class="check" src="assets/images/icons/check.png" />
  <h1 class="title">
    Purchase Complete
  </h1>
  <p class="success-text">
    You have successfully completed your purchase. Please visit the "receipts"
    tab on your profile to access your receipt.
  </p>
  <button
    class="actions-button"
    routerLink="/my-account"
    [disabled]="loading$ | async"
    data-cy="checkout-success__button"
  >
    OKAY
  </button>
  <mat-divider></mat-divider>

  <div class="wording">
    <h1 class="title">
      Important Membership Information
    </h1>
    <p class="success-text">
      Zoom Tan/Fit memberships can be used at any Zoom Tan/Fit locations
      nationwide
    </p>
    <ol class="list">
      <li>
        ALL Zoom Tan/Fit membership cancellations can ONLY be done online at
        buy.zoomtan.com/cancel-membership. It's very simple, very easy and the
        ONLY way to cancel.
      </li>
      <li>
        Text "ZOOM" to 239-690-6667 to obtain any information on your package or
        membership. Customer service does not have access to this information.
      </li>
      <li>
        You are eligible to cancel the auto pay portion of your month to month
        membership online after 10 days. You can continue to use your membership
        after you cancel the auto pay portion of it until your current month
        ends.
      </li>
      <li>
        If you joined on the 29th, 30th or 31st of any given month you will be
        drafted on the last day of any month that has fewer days than there were
        in the month you joined. (Example: If you joined on March 31st you would
        be drafted on April 30th.)
      </li>
      <li>
        Your membership is only cancelled when you receive a text message from
        Zoom Tan/Fit confirming the successful online cancellation of your
        membership.<br />
        If you never received a text confirmation from us, you must assume you
        are not cancelled even if an employee says you are cancelled.
      </li>
      <li>
        If any employee tells you that you can cancel any other way than online
        please report them to Customer Service at (239) 465-4285.
      </li>
      <li>
        You have until 11:59 PM (Eastern) the night before your draft date in
        order to cancel your auto draft and avoid any further drafts on your
        account. <br />It's your membership, it's your account and it's your
        responsibility to cancel timely.
      </li>
      <li>
        You have to pay any balance you may have on your account before you can
        cancel online. There are no exceptions to this policy and you can do
        this at buy.ZoomTan.com/pay-balance or at any Zoom Tan/Fit location
      </li>
      <li>
        Zoom Tan/Fit communicates all important messages via text messages only.
        If you opt out of texting you will be responsible for getting this
        information from another source.
      </li>
      <li>
        We won't ask you to pay more if you use your membership every day so
        please don't ask us for a refund if you didn't use your membership as
        often as you would have liked.
      </li>
      <li>
        If an employee is ever rude to you please call Customer Service
        immediately and we will take action.
      </li>
      <li>
        Members who are verbally abusive to any of our employees will be
        permanently terminated and not allowed to use services at any Zoom
        Tan/Fit ever again.
      </li>
    </ol>
  </div>
</div>
<!-- Facebook Pixel Code -->
<script>
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );
  fbq('init', '5316744001731870');
  fbq('track', 'PageView');
</script>
<noscript
  ><img
    height="1"
    width="1"
    style="display: none;"
    src="https://www.facebook.com/tr?id=5316744001731870&ev=PageView&noscript=1"
/></noscript>
<!-- End Facebook Pixel Code -->
<!-- Facebook Pixel Code -->
<script>
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );
  fbq('init', '1602396366443768');
  fbq('track', 'PageView');
</script>
<noscript
  ><img
    height="1"
    width="1"
    style="display: none;"
    src="https://www.facebook.com/tr?id=1602396366443768&ev=PageView&noscript=1"
/></noscript>
<!-- End Facebook Pixel Code -->
