import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class LabordayMonthlyComponent extends PromoComponent {
  // id
  static path = 'zt090621mp';
  static promoCode = 'LD';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.MONTHLY;

  // display
  imageFileDesktop = '/assets/images/promos/laborday-monthly-desktop.jpg';
  imageFileMobile = '/assets/images/promos/laborday-monthly-mobile.jpg';
  header = 'Get 30% OFF your first month of unlimited Spray and/or UV tanning!';
  text = 'In celebration of Labor Day, we have an exclusive online offer just for you! New and returning members can try us out and save 30% on their first tanning month! We do not require any long-term commitments or contracts. Cancellation is FREE and can be completed online any time after just 10 days from the start date. Members who LOVE our service can continue after the first month at regular pricing or opt to cancel and use the remainder of the tanning month that they purchased.';
}
