import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TermsAndConditionsDialogComponent } from '../components/tos/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import _replace from 'lodash/replace';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsService {
  dialogRef: MatDialogRef<TermsAndConditionsDialogComponent>;
  constructor(private formsDialog: MatDialog) {}

  public openDialog(parsedForms: string[]) {
    this.dialogRef = this.formsDialog.open(TermsAndConditionsDialogComponent, {
      data: parsedForms,
    });
  }

  public closeDialog() {
    this.dialogRef?.close();
  }
}
