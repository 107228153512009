import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Membership } from '../../../core/models';
import { DetailsDialogService } from '../../../shared/services';

abstract class MembershipCardComponent {
  @Input() membership: Membership;
  @Output() buttonEvent = new EventEmitter();

  zoomTan = false;
  zoomFit = false;

  abstract yearlyMembership: Membership;
  abstract monthlyMembership: Membership;

  constructor(private detailsDialogService: DetailsDialogService) {}

  openDetailsDialog() {
    this.detailsDialogService.openDialog(
      this.monthlyMembership,
      this.yearlyMembership
    );
  }
}

@Component({
  selector: 'hyp2-zoomtan-membership-card',
  templateUrl: './membership-card.component.html',
  styleUrls: ['./membership-card.component.scss'],
})
export class ZoomTanMembershipCardComponent extends MembershipCardComponent {
  zoomTan = true;
  get yearlyMembership() { return this.membership }
  get monthlyMembership() { return this.membership.children[0] }
}

@Component({
  selector: 'hyp2-zoomfit-membership-card',
  templateUrl: './membership-card.component.html',
  styleUrls: ['./membership-card.component.scss'],
})
export class ZoomFitMembershipCardComponent extends MembershipCardComponent {
  zoomFit = true;
  get yearlyMembership() { return null }
  get monthlyMembership() { return this.membership }
}
