import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'hyp2-pay-balance-success',
  templateUrl: './pay-balance-success.component.html',
  styleUrls: ['./pay-balance-success.component.scss']
})
export class PayBalanceSuccessComponent implements OnInit {
  isChangeMem: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  configureRoute() {
    this.isChangeMem = this.router.getCurrentNavigation()?.extras?.state?.changeMembership;
    if (this.isChangeMem) {
      this.router.navigate(['/change-membership']);
    } else {
      this.router.navigate(['/my-account']);
    }
  }

}
