import { createReducer, on } from '@ngrx/store';
import { CheckoutPurchaseActions } from '../actions';

export const checkoutPurchaseFeatureKey = 'checkoutPurchase';

export interface State {
  loading: boolean;
  success: boolean;
  error: string;
}

export const initialState: State = {
  loading: false,
  success: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(CheckoutPurchaseActions.checkoutPurchaseRequest, state => ({
    ...state,
    error: null,
    loading: true,
    success: false
  })),
  on(CheckoutPurchaseActions.checkoutPurchaseSuccess, state => ({ ...state, loading: false, success: true })),
  on(CheckoutPurchaseActions.checkoutPurchaseFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    success: false
  }))
);

export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getSuccess = (state: State) => state.success;
