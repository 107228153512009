<mat-card class="card mat-elevation-z3">
  <mat-form-field appearance="outline" formGroupName="clientData">
    <mat-label>First Name</mat-label>
    <input
      mask="Y{30}"
      [patterns]="customPatterns"
      matInput
      placeholder="First Name"
      autocomplete="given-name fname"
      name="fname"
      formControlName="firstName"
      data-cy="register__fname-input"
    />
    <mat-error
      *ngIf="this.firstName.hasError('pattern')"
      data-cy="register__fname--inv-char"
    >
      Invalid Characters
    </mat-error>
    <mat-error
      *ngIf="this.firstName.hasError('minlength')"
      data-cy="register__fname--inv-fname"
    >
      Invalid First Name
    </mat-error>
    <mat-error
      *ngIf="this.firstName.hasError('maxlength')"
      data-cy="register__fname--inv-limit"
    >
      Maximum Limit of 30 reached
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" formGroupName="clientData">
    <mat-label>Last Name</mat-label>
    <input
      mask="Y{30}"
      [patterns]="customPatterns"
      [dropSpecialCharacters]="false"
      matInput
      placeholder="Last Name"
      name="lname"
      autocomplete="family-name lname"
      formControlName="lastName"
      data-cy="register__lname-input"
    />
    <mat-error
      *ngIf="this.lastName.hasError('pattern')"
      data-cy="register__lname--inv-char"
    >
      Invalid Characters
    </mat-error>
    <mat-error
      *ngIf="this.lastName.hasError('minlength')"
      data-cy="register__lname--inv-lname"
    >
      Invalid Last Name
    </mat-error>
    <mat-error
      *ngIf="this.lastName.hasError('maxlength')"
      data-cy="register__lname--inv-limit"
    >
      Maximum Limit of 30 reached
    </mat-error>
  </mat-form-field>
  <!-- TODO come back and fix the type-->
  <mat-form-field appearance="outline" formGroupName="clientData">
    <mat-label>Date Of Birth</mat-label>
    <input
      matInput
      placeholder="MM/DD/YYYY"
      name="bday"
      id="bday"
      formControlName="dob"
      autocomplete="bday"
      data-cy="register__dob-input"
      mask="M0/d0/0000"
      [dropSpecialCharacters]="false"
    />
    <mat-error
      *ngIf="this.dob.hasError('required')"
      data-cy="register__dob--inv-required"
    >
      Invalid DOB
    </mat-error>
    <mat-error
      *ngIf="this.dob.hasError('minlength')"
      data-cy="register__dob--inv-minlength"
    >
      Invalid DOB
    </mat-error>
    <mat-error
      *ngIf="this.dob.hasError('maxlength')"
      data-cy="register__dob--inv-maxlength"
    >
      Invalid DOB
    </mat-error>
    <mat-error
      *ngIf="this.dob.hasError('dobEarly')"
      data-cy="register__dob--inv-early"
    >
      DOB is before 1910-01-01
    </mat-error>
    <mat-error
      *ngIf="this.dob.hasError('dobPastNow')"
      data-cy="register__dob--inv-past"
    >
      DOB is after today
    </mat-error>
    <mat-error
      *ngIf="this.dob.hasError('dobYoungerThanFourteen')"
      data-cy="register__dob--inv-younger-than-fourteen"
    >
      You must be 14 years of age or older to sign up online.
    </mat-error>
  </mat-form-field>
  <mat-form-field
    class="dlform"
    appearance="outline"
    formGroupName="clientData"
  >
    <mat-label>Drivers License Number</mat-label>
    <input
      formControlName="license"
      name="license"
      id="license"
      matInput
      placeholder="Enter Drivers License Number"
      autocomplete="on"
      data-cy="register__license-input"
    />
    <mat-icon matSuffix>picture_in_picture</mat-icon>
    <mat-hint class="dltext">
      Driver's license & credit card are required to be presented in-person on
      your first visit.
    </mat-hint>
    <mat-error
      *ngIf="this.license.hasError('pattern')"
      data-cy="register__license--inv-char"
      >Invalid Characters</mat-error
    >
    <mat-error
      *ngIf="this.license.hasError('minlength')"
      data-cy="register__license--inv-minlength"
      >Minimum length is 5
    </mat-error>
  </mat-form-field>
  <mat-form-field
    class="dlform2"
    appearance="outline"
    formGroupName="clientData"
  >
    <mat-label>Cell Phone Number</mat-label>
    <input
      type="tel"
      autocomplete="tel-national"
      matInput
      placeholder="555-555-1234"
      formControlName="phoneNumber"
      mask="(000) 000-0000"
      data-cy="register__phone-input"
    />
    <mat-icon matSuffix>phone</mat-icon>
    <mat-hint>Include area code</mat-hint>
    <mat-error
      *ngIf="this.phoneNumber.hasError('startingOne')"
      data-cy="register__phone--inv-number"
    >
      Phone Number can't start with +1
    </mat-error>
    <mat-error
      *ngIf="this.phoneNumber.hasError('minlength')"
      data-cy="register__phone--inv-minlength"
    >
      Phone Number is to short
    </mat-error>
  </mat-form-field>
  <ng-container formGroupName="clientData">
    <mat-checkbox formControlName="sms" data-cy="sms-checkbox">
      <span class="card__sms-text">
        You agree to receive automated SMS messages from Zoom Tan.  Standard text messaging and data rates may apply.    You can reply STOP at any time to end.
      </span>
    </mat-checkbox>
  </ng-container>
  <button
    [disabled]="!registerForm.valid"
    (click)="buttonEvent.emit(registerForm)"
    mat-raised-button
    class="card__button"
    data-cy="register__confirm-button"
  >
    Confirm
  </button>
</mat-card>
