import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class RefreshClientService {
  constructor(private apollo: Apollo) {}

  getRefreshClient(): Observable<any> {
    return this.apollo.query({
      query: getClientRefresh,
    });
  }
}

export const getClientRefresh = gql`
  fragment refreshClientMembershipFields on ClientMembership {
    id
    membership {
      id
      name
      mode
      price
      taxedPrice
      desc {
        shortDescrip
        longDescrip
      }
      type
      ... on MembershipEft {
        recurringFee
      }
      expirationDays
      portal
    }
    dates {
      start
      end
      renewal
    }
    status {
      active
      canceled
      offline
      previousChange
      changeable
    }
    prices {
      purchaseAmount
    }
    billingDayOfMonth
    createdAt
  }

  query getClientRefresh {
    getClientRefresh {
      contact {
        email {
          address
          allowed
          bad
        }
        cell {
          number
          allowed
          bad
          verified
          verifiedAt
        }
      }
      dob
      active
      balance
      eftBalance
      ... on Client {
        createdAt
        promos {
          used
          restricted
        }
        clientMemberships {
          activeMembership {
            ...refreshClientMembershipFields
          }
          memberships {
            ...refreshClientMembershipFields
          }
        }
        billing {
          billingName
          cardInfo {
            lastFourCardNumber
            cardType
            expireMonth
            expireYear
          }
          badCard
          deadCard
          expiredCard
          recurringFee
        }
        receipts(query: ALL) {
          id
          amount
          createdAt
          agreement
        }
        address {
          line1
          line2
          city
          state
          zip
        }
      }
    }
  }
`;
