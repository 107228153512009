import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromGetPortalMemberships from './portalMemberships.reducer';

export const coreFeatureKey = 'core';

export interface CoreState {
  [fromGetPortalMemberships.portalMembershipsFeatureKey]: fromGetPortalMemberships.State;
  [fromGetPortalMemberships.portalPromoMembershipsFeatureKey]: fromGetPortalMemberships.State;
}

export interface State extends fromRoot.State {
  [coreFeatureKey]: CoreState;
}

export function reducers(state: CoreState | undefined, action: Action) {
  return combineReducers({
    [fromGetPortalMemberships.portalMembershipsFeatureKey]:
      fromGetPortalMemberships.reducer,
    [fromGetPortalMemberships.portalPromoMembershipsFeatureKey]:
      fromGetPortalMemberships.promoReducer,
  })(state, action);
}

export const selectCoreState = createFeatureSelector<CoreState>(coreFeatureKey);

// Get Memberships Selector
export const selectGetPromoMembershipsState = createSelector(
  selectCoreState,
  (state: CoreState) => state.portalPromoMemberships
);
export const selectGetMembershipsState = createSelector(
  selectCoreState,
  (state: CoreState) => state.portalMemberships
);
export const getMemberships = createSelector(
  selectGetMembershipsState,
  fromGetPortalMemberships.getPortalMemberships
);
export const getPromoMemberships = createSelector(
  selectGetPromoMembershipsState,
  fromGetPortalMemberships.getPortalMemberships
);
export const getMonthlyPortalMemberships = createSelector(
  getMemberships,
  fromGetPortalMemberships.getMonthlyMemberships
);
export const getMonthlyPromoMemberships = createSelector(
  getPromoMemberships,
  fromGetPortalMemberships.getMonthlyMemberships
);
export const getZoomFitMemberships = createSelector(
  selectGetMembershipsState,
  fromGetPortalMemberships.getZoomFitPortalMemberships
);
export const getZoomFitPromoMemberships = createSelector(
  selectGetPromoMembershipsState,
  fromGetPortalMemberships.getZoomFitPortalMemberships
);
export const getZoomTanMemberships = createSelector(
  selectGetMembershipsState,
  fromGetPortalMemberships.getZoomTanPortalMemberships
);
export const getMembershipIds = createSelector(
  selectGetMembershipsState,
  fromGetPortalMemberships.getPortalMembershipIds
);
export const getMembershipById = ((id) =>
  createSelector(
    selectGetMembershipsState,
    fromGetPortalMemberships.getMembershipById(id),
  )
);
export const getPromoMembershipById = ((id) =>
    createSelector(
      selectGetPromoMembershipsState,
      fromGetPortalMemberships.getPromoMembershipById(id),
    )
);
export const getPortalMembershipsLoaded = createSelector(
  selectGetMembershipsState,
  fromGetPortalMemberships.getPortalMembershipsLoaded
);
export const getPortalPromoMembershipsLoaded = createSelector(
  selectGetPromoMembershipsState,
  fromGetPortalMemberships.getPortalMembershipsLoaded
);
