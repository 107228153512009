import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../auth/services';

import {
  ChangeMembershipComponent,
  ChangeMemSuccessfulComponent,
} from './containers';
import { ChangeMembershipGuardService } from './services';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ChangeMembershipComponent,
    canActivate: [ ChangeMembershipGuardService],
    data: {
      title: 'Change Membership',
      description:
        'Upgrade, Downgrade Or Swap Out Your Yearly Or Monthly Zoom Tan Membership Here',
    },
  },
  {
    path: 'success',
    pathMatch: 'full',
    component: ChangeMemSuccessfulComponent,
    canActivate: [ ChangeMembershipGuardService],
    data: {
      title: 'Change Membership Success',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChangeMembershipRoutingModule {}
