import { Component, Input } from '@angular/core';
import { Membership } from '@hyp2/graphql';

@Component({
  selector: 'hyp2-change-membership-table',
  templateUrl: './change-membership-table.component.html',
  styleUrls: ['./change-membership-table.component.scss'],
})
export class ChangeMembershipTableComponent {
  displayedColumns: string[] = ['currentMembership', 'newMembership'];

  @Input() currentMembership: Membership;
  @Input() newMembership: Membership;
}
