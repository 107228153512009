import moment from 'moment';
import { BillingInput } from '@hyp2/graphql';

export function getRandomString(length = 10): string {
  const chars = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
  return [...Array(length)]
    .map((i) => chars[(Math.random() * chars.length) | 0])
    .join(``);
}

export function getRandomNumber(length = 10): number {
  const chars = [...'123456789'];
  return +(
    '2' +
    [...Array(length - 1)]
      .map((i) => chars[(Math.random() * chars.length) | 0])
      .join(``)
  );
}

export function getRandomDate(
  start = new Date(1950, 0, 1),
  end = new Date(2000, 0, 1)
): string {
  const date = new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export function getRandomClientBilling(): BillingInput {
  return {
    billingName: this.getRandomString(),
    cardNumber: this.getRandomNumber(16)?.toString(),
    cardType: 'VI',
    cardCVV: this.getRandomNumber(3)?.toString(),
    expireMonth: '11',
    expireYear: moment().add(1, 'year').format('YY'),
    save: true,
  };
}
