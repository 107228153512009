export const errorMapExclude = {
         auth: ['99'],
         membership: [
           '2115',
           '2113',
           '2116',
           '2137',
           '2030',
           '2029',
           '2141',
           '2031',
           '10100',
         ],
         client: ['3106'],
       };
