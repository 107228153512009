import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Integrations } from '@sentry/tracing';

if (environment.mode !== 'development') {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.mode,
    denyUrls: [
      /inspectlet.js/i,
      /^chrome:\/\//i,
      /pinterest\.com/i,
      /pinimg\.com/i,
    ],
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          environment.production
            ? 'https://buy.zoomtan.com'
            : 'https://v2.zoomtan.com',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0, // environment.production ? 0.1 : 1.0,
  });
  Sentry.setTag('mode', 'frontend');
}

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
