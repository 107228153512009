import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BillingInput } from '@hyp2/graphql';

@Injectable({
  providedIn: 'root',
})
export class PayBalanceService {
  constructor(private apollo: Apollo) {}

  payClientBalance(billing: BillingInput): Observable<any> {
    return this.apollo.mutate({
      mutation: payBalance,
      variables: {
        billing,
        useOnFileBilling: false,
      },
    });
  }
}

const payBalance = gql`
  mutation payBalance($billing: BillingInput!, $useOnFileBilling: Boolean) {
    payBalance(billing: $billing, useOnFileBilling: $useOnFileBilling)
  }
`;
