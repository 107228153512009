import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { map, switchMap, take, tap, withLatestFrom, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { SEOService } from '../../../../../src/app/shared/services/SEO.service';

import { ChangeMembershipActions, ChangeMembershipInfoActions } from '../../actions';
import * as fromAuth from '../../../auth/reducers';
import * as fromProfile from '../../reducers';
import { SelectedItem } from '../../../checkout/models';

import {
  BillingInput,
  ChangeMembership,
  ChangeMembershipTo,
  Client,
  ClientMembership,
  Membership,
  MembershipEft,
} from '@hyp2/graphql';
import { MembershipMode } from '@libs/be/graphql/src/typings';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { StartChange } from '../../interface';
import { SelectedItemService } from '../../../checkout/services';
import { PromosService } from '../../../promo/promos.service';
import { PromoComponent } from '../../../promo/main/promo.component';

@Component({
  selector: 'hyp2-change-membership',
  templateUrl: './change-membership.component.html',
  styleUrls: ['./change-membership.component.scss'],
})
export class ChangeMembershipComponent implements OnInit, OnDestroy {
  client$: Observable<Client>;
  currentMembership$: Observable<Membership>;
  currentClientMembership$: Observable<ClientMembership>;
  membershipChanges$: Observable<ChangeMembershipTo[]>;
  membershipChangeInfo$: Observable<ChangeMembership>;
  confirmChangeInformation$: Observable<any>;
  isBadClientBilling$: Observable<boolean>;
  filteredMembershipChanges$: Observable<ChangeMembershipTo[]>;
  startChangeData$: Observable<StartChange>;
  loading$: Observable<boolean | Boolean>;
  showTos$: Observable<boolean>;

  clientSub: Subscription;
  defaultSelectionSub: Subscription;

  newMembershipChange$ = new BehaviorSubject<ChangeMembershipTo>(null);
  showConfirmChange$ = new BehaviorSubject<boolean>(false);
  clientBilling$ = new BehaviorSubject<BillingInput>(defaultBilling);
  newCard$ = new BehaviorSubject<boolean>(false);
  showMonthlyMemberships$ = new BehaviorSubject<boolean>(true);
  itemSelected$ = new BehaviorSubject<any>({});

  item: SelectedItem;
  isStartNow: boolean;
  changeTitle: string;

  useOnFileBilling = true;
  isContinueButtonEnabled = false;
  membershipSelected = false;
  membershipConfirmed = false;
  showChoices = true;
  showStartChoices = false;
  agreed = false;

  selectMemDesc =
    'Please select the membership you would like to change to and click "continue" below.';
  selectStartDesc =
    'Please select how you would like the change to be handled.';

  selectStart = 'Select New Membership Start';
  selectNew = 'Select New Membership';

  get title() {
    return this.showStartChoices ? this.selectStartDesc : this.selectMemDesc;
  }
  get smallTitle() {
    return this.showStartChoices ? this.selectStart : this.selectNew;
  }

  constructor(
    private authStore$: Store<fromAuth.State>,
    private profileStore$: Store<fromProfile.State>,
    private router: Router,
    private route: ActivatedRoute,
    private seo: SEOService,
    public selectedItemService: SelectedItemService,
    public promosService: PromosService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (!isPlatformServer(this.platformId)) {
      const promo = this.route.snapshot.queryParamMap.get('promo');
      this.profileStore$.dispatch(
        ChangeMembershipInfoActions.changeMembershipInfoRequest({ promo })
      );
    }
  }

  ngOnDestroy() {
    this.clientSub?.unsubscribe();
    this.defaultSelectionSub?.unsubscribe();
  }

  ngOnInit() {
    this.seo.generateTags([
      {
        title: 'Change Membership',
        description:
          'Upgrade, Downgrade Or Swap Out Your Yearly Or Monthly Zoom Tan Membership Here',
        slug: 'change-membership',
      },
    ]);
    this.membershipChanges$ = this.profileStore$.pipe(
      select(fromProfile.getMembershipChanges)
    );
    this.client$ = this.authStore$.select(
      fromAuth.getClient
    );
    this.isBadClientBilling$ = this.authStore$.select(
      fromAuth.getIsBadClientBilling
    );
    this.membershipChangeInfo$ = this.profileStore$.pipe(
      select(fromProfile.getAllChangeMembershipInfo)
    );

    this.filteredMembershipChanges$ = combineLatest([
      this.membershipChanges$,
      this.showMonthlyMemberships$,
    ])
      .pipe(
        map(([change, showMonthlyMemberships]) =>
          change?.filter(
            (membershipChange) =>
              (membershipChange.newMembership.mode === 'EFT') ===
              showMonthlyMemberships
          )
        )
      )
      .pipe(
        map((data) => {
          const sortByPrice = (a: ChangeMembershipTo, b: ChangeMembershipTo) =>
            +a?.newMembership?.price - +b?.newMembership?.price;

          const zoomfit = data?.filter(
            (membership) => membership?.newMembership?.isZoomFit
          );
          const zoomTan = data?.filter(
            (membership) => !membership?.newMembership?.isZoomFit
          );

          const sortedZoomTan = zoomTan?.sort((a, b) => sortByPrice(a, b));
          const sortedZoomFit = zoomfit?.sort((a, b) => sortByPrice(a, b));

          return sortedZoomTan?.concat(sortedZoomFit);
        })
      );

    // set default selection
    this.defaultSelectionSub = combineLatest(
      this.selectedItemService.getSelectedItemObservable(),
      this.membershipChanges$,
    ).pipe(
      map(([selectedItem, changes]) => changes?.find((change) =>
        change.newMembership.id === selectedItem?.membershipId
      )),
      mergeMap((defaultChange) => {
        if (defaultChange) {
          const { start, end, promo, previousPromoRestrictions, clientRestrictions } = defaultChange.newMembership.sale;
          return combineLatest(
            PromoComponent.checkOfferAvailable(start, end),
            this.promosService.checkClientPromoStatus(promo, previousPromoRestrictions, clientRestrictions),
          )
          .pipe(
            map(([available, promoUsed]) => available && !promoUsed && defaultChange)
          )
        } else {
          return of(undefined);
        }
      }),
    )
    .subscribe((defaultChange) => {
      if (defaultChange) {
        this.changeMembershipType(defaultChange.newMembership.mode === MembershipMode.Eft);
        this.isContinueButtonEnabled = true;
        this.newMembershipChange$.next(defaultChange);
      }
    });

    this.currentMembership$ = this.authStore$
      .select(fromAuth.getActiveClientMembership)
      .pipe(map((cm) => cm?.membership));

    this.loading$ = combineLatest([
      this.authStore$.select(fromAuth.getLoginLoading),
      this.profileStore$.select(fromProfile.getChangeMembershipLoading),
      this.profileStore$.select(fromProfile.getChangeMembershipInfoLoading),
    ]).pipe(map(([clientLoading, change, info]) => clientLoading || change || info));

    this.changeTitle = this.title;

    this.currentClientMembership$ = this.authStore$.select(
      fromAuth.getActiveClientMembership
    );

    this.clientSub = this.authStore$
      .select(fromAuth.getClient)
      .pipe(
        tap(_ => {
          this.agreed = false;
        }),
      )
      .subscribe();
  }

  getConfirmChangeInformation() {
    return combineLatest([
      this.isBadClientBilling$,
      this.currentMembership$,
      this.client$,
      this.currentClientMembership$,
      this.newMembershipChange$,
    ]).pipe(
      map(
        ([
          isBadBilling,
          currentMembership,
          client,
          clientMembership,
          newMembership,
        ]) => {
          const selectedChangeMembershipInfo = newMembership?.changeMembershipInfo?.find(
            (memInfo) => memInfo.now === this.isStartNow
          );

          const isMembershipYearly =
            newMembership?.newMembership?.mode === 'YEARLY';

          const hasYearlyMembershipOnHold =
            isMembershipYearly &&
            clientMembership.dates?.end &&
            client.clientMemberships?.memberships?.length > 1;
          const yearlyMembershipHoldText = hasYearlyMembershipOnHold
            ? '\n\nYour on-hold membership will be unaffected by this change.'
            : '';

          const immediateChangeText = isMembershipYearly
            ? `Your current membership & privileges will be forfeited immediately and replaced with the new membership selected. ${yearlyMembershipHoldText}`
            : 'Membership change becomes effective today';
          const detailText = this.isStartNow
            ? immediateChangeText
            : 'Membership change starts after current membership expires';

          return {
            showPrice: newMembership?.newMembership?.mode === 'EFT',
            isMembershipYearly,
            price: newMembership?.newMembership?.price,
            subtotal: selectedChangeMembershipInfo?.preTaxChargeAmount,
            tax:
              selectedChangeMembershipInfo?.chargeAmount -
              selectedChangeMembershipInfo?.preTaxChargeAmount,
            total: selectedChangeMembershipInfo?.chargeAmount,
            creditCardLastFour: client?.billing?.cardInfo?.lastFourCardNumber,
            nextPayment: clientMembership?.dates?.renewal,
            nextPaymentAmount:
              (newMembership?.newMembership as MembershipEft)?.recurringFee ??
              0,
            isBadClientBilling: isBadBilling,
            shortDescription: newMembership?.newMembership?.desc?.shortDescrip,
            details: detailText,
            note:
              currentMembership?.mode === 'EFT'
                ? 'Memberships can only be changed once per billing cycle'
                : 'You can only change your yearly membership once',
          };
        }
      )
    );
  }

  confirmAgreed($event) {
    if ($event) {
      this.agreed = true;
    } else {
      this.agreed = false;
    }
  }

  paymentCardEvent($event): void {
    this.setClientBilling($event);
    this.agreed = false;
  }

  setClientBilling(billing?): void {
    this.clientBilling$.next(billing ?? defaultBilling);
  }

  newCardEvent($event): void {
    this.agreed = false;
    this.newCard$.next($event);
    this.setClientBilling();
  }

  confirmChange(): void {
    this.clientBilling$.pipe(take(1)).subscribe((billing) =>
      this.profileStore$.dispatch(
        ChangeMembershipActions.changeMembershipRequest({
          toMembershipId: +this.newMembershipChange$?.value?.newMembership?.id,
          billing,
          startNow: this.isStartNow,
          useOnFileBilling: this.useOnFileBilling,
          promo: this.newMembershipChange$?.value?.newMembership?.sale?.promo,
        })
      )
    );
  }


  continue(): void {
    if (this.newMembershipChange$?.value?.newMembership?.mode === 'YEARLY') {
      this.setSelectedItem();
      this.isStartNow = true;
      this.showConfirmChange$.next(true);
      this.showStartChoices = false;
      this.showChoices = false;
      this.confirmChangeInformation$ = this.getConfirmChangeInformation();
      this.showTos$ = this.getShowTos();
    } else {
      if (this.showStartChoices) {
        this.showStartChoices = false;
        this.confirmChangeInformation$ = this.getConfirmChangeInformation();
        this.showConfirmChange$.next(true);
        this.showTos$ = this.getShowTos();
      } else {
        this.setSelectedItem();
        this.startChangeData$ = this.getStartChangeData();
        this.showChoices = false;
        this.showStartChoices = true;
      }
      this.changeTitle = this.title;
    }
    this.isContinueButtonEnabled = false;
  }

  setSelectedItem() {
    this.itemSelected$.next({
      title: this.newMembershipChange$?.value?.newMembership.name,
      categoryInfo: this.newMembershipChange$?.value?.newMembership.desc
        .shortDescrip,
      membershipId: this.newMembershipChange$?.value?.newMembership.id,
      image: null,
      cost: this.newMembershipChange$?.value?.newMembership.price,
      taxedCost: this.newMembershipChange$?.value?.newMembership.taxedPrice,
      eft: this.newMembershipChange$?.value?.newMembership.mode === 'EFT',
    });
  }

  getShowTos(): Observable<boolean> {
    return combineLatest([
      this.showConfirmChange$,
      this.clientBilling$,
      this.newMembershipChange$,
      this.isBadClientBilling$,
      this.newCard$,
    ]).pipe(
      map(([showConfirm, billing, newMembership, isBadBilling, newCard]) => {
        let selectedChangeMembershipInfo;
        if (newMembership) {
          selectedChangeMembershipInfo = newMembership?.changeMembershipInfo?.find(
            (memInfo) => memInfo.now === this.isStartNow
          );
        }
        return (
          !!showConfirm &&
          (billing?.cardNumber?.length > 0 ||
            (!isBadBilling && !newCard) ||
            (newMembership?.newMembership?.mode === 'YEARLY' &&
              selectedChangeMembershipInfo?.chargeAmount === 0))
        );
      })
    );
  }

  changeMembershipStartDate($event): void {
    this.isStartNow = $event;
    this.isContinueButtonEnabled = true;
  }

  changeMembershipType(value): void {
    this.showMonthlyMemberships$.next(value);
  }

  back(): void {
    if (!this.newMembershipChange$.value) {
      this.router.navigate(['/my-account']);
    } else {
      if (this.showConfirmChange$?.value) {
        if (this.newMembershipChange$.value.newMembership.mode === 'YEARLY') {
          this.newMembershipChange$.next(null);
          this.showChoices = true;
          this.showStartChoices = false;
        } else {
          this.showChoices = false;
          this.showStartChoices = true;
        }
        this.agreed = false;
        this.setClientBilling();
        this.showConfirmChange$.next(false);
      } else {
        this.newMembershipChange$.next(null);
        this.agreed = false;
        this.showChoices = true;
        this.showStartChoices = false;
      }
      if (this.newCard$.value) {
        this.newCard$.next(false);
      }

      this.changeTitle = this.title;
      this.isContinueButtonEnabled = false;
    }
  }

  getStartChangeData(): Observable<StartChange> {
    return this.authStore$
      .pipe(
        select(fromAuth.getActiveClientMembership),
        withLatestFrom(this.newMembershipChange$)
      )
      .pipe(
        switchMap(([clientMembership, newMembership]) => {
          return of({
            startNowPrice: +newMembership?.changeMembershipInfo?.find(
              (change) => change.now
            )?.chargeAmount,
            startLaterPrice: +newMembership?.changeMembershipInfo?.find(
              (change) => !change.now
            )?.chargeAmount,
            renewalDate: moment(clientMembership?.dates?.renewal).format(
              'YYYY-MM-DD'
            ),
            expireDate: moment(clientMembership?.dates?.renewal)
              .subtract(1, 'day')
              .format('YYYY-MM-DD'),
            currentMembershipName: clientMembership.membership.name,
            newMembershipName: newMembership?.newMembership.name,
            newMembershipMode: newMembership?.newMembership.mode,
            yearlyMembership: !!clientMembership.yearly,
          });
        })
      );
  }

  membershipClicked($event: ChangeMembershipTo): void {
    this.newMembershipChange$.next($event);
    this.isContinueButtonEnabled = true;
  }
}

const defaultBilling = {
  billingName: '',
  save: false,
  cardNumber: '',
  cardCVV: '',
  cardType: 'VI',
  expireMonth: '01',
  expireYear: '99',
};
