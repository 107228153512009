import { Component, OnInit, Input } from '@angular/core';
import { SelectedItem } from '../../models';

@Component({
  selector: 'hyp2-total-cost',
  templateUrl: './total-cost.component.html',
  styleUrls: ['./total-cost.component.scss']
})
export class TotalCostComponent implements OnInit {
  @Input() selectedItem: SelectedItem;

  constructor() { }

  ngOnInit(): void {
    
  }

}
