import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CheckoutRoutingModule } from './checkout-routing.module';
import { MaterialModule } from '../shared/material';
import { SharedModule } from '../shared/shared.module';

import * as fromCheckout from './reducers';
import { CheckoutPurchaseEffects } from './effects';
import {
  CardFormComponent,
  RegisterFormComponent,
  TermsAndConditionsComponent,
} from '../shared/components';
import { CheckoutComponent, CheckoutSuccessfulComponent } from './containers';
import {
  SelectedItemsComponent,
  TotalCostComponent,
  MemberInfoCardComponent,
  PaymentInfoCardComponent,
} from './components';

export const COMPONENTS = [
  CheckoutComponent,
  MemberInfoCardComponent,
  SelectedItemsComponent,
  TotalCostComponent,
  PaymentInfoCardComponent,
  CheckoutSuccessfulComponent,
];
export const ENTRYCOMPONENTS = [
  CardFormComponent,
  RegisterFormComponent,
  TermsAndConditionsComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  entryComponents: [ENTRYCOMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    CheckoutRoutingModule,
    StoreModule.forFeature(
      fromCheckout.checkoutFeatureKey,
      fromCheckout.reducers
    ),
    EffectsModule.forFeature([CheckoutPurchaseEffects]),
  ],
})
export class CheckoutModule {}
