<div class="container">
  <h2 class="container__title" data-cy="payment-info__title">Payment Method</h2>
  <div
    class="container__billing"
    *ngIf="
      client?.billing &&
      !client?.billing.badCard &&
      !client?.billing?.deadCard &&
      !client?.billing?.expiredCard &&
      !newCard
    "
  >
    <div class="container__billing-info">
      <span class="container__info-title" data-cy="payment-info__client-name"
        >Hello, {{ client?.name?.first }}!</span
      >
      <br />
      <span
        class="container__info-message"
        data-cy="payment-info__client-msg"
        >{{ cardPresentMessage }}</span
      >
    </div>
    <mat-card class="card mat-elevation-z6">
      <mat-icon class="card__icon">credit_card</mat-icon>
      <p class="card__card-info" data-cy="payment-info__client-card">
        xxxx-xxxx-xxxx-{{ client?.billing.cardInfo.lastFourCardNumber }}
      </p>
      <button
        (click)="setNewCard(true)"
        class="card__change"
        data-cy="payment-info__change-button"
      >
        change
      </button>
    </mat-card>
  </div>
  <div
    class="container__no-billing"
    *ngIf="
      !client?.billing ||
      client?.billing?.badCard ||
      client?.billing?.deadCard ||
      client?.billing?.expiredCard ||
      newCard
    "
  >
    <hyp2-card-form
      [cancelable]="
        client?.billing &&
        !client?.billing?.badCard &&
        !client?.billing?.deadCard &&
        !client?.billing?.expiredCard
      "
      (billing)="billing.emit($event)"
      (cancelButtonEvent)="this.setNewCard(false)"
      data-cy="payment-info__payment-form"
    ></hyp2-card-form>
  </div>
</div>
