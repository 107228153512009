import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { tap } from 'rxjs/operators';

export const MEMBERSHIP_KEY = makeStateKey('memberships');
export const PROMO_MEMBERSHIP_KEY = makeStateKey('promoMemberships');

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private apollo: Apollo, private state: TransferState) {}

  getPortalMemberships(count: number, promo?: string): Observable<any> {
    const memberships = this.state.get(MEMBERSHIP_KEY, null as any);
    if (memberships) {
      return of(memberships);
    }

    return this.apollo
      .query({
        query: allPortalMemberships,
        variables: { count, promo },
      })
      .pipe(tap((res) => this.state.set(MEMBERSHIP_KEY, res as any)));
  }

  getPortalPromoMemberships(count: number, promo?: string): Observable<any> {
    const memberships = this.state.get(PROMO_MEMBERSHIP_KEY, null as any);
    if (memberships) {
      return of(memberships);
    }

    return this.apollo
      .query({
        query: allPortalMemberships,
        variables: { count, promo },
      })
      .pipe(tap((res) => this.state.set(PROMO_MEMBERSHIP_KEY, res as any)));
  }

  getPortalPackages(count: number): Observable<any> {
    return this.apollo.query({
      query: allPortalPackages,
      variables: { count },
    });
  }
}

const allPortalMemberships = gql`
  query getAllPortalMemberships($count: Int, $promo: String) {
    getAllPortalMemberships(take: $count, skip: 0, promo: $promo) {
      edges {
        id
        mode
        type
        parentTitle
        expirationDays
        name
        price
        taxedPrice
        isZoomFit
        desc {
          shortDescrip
          longDescrip
        }
        image
        sale {
          active
          salePrice
          taxedSalePrice
          saved
          extraDays
          start
          end
          previousPromoRestrictions
          clientRestrictions
          promo
          promoId
        }
        portal
        children {
          id
          mode
          type
          parentTitle
          expirationDays
          name
          price
          taxedPrice
          desc {
            shortDescrip
            longDescrip
          }
          image
          sale {
            active
            salePrice
            taxedSalePrice
            saved
            extraDays
            start
            end
            previousPromoRestrictions
            clientRestrictions
            promo
            promoId
          }
          portal
          children {
            id
            mode
            type
            parentTitle
            expirationDays
            name
            price
            taxedPrice
            desc {
              shortDescrip
              longDescrip
            }
            image
            sale {
              active
              salePrice
              taxedSalePrice
              saved
              extraDays
              start
              end
              previousPromoRestrictions
              clientRestrictions
              promo
              promoId
            }
            portal
          }
        }
      }
    }
  }
`;

const allPortalPackages = gql`
  query allPortalPackages($count: Int) {
    getPackages(take: $count, skip: 0) {
      edges {
        id
        type
        name
        price
        pricePlusTax
        sale {
          active
          price
          startDate
          endDate
          saved
        }
        desc {
          short
          long
        }
      }
    }
  }
`;
