import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material';
import { NgxMaskModule, IConfig } from 'ngx-mask'; // Masks phone number input
import {
  AccountInfoDialogComponent,
  MatCardProfileComponent,
  DetailsDialogComponent,
  CardFormComponent,
  RegisterClientComponent,
  RegisterFormComponent,
  TermsAndConditionsDialogComponent,
  ResponseDialogComponent,
  VerifyCellDialogComponent,
  ReceiptDialogComponent,
  TermsAndConditionsComponent,
  ChangeExistingMemDialogComponent,
  OverlaySpinnerComponent,
  DialogComponent,
} from './components';

export const COMPONENTS = [
  AccountInfoDialogComponent,
  MatCardProfileComponent,
  DetailsDialogComponent,
  CardFormComponent,
  RegisterClientComponent,
  RegisterFormComponent,
  TermsAndConditionsDialogComponent,
  ResponseDialogComponent,
  VerifyCellDialogComponent,
  ReceiptDialogComponent,
  TermsAndConditionsComponent,
  ChangeExistingMemDialogComponent,
  OverlaySpinnerComponent,
  DialogComponent,
];

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMaskModule.forRoot(maskConfigFunction),
  ],
  exports: [NgxMaskModule, COMPONENTS],
})
export class SharedModule {}
