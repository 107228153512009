<mat-dialog-content class="dialog">
  <div class="dialog__text">
    <pre *ngIf="!skipPretag" [innerHtml]="data"></pre>
    <span *ngIf="skipPretag" [innerHtml]="data"></span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button (click)="closeDialog()" mat-raised-button class="dialog__button">
    Close
  </button>
</mat-dialog-actions>
