<div class="container">
  <div class="list">
    <mat-list>
      <mat-list-item>
        Subtotal
      </mat-list-item>
      <mat-list-item class="list__data" data-cy="total-cost__subtotal">
        {{ selectedItem?.cost | currency }}
      </mat-list-item>
      <br />
      <mat-list-item>
        Tax
      </mat-list-item>
      <mat-list-item class="list__data" data-cy="total-cost__tax">
        {{ selectedItem?.taxedCost - selectedItem?.cost | currency }}
      </mat-list-item>
      <br />
      <mat-list-item>
        <strong>Total</strong>
      </mat-list-item>
      <mat-list-item class="list__data">
        <strong data-cy="total-cost__total">{{
          selectedItem?.taxedCost | currency
        }}</strong>
      </mat-list-item>
      <br />
    </mat-list>
  </div>
</div>
