import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { map, withLatestFrom, tap } from 'rxjs/operators';
import { Observable, combineLatest, Subscription, BehaviorSubject, zip } from 'rxjs';
import moment from 'moment';

import { SelectedItemService } from '../../services';
import { ChangeExistingMemDialogService } from '../../../shared/services/change-exisiting-mem-dialog.service';
import { InitTokenService } from '../../../auth/services';

import * as fromAuth from '../../../auth/reducers';
import * as fromCheckout from '../../reducers';
import { RegisterClientActions } from '../../../auth/actions';
import { CheckoutPurchaseActions } from '../../actions';
import { SelectedItem } from '../../models';
import { VerifyCellDialogService } from '../../../shared/services';
import { BillingInput, Client, ClientMembership } from '@hyp2/graphql';
import { PromosService } from '../../../promo/promos.service';
import { ActivatedRoute } from '@angular/router';
import { PromoComponent } from '../../../promo/main/promo.component';
import { selectUnlessLoading } from '../../../shared/utils';

@Component({
  selector: 'hyp2-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  public agreed = false;

  clientBilling$ = new BehaviorSubject<BillingInput>(null);
  newCard$ = new BehaviorSubject<boolean>(false);
  showTos$: Observable<boolean>;

  activeSub: Subscription;
  clientSub: Subscription;
  loading$: Observable<Boolean>;

  dialogOpen: boolean;

  public useOnFileBilling = true; // default true so that if they add billing it'll set to false
  public item: SelectedItem = this.selectedItemService.getSelectedItem();
  public client$: Observable<Client> = this.authStore.select(
    fromAuth.getClient
  );
  isBadClientBilling$: Observable<boolean> = this.authStore.select(
    fromAuth.getIsBadClientBilling
  );
  public error$: Observable<string> = this.checkoutStore.select(
    fromCheckout.getCheckoutErrors
  );
  constructor(
    private authStore: Store<fromAuth.State>,
    private checkoutStore: Store<fromCheckout.State>,
    private selectedItemService: SelectedItemService,
    private CEMDService: ChangeExistingMemDialogService,
    private verifyCellService: VerifyCellDialogService,
    private promosService: PromosService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const { promo, start, end, previousPromoRestrictions, clientRestrictions } = this.item.sale;

    this.activeSub = zip(
      this.promosService.checkClientPromoStatus(promo, previousPromoRestrictions, clientRestrictions),
      this.checkActiveMembership(),
    )
    .pipe(
      withLatestFrom(PromoComponent.checkOfferAvailable(start, end)),
    )
    .subscribe(([[promoUsedData, {activeCM, lastCM, item}], available]) => {
      if ((!available || promoUsedData || activeCM) && !this.dialogOpen) {
        if (!available) {
          this.promosService.openPromoUnavailableDialog();
        } else if (activeCM) {
          lastCM = lastCM || activeCM;
          this.openActiveMembershipDialog(activeCM, lastCM, item);
        } else if (promoUsedData) {
          this.promosService.openPromoUsedDialog(promoUsedData);
        }
        this.dialogOpen = true;
      }
    });

    this.loading$ = combineLatest([
      this.checkoutStore.select(fromCheckout.getCheckoutPurchaseLoading),
      this.authStore.select(fromAuth.getLoginLoading),
      this.authStore.select(fromAuth.getRegisterLoading),
    ]).pipe(
      map(([purchase, login, register]) => purchase || login || register)
    );

    this.clientSub = this.authStore
    .select(fromAuth.getClient)
    .pipe(
      tap((_) => {
        this.agreed = false;
      })
    )
    .subscribe();

    this.showTos$ = this.getShowTos();
  }

  ngOnDestroy(): void {
    this.activeSub?.unsubscribe();
    this.clientSub?.unsubscribe();
  }

  checkActiveMembership() {
    return this.authStore.pipe(
      selectUnlessLoading(fromAuth.getFilteredClientMemberships, fromAuth.getLoginLoading),
      withLatestFrom(this.selectedItemService.getSelectedItemObservable()),
      map(([memberships, item]) => ({
        activeCM: memberships?.find((mem) => mem?.status?.active),
        lastCM: memberships?.filter((mem) => !mem?.status?.active).reverse()[0],
        item,
      })),
    );
  }

  openActiveMembershipDialog(activeCM: ClientMembership, lastCM: ClientMembership, item: SelectedItem) {
    const clientEft = activeCM?.membership.mode === 'EFT';
    const endDate = clientEft
      ? null
      : moment(lastCM?.dates?.end).format('MM-DD-YYYY');

    const existingMembership = {
      year: endDate,
      cancelled: activeCM?.status?.canceled,
      current: activeCM?.membership?.id === item.membershipId,
      itemEft: this.item?.eft,
      clientEft,
      promo: this.item?.sale?.promo,
    };

    this.CEMDService.openDialog(existingMembership);
  }

  registerClicked($event: any) {
    const formattedDate = moment($event.clientData.dob).format('YYYY-MM-DD');
    this.authStore.dispatch(
      RegisterClientActions.registerClientRequest({
        name: {
          first: $event.clientData.firstName,
          last: $event.clientData.lastName,
        },
        dob: formattedDate,
        license: {
          number: $event.clientData.license,
          passport: false,
          state: null,
          documentId: null,
        },
        cell: $event.clientData.phoneNumber,
      })
    );
  }

  checkout() {
    this.authStore.dispatch(
      CheckoutPurchaseActions.checkoutPurchaseRequest({
        membershipId: this.item.membershipId,
        clientBilling: this.clientBilling$.value,
        useOnFileBilling: this.useOnFileBilling,
        promo: this.item.sale?.promo,
      })
    );
  }

  paymentCardEvent($event): void {
    this.setClientBilling($event);
    this.agreed = false;
    this.useOnFileBilling = !$event;
  }

  setClientBilling(billing?): void {
    this.clientBilling$.next(billing);
  }

  newCardEvent($event): void {
    this.agreed = false;
    this.newCard$.next($event);
    this.setClientBilling();
    this.useOnFileBilling = !$event;
  }

  getShowTos(): Observable<boolean> {
    return combineLatest([
      this.clientBilling$,
      this.isBadClientBilling$,
      this.newCard$,
    ]).pipe(
      map(([billing, isBadBilling, newCard]) => {
        return billing?.cardNumber?.length > 0 || (!isBadBilling && !newCard);
      })
    );
  }

  confirmAgreed($event) {
    if ($event) {
      this.agreed = true;
    } else {
      this.agreed = false;
    }
  }

  openVerify() {
    this.verifyCellService.openResendVerifyCell();
  }
}
