<h1
  class="container__item container__title container__shopitems"
  data-cy="home-page-title-header"
>
  {{ title }}
</h1>
<h1
  *ngIf="locationText"
  class="container__item container__zoomfit-locations"
  [class.container__zoomfit-locations-warning]="locationWarning"
>
  {{ locationText }}
</h1>
<div
  *ngIf="memberships"
  class="container__item container__shopitems container__shopitems_{{layoutType}}"
>
<ng-container *ngIf="zoomTan">
  <hyp2-zoomtan-membership-card
    *ngFor="let membership of memberships"
    [membership]="membership"
    (buttonEvent)="buttonEvent.emit($event)"
    [attr.data-cy]="data_cy"
  ></hyp2-zoomtan-membership-card>
</ng-container>
<ng-container *ngIf="zoomFit">
  <hyp2-zoomfit-membership-card
    *ngFor="let membership of memberships"
    [membership]="membership"
    (buttonEvent)="buttonEvent.emit($event)"
    [attr.data-cy]="data_cy"
  ></hyp2-zoomfit-membership-card>
</ng-container>
</div>
