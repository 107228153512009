import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromProfile from './profile.reducer';
import * as fromUpdateBilling from './update-billing.reducer';

export const profileFeatureKey = 'profile';

export interface ProfileState {
  [fromProfile.profileDataFeatureKey]: fromProfile.State;
  [fromUpdateBilling.updateBillingFeatureKey]: fromUpdateBilling.State;

}

export interface State extends fromRoot.State {
  [profileFeatureKey]: ProfileState;
}

export function reducers(state: ProfileState | undefined, action: Action) {
  return combineReducers({
    [fromProfile.profileDataFeatureKey]: fromProfile.reducer,
    [fromUpdateBilling.updateBillingFeatureKey]: fromUpdateBilling.reducer,

  })(state, action);
}

export const selectProfileState = createFeatureSelector<ProfileState>(
  profileFeatureKey
);

// Profile Selectors
export const selectProfileDataState = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profileData
)
export const getError = createSelector(selectProfileDataState, fromProfile.getError);

// Update Billing Selectors
export const selectUpdateBillingState = createSelector(
  selectProfileState,
  (state: ProfileState) => state.updateBilling
);
export const getUpdateBillingLoading = createSelector(
  selectUpdateBillingState,
  fromUpdateBilling.getLoading
);
