import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material';

import * as fromCancelMembership from './reducers';
import { CancelMembershipEffects } from './effects';
import { MatCardProfileComponent } from '../shared/components';
import { CancelMembershipComponent } from './containers';
import { CancelComponent, CustomerServiceInfoComponent } from './components';
import { CancelMembershipRoutingModule } from './cancel-membership-routing.module';

export const COMPONENTS = [
  CancelMembershipComponent,
  CancelComponent,
  CustomerServiceInfoComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  entryComponents: [MatCardProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MaterialModule,
    CancelMembershipRoutingModule,
    StoreModule.forFeature(
      fromCancelMembership.cancelMembershipFeatureKey,
      fromCancelMembership.reducers
    ),
    EffectsModule.forFeature([
      CancelMembershipEffects,
    ]),
  ],
  providers: [CurrencyPipe, DatePipe],
})
export class CancelMembershipModule {}
