<div class="dialog">
  <h1 class="dialog__title">{{ data.title }}</h1>
  <p class="dialog__description">{{ data.description }}</p>
  <br />
  <mat-form-field appearance="outline">
    <mat-label>Pin</mat-label>
    <input
      [(ngModel)]="pin"
      matInput
      placeholder="6-digit pin"
      id="single-factor-code-text-field"
      autocomplete="one-time-code"
      mask="000000"
      [formControl]="pinField"
    />
    <mat-icon matSuffix>phonelink_lock</mat-icon>
    <mat-error *ngIf="pinField.hasError('minlength')">
      Pin is to short
    </mat-error>
  </mat-form-field>

  <a class="dialog__resend" (click)="textResend()">Click here to Resend Code</a>

  <mat-dialog-actions class="dialog__buttons">
    <button
      [mat-dialog-close]=""
      class="dialog__button"
      mat-raised-button
      data-cy="verify-cell__later"
    >
      Maybe later
    </button>
    <button
      disabled
      [mat-dialog-close]="pin"
      [disabled]="!pinField.valid"
      class="dialog__button"
      mat-raised-button
    >
      Confirm
    </button>
  </mat-dialog-actions>

  <h1 class="dialog__disclaimer">
    Please note that Zoom Tan communicates all important messages via text
    messages only. If you do not verify your phone number now you you may not
    receive all important membership info and purchase receipts. Please contact
    customer service at 239-465-4285 for additional information.
  </h1>
</div>
