<button
  *ngIf="!showTools"
  mat-mini-fab
  matTooltip="Admin Creation Buttons"
  color="primary"
  (click)="toggleShowButtons()"
>
  <mat-icon>build</mat-icon>
</button>
<mat-card class="buttons" *ngIf="showTools">
  <div *ngIf="client$ | async as client">
    <h2>Client</h2>
    <p>Client ID: {{ client?.id }}</p>
    <p>Client First Name: {{ client?.name?.first }}</p>
    <p>Client Last Name: {{ client?.name?.last }}</p>
    <p>Client DL: {{ client?.license?.number }}</p>
    <p>Client Phone #: {{ client?.contact?.cell?.number }}</p>
    <p>Active CM ID: {{ client?.clientMemberships?.activeMembership?.id }}</p>
  </div>

  <button
    class="admin-button"
    *ngIf="canCreateRandomAccount$ | async"
    (click)="createRandomAccount()"
    mat-raised-button
  >
    Create random account
  </button>
  <button
    class="admin-button"
    *ngIf="!(jwt$ | async)"
    mat-raised-button
    (click)="getInitToken()"
  >
    Get Initial Jwt
  </button>
  <button
    class="admin-button"
    *ngIf="canBuyMembership$ | async"
    mat-raised-button
    (click)="buyMembership()"
  >
    Buy membership
  </button>
  <button
    class="admin-button"
    color="warn"
    (click)="toggleShowButtons()"
    mat-raised-button
  >
    Close
  </button>
</mat-card>
