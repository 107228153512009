import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class FiveDollarFridayYearlyComponent extends PromoComponent {
  // id
  static path = '5fy';
  static promoCode = '5FY';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.YEARLY;

  // display
  imageFileDesktop = '/assets/images/promos/five-dollar-friday-desktop.jpg';
  imageFileMobile = '/assets/images/promos/five-dollar-friday-mobile.jpg';
  header = 'Get 3 EXTRA Months For Only $5 With This Special $5 Friday Offer!';
  text = 'We have an exclusive online offer just for you during today’s $5 Friday Sale!  You can get 3 extra months for only $5 when you purchase any of our yearly memberships today. Yearly members already save big over monthly members but with this deal you can save up to an extra of $132.50. Don\'t miss out!';
}
