import { Component, Input } from '@angular/core';
import { Membership } from '@hyp2/graphql';

@Component({
  selector: 'hyp2-no-change-available',
  templateUrl: './no-change-available.component.html',
  styleUrls: ['./no-change-available.component.scss'],
})
export class NoChangeAvailableComponent{
  @Input() currentMembership: Membership;
}
