import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromCheckoutPurchase from './checkout-purchase.reducer';

export const checkoutFeatureKey = 'checkout';

export interface CheckoutState {
  [fromCheckoutPurchase.checkoutPurchaseFeatureKey]: fromCheckoutPurchase.State;
}

export interface State extends fromRoot.State {
  [checkoutFeatureKey]: CheckoutState;
}

export function reducers(state: CheckoutState | undefined, action: Action) {
  return combineReducers({
    [fromCheckoutPurchase.checkoutPurchaseFeatureKey]:
      fromCheckoutPurchase.reducer,
  })(state, action);
}

export const selectCheckoutState = createFeatureSelector<CheckoutState>(
  checkoutFeatureKey
);

// Checkout purchase state
export const selectCheckoutPurchaseState = createSelector(
  selectCheckoutState,
  (state: CheckoutState) => state.checkoutPurchase
);
export const getCheckoutPurchaseLoading = createSelector(
  selectCheckoutPurchaseState,
  fromCheckoutPurchase.getLoading
);
export const getCheckoutErrors = createSelector(
  selectCheckoutPurchaseState,
  fromCheckoutPurchase.getError
);
export const getCheckoutSuccess = createSelector(
  selectCheckoutPurchaseState,
  fromCheckoutPurchase.getSuccess
);
