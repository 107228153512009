<div class="container">
  <div class="container__close">
    <button
      routerLink="/my-account"
      data-cy="cancel-membership__back-button"
      class="close"
      aria-label="Close"
    >
      <span class="container__close--button" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="!success">
    <h1 class="container__title" data-cy="cancel-membership__title">
      Cancel Membership
    </h1>
    <p class="container__description" data-cy="cancel-membership__subtitle">
      {{ form !== undefined ? cancelMsg : cancelErr }}
      <span *ngIf="!memExists">{{ cancelWarning }}</span>
    </p>

    <div *ngIf="form !== undefined">
      <mat-card class="card-confirmation mat-elevation-z2">
        <mat-card-content data-cy="cancel-membership__warning">
          {{ cancelConfirmationMessage }}
        </mat-card-content>
      </mat-card>
      <mat-card class="card-confirmation-formatted mat-elevation-z2">
        <mat-card-content data-cy="cancel-membership__warning">
          If you want to
          <a routerLink="/change-membership"
            ><span class="important-text"
              >change your membership, please click here.</span
            ></a
          >
          <span class="important-text">
            DO NOT CANCEL OTHERWISE YOU WON'T BE ABLE TO CHANGE YOUR
            MEMBERSHIP.</span
          >
        </mat-card-content>
      </mat-card>

      <mat-card class="card mat-elevation-z2">
        <mat-card-content
          class="card__form"
          [innerHtml]="form"
          data-cy="cancel-membership__form"
        >
          {{ form }}
        </mat-card-content>
      </mat-card>
    </div>
    <mat-card *ngIf="form === undefined" class="card mat-elevation-z2">
      <mat-card-content class="card__list">
        <span data-cy="cancel-membership__error">{{ error }}</span>
        <div *ngIf="isPendingBalance">
          <a routerLink="/pay-balance"
            ><span class="important-text">Pay balance now.</span></a
          >
        </div>
      </mat-card-content>
    </mat-card>
    <div>
      <button
        *ngIf="form !== undefined"
        class="container__button"
        mat-raised-button
        data-cy="cancel-membership__submit"
        (click)="buttonEvent.emit($event)"
      >
        Cancel Membership
      </button>
      <p class="important-text">Customer Service</p>
      <hyp2-customer-service-info> </hyp2-customer-service-info>
      <p class="important-text">(239) 465-4285</p>
    </div>
  </div>
  <div *ngIf="success">
    <h1
      class="container__title--success"
      data-cy="cancel-membership__success-title"
    >
      Successful
    </h1>
    <p class="container__description" data-cy="cancel-membership__success-desc">
      You have successfully cancelled your membership
    </p>
    <mat-card class="card mat-elevation-z2">
      <mat-card-content data-cy="cancel-membership__success-customer-service">
        You will be sent a text message shortly with cancellation confirmation.
        <span class="important-text"
          >If you do not receive confirmation it is very important that you
          contact customer service at
        </span>
        <span class="important-text__phone">(239) 465-4285</span>
        <hyp2-customer-service-info> </hyp2-customer-service-info>
      </mat-card-content>
    </mat-card>
    <button
      routerLink="/my-account"
      class="container__button--success"
      mat-raised-button
      data-cy="cancel-membership__success-button"
    >
      View Profile
    </button>
  </div>
</div>
