<div class="container">
  <h2 class="container__title" data-cy="selected-item__title">Order Summary</h2>
  <mat-card class="card mat-elevation-z3">
    <mat-card-content class="card__grid">
      <img
        class="card__img"
        src="{{ selectedItem?.image }}"
        alt="selected-item"
        data-cy="selected-item__image"
      />
      <div class="card__info">
        <span class="card__info--bold" data-cy="selected-item__name">{{
          selectedItem?.title
        }}</span>
        <br />
        <span class="card__info--small" data-cy="selected-item__info">{{
          selectedItem?.categoryInfo
        }}</span>
      </div>
      <span class="card__cost" data-cy="selected-item__cost">
        <span>{{ selectedItem?.cost | currency }}</span>
        <span *ngIf="promo" class="card__promo" data-cy="selected-item__promo">{{
        offerDetails
      }}</span>
      </span>
    </mat-card-content>
  </mat-card>
</div>
