  <mat-card
    class="card mat-elevation-z3"
  >
    <mat-radio-button
      color="primary"
      (click)="buttonEvent.emit(false)"
      data-cy="start-change__radio-button--later"
      [value]="false"
      [checked]="false"
    ></mat-radio-button>
    <div class="flex-spacer"></div>
    <div class="card__info">
      <span class="card__info--title">PLACE THE NEW MEMBERSHIP ON HOLD</span>
      <span class="card__info--top" data-cy="start-change__desc--later">
        If you select this option you will keep your
        {{ startChangeData?.currentMembershipName }} privileges until
        {{ startChangeData?.expireDate | date }} and will not be able to use
        your {{ startChangeData?.newMembershipName }} until
        {{ startChangeData?.renewalDate | date }}
      </span>
      <span class="card__info--bottom"
        >Price today:
        <span
          class="card__info--bottom-red"
          data-cy="start-change__cost--later"
          >{{ startChangeData?.startLaterPrice | currency }}</span
        ></span
      >
    </div>
  </mat-card>
  <mat-card class="card mat-elevation-z3">
    <mat-radio-button
      color="primary"
      (click)="buttonEvent.emit(true)"
      class="card__checkbox"
      data-cy="start-change__radio-button--now"
      [value]="false"
      [checked]="false"
    ></mat-radio-button>
    <div class="flex-spacer"></div>
    <div class="card__info">
      <span class="card__info--title"
        >START THE NEW MEMBERSHIP IMMEDIATELY</span
      >
      <span class="card__info--top" data-cy="start-change__desc--now">
        If you select this option you will forfeit the remainder of your
        {{ startChangeData?.currentMembershipName }} and its associated
        privileges and can begin using your
        {{ startChangeData?.newMembershipName }} privileges immediately.
      </span>
      <span class="card__info--bottom"
        >Price today:
        <span
          class="card__info--bottom-red"
          data-cy="start-change__cost--now"
          >{{ startChangeData?.startNowPrice | currency }}</span
        ></span
      >
    </div>
  </mat-card>
