import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NavLink } from '../../../shared/interfaces';
import * as fromProfile from '../../reducers';
import * as fromAuth from '../../../auth/reducers';

@Component({
  selector: 'hyp2-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  loading$: Observable<Boolean>;

  navLinks: NavLink[] = [
    {
      label: 'Account Information',
      link: './account',
      index: 0,
      disabled: false,
    },
    {
      label: 'Receipts',
      link: './receipts',
      index: 1,
      disabled: false,
      cy: 'receipts-link',
    },
  ];

  receipts$ = this.authStore.pipe(select(fromAuth.getClientReceipts));

  constructor(
    private metaService: Meta,
    private profileStore: Store<fromProfile.ProfileState>,
    private authStore: Store<fromAuth.AuthState>
  ) {}

  ngOnInit(): void {
    this.metaService.addTags([
      {
        name: 'description',
        content:
          'View Your Zoom Tan Account Information Here. Login With Your Cell Phone Number And Driver’s License Number To Manage Your Membership, Update Billing Info, View Receipts & More',
      },
    ]);

    this.loading$ = this.profileStore.pipe(
      select(fromProfile.getUpdateBillingLoading)
    );
  }
}
