import { createReducer, on } from '@ngrx/store';
import {
  VerifyClientActions,
  LogoutActions,
  RefreshClientActions,
} from '../actions';

import moment from 'moment';
import { isBadClientBilling } from '../../shared/utils';
import { Client } from '@hyp2/graphql';

export const clientFeatureKey = 'client';

export interface State {
  client: Client;
  isAuthenticated: boolean;
  jwt: string;
  error: string;
  loading: boolean;
}

export const initialState: State = {
  client: undefined,
  isAuthenticated: undefined,
  jwt: undefined,
  error: null,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(VerifyClientActions.verifyClientRequest, (state, { license }) => ({
    ...state,
    license,
    isAuthenticated: false,
    error: null,
    loading: true,
  })),
  on(
    VerifyClientActions.verifyClientSuccess,
    (state, { isAuthenticated, jwt, client }) => ({
      ...state,
      isAuthenticated,
      jwt,
      client,
      error: null,
      loading: false,
    })
  ),
  on(VerifyClientActions.verifyClientFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(RefreshClientActions.refreshClientRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(RefreshClientActions.refreshClientSuccess, (state, { client }) => ({
    ...state,
    client,
    loading: false,
  })),
  on(LogoutActions.logoutClient, (state) => initialState)
);

export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getClient = (state: State) => state.client;
export const getIsAuthenticated = (state: State) => state?.isAuthenticated;
export const getJwt = (state: State) => state?.jwt;
export const getClientBilling = (state: State) => state.client?.billing;
export const getClientForms = (state: State) => state.client?.forms;
export const getClientReceipts = (state: State) => state.client?.receipts;
export const getClientHistory = (state: State) => state.client?.tan.history;
export const getClientBalance = (state: State) => state.client?.balance;
export const getClientPackages = (state: State) => state.client?.packages;
export const getFilteredClientMemberships = (state: State) =>
  state.client?.clientMemberships?.memberships?.filter((membership) => {
    return membership?.status?.active || moment(membership?.dates?.start).isAfter(moment());
  });

export const getActiveClientMembership = (state: State) => {
  return state.client?.clientMemberships?.activeMembership;
};

export const getIsBadClientBilling = (state: State) => {
  return isBadClientBilling(state?.client?.billing);
};
