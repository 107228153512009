import { Component } from '@angular/core';
import { PromosService } from '../promos.service';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class Prime2Component extends PromoComponent {
  // id
  static path = 'zt0621prime2';
  static promoCode = 'PD';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.MONTHLY;

  // availability
  enabled = false;

  // display
  imageFileDesktop = '/assets/images/promos/prime-desktop.jpg';
  imageFileMobile = '/assets/images/promos/prime-mobile.jpg';
  header = 'Get one month of unlimited tanning starting at only $5 with these "PRIME" deals!';
  text = 'With this exclusive online-only special offer, you can try us out and save up to 83% on your first tanning month! We do not require any long-term commitments or contracts. Cancellation is FREE and can be completed online any time after just 10 days from the start date. Members who LOVE our service can continue after the first month at regular pricing or opt to cancel and use the remainder of the tanning month that they purchased.';

}
