<div class="container">
  <hyp2-overlay-spinner *ngIf="loading$ | async"></hyp2-overlay-spinner>
  <nav mat-tab-nav-bar mat-align-tabs="center">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
      [disabled]="link.index === 1 && !((receipts$ | async)?.length > 0)"
      [attr.data-cy]="link.cy"
    >
      {{ link.label }}
    </a>
  </nav>
  <div class="container__item">
    <router-outlet></router-outlet>
  </div>
</div>
