import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take, filter, tap } from 'rxjs/operators';

import * as fromAuth from '../reducers';
import { VerifyClientActions } from '../actions';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class InitTokenService {
  constructor(
    private authStore: Store<fromAuth.State>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  initializeToken() {
    return this.authStore
      .pipe(
        take(1),
        select(fromAuth.getIsAuthenticated),
        filter(
          (auth) => auth === undefined && isPlatformBrowser(this.platformId)
        ),
        tap(() => {
          this.authStore.dispatch(
            VerifyClientActions.verifyClientRequest({ returnUrl: null })
          );
        }),
      ).subscribe();
  }
}
