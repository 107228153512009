<div class="parent-container" *ngIf="client$ | async as client">
  <hyp2-overlay-spinner *ngIf="loading$ | async"></hyp2-overlay-spinner>
  <div class="container">
    <h1 class="title">{{ title }}</h1>
    <p class="description">{{ description }}</p>
    <mat-card class="card mat-elevation-z6">
      <div class="card__content">
        <span class="card__content-text"> Past amount due: </span>
        <span class="card__content-balance">
          {{ balance$ | async | currency }}
        </span>
      </div>
    </mat-card>
    <hyp2-card-form
      [optionalSave]="!!this.item?.membershipId"
      class="container__card-form"
      (billing)="paymentCardEvent($event)"
    >
    </hyp2-card-form>
    <!-- Terms & Conditions -->
    <hyp2-terms-and-conditions
      *ngIf="clientBilling$ | async as billing"
      [client]="client"
      [item]="item"
      [agreed]="agreed"
      [clientBilling]="billing"
      (buttonEvent)="confirmAgreed($event)"
      [showAgreement]="billing?.save && !!item.membershipId"
    ></hyp2-terms-and-conditions>
    <button
      mat-raised-button
      (click)="submit()"
      class="button"
      data-cy="pay-balance-submit-button"
      [disabled]="!this.agreed || (this.loading$ | async)"
    >
      Submit
    </button>
  </div>
</div>
