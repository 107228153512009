import { OnInit, OnDestroy } from '@angular/core';
import { PromosService } from '../promos.service';
import { environment } from '../../../environments/environment';
import { Membership } from '../../core/models';
import { map } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import moment from 'moment-timezone';

export enum Brands {
  ZOOMTAN,
  ZOOMFIT,
};

export enum BillingTypes {
  MONTHLY,
  YEARLY,
};

export interface PromoComponent {
  constructor: typeof PromoComponent;
}

export abstract class PromoComponent implements OnInit, OnDestroy {
  // id
  static path: string;
  static promoCode: string;
  promoCode = this.constructor.promoCode;

  // memberships
  abstract brand: Brands;
  abstract billingType: BillingTypes;
  get Brand() { return enumMap(Brands, this.brand) }
  get BillingType() { return enumMap(BillingTypes, this.billingType) }
  get memberships$() {
    return (
      this.Brand.ZOOMFIT ? this.promosService.zoomFitMemberships$ :
      this.BillingType.MONTHLY ? this.promosService.monthlyMemberships$ :
      this.BillingType.YEARLY ? this.promosService.yearlyMemberships$ :
      null
    );
  }

  // availability
  enabled = true;
  static offerAvailable(start: string, end: string, enabled=true) {
    const startTime = moment.tz(start, 'America/New_York').valueOf() || 0;
    const endTime = moment.tz(end, 'America/New_York').valueOf() || Infinity;
    const current = (Date.now() > startTime && Date.now() < endTime);
    const production = (environment.mode === 'production');
    return enabled && (current || !production);
  }
  static checkOfferAvailable(start: string, end: string, enabled=true) {
    return of(this.offerAvailable(start, end, enabled));
  }
  get availableMemberships$() {
    return this.memberships$.pipe(
      map((memberships) =>
        memberships?.filter((membership) => {
          const { start, end } = membership.sale;
          return this.constructor.offerAvailable(start, end, this.enabled);
        })
      )
    );
  }

  // display
  abstract imageFileDesktop: string;
  abstract imageFileMobile: string;
  abstract header: string;
  abstract text: string;
  static offerDetails(saved: number, extraDays: number) {
    const monthsOrDays =
      (extraDays < 30) ?
        (extraDays === 1) ? 'Day' : 'Days' :
        (extraDays >= 30 && extraDays < 60) ? 'Month' : 'Months';
    const monthOrDayCount = (extraDays < 30) ? extraDays : Math.floor(extraDays / 30);
    const extraCost = -saved;
    if (extraDays === 0 && extraCost < 0) {
      return `You save $${this.formatPrice(-extraCost)}`;
    } else if (extraDays > 0 && extraCost === 0) {
      return `${monthOrDayCount} Extra ${monthsOrDays} Free`;
    } else if (extraDays > 0 && extraCost > 0) {
      return `${monthOrDayCount} Extra ${monthsOrDays} for $${this.formatPrice(extraCost)}`;
    } else if (extraDays > 0 && extraCost < 0) {
      return `You save $${this.formatPrice(-extraCost)} and get ${monthOrDayCount} extra ${monthsOrDays}`;
    } else if (extraDays <= 0 && extraCost >= 0) {
      return '';
    }
  }
  offerDetails(membership: Membership) {
    const { saved, extraDays } = membership.sale;
    return this.constructor.offerDetails(saved, extraDays);
  }
  static formatPrice = (price: number) => Number.isInteger(price) ? price : price.toFixed(2);
  formatPrice = this.constructor.formatPrice;

  promoStatusSub: Subscription;
  constructor(public readonly promosService: PromosService, meta: Meta) {
    meta.addTag({ name: 'robots', content: 'noindex' });
  }

  ngOnInit() {
    this.promosService.fetchMemberships(this.promoCode);
    this.promoStatusSub =
      this.promosService.checkClientPromoStatusByMembership(this.promoCode, this.memberships$)
      .subscribe((promoUsedData) => {
        if (promoUsedData) this.promosService.openPromoUsedDialog(promoUsedData);
      });
  }

  ngOnDestroy() {
    this.promoStatusSub?.unsubscribe();
  }
}

function enumMap<E>(e: E, val) {
  return Object.entries(e).reduce((partial, [k, v]) => ({
    [k]: v === val,
    ...partial,
  }), {}) as { [Property in keyof E] };
}

