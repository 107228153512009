import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReceiptDialogComponent } from '../components';

@Injectable({
  providedIn: 'root',
})
export class ReceiptDialogService {
  dialogRef: MatDialogRef<ReceiptDialogComponent>;

  constructor(public dialog: MatDialog) {}

  public openDialog(dialogData: string) {
    this.dialogRef = this.dialog.open(ReceiptDialogComponent, {
      data: dialogData,
    });
  }
}
