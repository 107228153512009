import {
  ActionReducer,
  MetaReducer,
  Action,
  ActionReducerMap,
  createFeatureSelector,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
import * as Sentry from '@sentry/angular';
import { cloneDeep } from 'lodash';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import { InjectionToken } from '@angular/core';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  router: fromRouter.RouterReducerState<any>;
}

export const selectRouter = createFeatureSelector<
  State,
  fromRouter.RouterReducerState<any>
>('router');

export const {
  selectCurrentRoute, // select the current route
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouter);

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    router: fromRouter.routerReducer,
  }),
});

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

const packState = (state) => {
  return JSON.stringify(trimState(cloneDeep(state)));
};
// trim long/sensitive fields in the state before sending to Sentry
const sentinel = '<removed>';
const trimDesc = (item) => {
  if (item?.shortDescrip) {
    item.shortDescrip = sentinel;
  }
  if (item?.longDescrip) {
    item.longDescrip = sentinel;
  }
  return item;
};
const trimState = (state) => {
  if (!state) {
    return;
  }
  if (state?.core?.portalMemberships?.memberships) {
    for (const m of state.core?.portalMemberships?.memberships) {
      for (const child of m?.children) {
        child.desc = trimDesc(child.desc);
      }
      m.desc = trimDesc(m.desc);
    }
  }
  if (state?.auth?.client) {
    state.auth.client.jwt = sentinel;
  }
  if (state?.auth?.client?.client) {
    for (const r of state.auth.client?.client?.receipts) {
      if (r.agreement) {
        r.agreement = sentinel;
      }
    }
  }

  return state;
};

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = [
  (reducer: ActionReducer<State>) => {
    return (state, action) => {
      Sentry.setExtra('state', packState(state));
      return reducer(state, action);
    };
  },
];
if (!environment.production) {
  metaReducers.push(logger);
}
