  <!-- Error Card -->
  <div>
    <mat-card class="card mat-elevation-z3">
      <span class="card__error">{{
        currentMembership?.mode === 'EFT'
          ? 'Cannot change membership until next billing cycle.'
          : 'Yearly Membership are allowed 1 change.'
      }}</span>
    </mat-card>
  </div>
