import { Component, OnInit } from '@angular/core';

import * as fromAuth from '../../../auth/reducers'
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'hyp2-checkout-successful',
  templateUrl: './checkout-successful.component.html',
  styleUrls: ['./checkout-successful.component.scss']
})
export class CheckoutSuccessfulComponent implements OnInit {
  loading$: Observable<Boolean>;
  constructor(private authStore: Store<fromAuth.State>) { }

  ngOnInit(): void {
    this.loading$ = this.authStore.select(fromAuth.getLoginLoading)
  }

}
