<div class="wrapper">
  <!-- Spinner -->
  <hyp2-overlay-spinner *ngIf="loading$ | async"></hyp2-overlay-spinner>
  <div
    class="loaded-content"
    [class.blurred]="(loading$ | async)"
  >
    <div class="container">
      <div class="container__item">
        <h1 class="container__title" data-cy="checkout__title">Checkout</h1>
        <!-- Selected Item -->
        <hyp2-selected-items
          [selectedItem]="item"
          data-cy="checkout__selected-item"
        ></hyp2-selected-items>
        <!-- Register Form -->
        <hyp2-register-client
          *ngIf="!(client$ | async)"
          (buttonEvent)="registerClicked($event)"
          [loading]="loading$ | async"
          data-cy="checkout__register"
        ></hyp2-register-client>
        <div *ngIf="client$ | async">
          <!-- Client Info -->
          <hyp2-member-info-card
            [client]="client$ | async"
            (openVerify)="openVerify()"
            data-cy="checkout__membership-info"
          ></hyp2-member-info-card>
          <!-- Billing Info -->
          <hyp2-payment-info-card
            *ngIf="client$ | async as client"
            [client]="client"
            (billing)="paymentCardEvent($event)"
            (newCardEvent)="newCardEvent($event)"
            data-cy="checkout__payment-info"
          ></hyp2-payment-info-card>
        </div>
        <!-- TOS -->

        <div class="container__tos">
          <hyp2-terms-and-conditions
            *ngIf="(showTos$ | async) &&
                client$ | async as client
            "
            [client]="client"
            [item]="item"
            [agreed]="agreed"
            [clientBilling]="
              (clientBilling$ | async)?.save || !client?.billing ? (clientBilling$ | async) : null
            "
            [showAgreement]="
              (clientBilling$ | async)?.save || item?.eft || !!client?.billing
            "
            (buttonEvent)="confirmAgreed($event)"
            data-cy="checkout__tos"
          ></hyp2-terms-and-conditions>
        </div>
        <!-- Total Cost -->
        <hyp2-total-cost
          [selectedItem]="item"
          data-cy="checkout__total-cost"
        ></hyp2-total-cost>
        <div class="container__button-grid">
          <button
            mat-raised-button
            (click)="checkout()"
            class="container__button"
            [disabled]="!this.agreed || (loading$ | async)"
            data-cy="checkout__confirm-button"
          >
            Confirm Purchase
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
