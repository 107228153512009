<!-- Change-Membership choices -->
  <mat-radio-group (change)="membershipSelected.emit($event.value)">
    <hyp2-change
      *ngFor="let membership of membershipChanges"
      [membership]="membership"
      [isDefault]="isDefault(membership)"
      data-cy="change-membership__memberships"
    ></hyp2-change>
  </mat-radio-group>
  <mat-card
  *ngIf="showMonthlyMemberships && membershipChanges?.length === 0"
    class="card mat-elevation-z3"
  >
    <span class="card__error" data-cy="change-membership__no-monthly-msg"
      >No monthly memberships available</span
    >
  </mat-card>
  <mat-card
    *ngIf="!showMonthlyMemberships && membershipChanges?.length === 0"
    class="card mat-elevation-z3"
  >
    <span class="card__error" data-cy="change-membership__no-yearly-msg"
      >No yearly memberships available</span
    >
  </mat-card>
