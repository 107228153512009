import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { DataService } from '../services';
import { PortalMembershipsActions } from '../actions';

@Injectable()
export class PortalPromoMembershipsEffects {
  getPortalPromoMemberships$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortalMembershipsActions.getPromoMembershipsRequest),
      switchMap((action) =>
        this.dataService
          .getPortalPromoMemberships(action.totalCount, action.promo)
          .pipe(
            switchMap((response) =>
              of(
                PortalMembershipsActions.getPromoMembershipsSuccess({
                  memberships: response.data.getAllPortalMemberships.edges,
                })
              )
            ),
            catchError((error) =>
              of(
                PortalMembershipsActions.getMembershipsFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );

  constructor(private actions$: Actions, private dataService: DataService) {}
}
