import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup} from '@angular/forms';

@Component({
  selector: 'hyp2-register-client',
  templateUrl: './register-client.component.html',
  styleUrls: ['./register-client.component.scss']
})
export class RegisterClientComponent implements OnInit {
  @Output() buttonEvent = new EventEmitter();
  @Input() loading: Boolean;
  registerForm: FormGroup;

  constructor() { }

  ngOnInit() {
    this.registerForm = new FormGroup({});
  }

  emitForm($event){
    this.buttonEvent.emit($event?.value);
  }
}
