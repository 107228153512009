import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as fromAuth from '../../../auth/reducers';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { UtilitiesService } from '../../../shared/services/utilities.service';
import { LogoutActions } from '../../../auth/actions';
import { SelectedItemService } from '../../../checkout/services';
import { Router } from '@angular/router';
import { Client } from '@hyp2/graphql';
import { BrowserService } from '../../services';

@Component({
  selector: 'hyp2-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  private sessionStorageKey = 'clientFirstName';
  isSupportedBrowser = true;

  message$ = this.authStore$.select(fromAuth.getClient).pipe(
    map((client) => {
      return this.getMessage(client);
    })
  );
  activeClientMembership$ = this.authStore$.select(
    fromAuth.getActiveClientMembership
  );

  logout() {
    this.selectedItemService.sendSelectedItem(null);
    this.authStore$.dispatch(LogoutActions.logoutClient());
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem(this.sessionStorageKey, '');
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authStore$: Store<fromAuth.State>,
    private utilService: UtilitiesService,
    private selectedItemService: SelectedItemService,
    private browserService: BrowserService,
    private router: Router
  ) {
      this.isSupportedBrowser = this.browserService.isSupportedBrowser();
  }

  routeToMyAccount() {
    this.router.navigate(['/my-account']);
  }

  routeToBuyMembership() {
    if (this.selectedItemService.getSelectedItem()) {
      this.router.navigate(['/checkout']);
    } else {
      this.router.navigate(['/']);
    }
  }

  routeToChangeMembership() {
    this.router.navigate(['/change-membership']);
  }

  routeToCancelMembership() {
    this.router.navigate(['/cancel-membership']);
  }

  routeToMyReceipts() {
    this.router.navigate(['/my-account/receipts']);
  }

  private getMessage(client: Client): string {
    const message = 'Hi, ';
    if (client) {
      const clientName = this.utilService.fixCapitalize(client?.name?.first);
      if (isPlatformBrowser(this.platformId)) {
        sessionStorage.setItem(this.sessionStorageKey, clientName);
      }
      return message + clientName;
    }

    if (isPlatformBrowser(this.platformId)) {
      const sessionStorageClientName = sessionStorage.getItem(
        this.sessionStorageKey
      );
      return sessionStorageClientName
        ? message + sessionStorageClientName
        : null;
    }
  }
}
