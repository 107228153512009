import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Client } from '@hyp2/graphql';

@Component({
  selector: 'hyp2-payment-info-card',
  templateUrl: './payment-info-card.component.html',
  styleUrls: ['./payment-info-card.component.scss'],
})
export class PaymentInfoCardComponent implements OnInit {
  @Output() billing = new EventEmitter();
  @Input() client: Client;
  @Output() newCardEvent = new EventEmitter();
  public newCard = false;
  public cardPresentMessage =
    'You already have a credit/debit card on file. If you would like to use a different card please select "change" below.';

  constructor() { }

  ngOnInit(): void {}

  setNewCard(value: boolean) {
    this.newCard = value;
    this.newCardEvent.emit(value);
  }
}
