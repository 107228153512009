import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { ChangeMembershipActions } from '../actions';
import { ChangeMembershipService } from '../services';
import { RefreshClientActions } from '../../auth/actions';

@Injectable()
export class ChangeMembershipEffects {
  private readonly FORM_MISMATCH_CODE = '4110';

  changeMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeMembershipActions.changeMembershipRequest),
      switchMap((action) =>
        this.changeMembershipService
          .changeMembership(
            action.toMembershipId,
            action.billing,
            action.startNow,
            action.useOnFileBilling,
            action.promo,
          )
          .pipe(
            switchMap((response) =>
              of(
                ChangeMembershipActions.changeMembershipSuccess({
                  response: response.data,
                })
              )
            ),
            catchError((error) =>
              of(
                ChangeMembershipActions.changeMembershipFailure({
                  error: error.message,
                  errorCode: error.graphQLErrors.map(
                    (x) => x.extensions?.code
                  )[0],
                })
              )
            )
          )
      )
    )
  );

  changeMembershipSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChangeMembershipActions.changeMembershipSuccess),
        tap((action) => {
          if (action.response) {
            this.router.navigate(['change-membership/success']);
          }
        })
      ),
    { dispatch: false }
  );

  changeMembershipFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeMembershipActions.changeMembershipFailure),
      switchMap((action) => {
        if (action.errorCode === this.FORM_MISMATCH_CODE) {
          return of(RefreshClientActions.refreshClientRequest());
        }
        return EMPTY;
      })
    )
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private changeMembershipService: ChangeMembershipService
  ) {}
}
