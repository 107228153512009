import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { HomeActions } from '../actions';

@Injectable()
export class HomeEffects {
  home$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.homeRequest),
        // do something here
    )
  );

  constructor(
    private actions$: Actions,
  ) { }
}
