import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth/services';
import { AuthComponent } from './auth/containers';
import { LoggedOutGuardService } from './auth/services/logged-out-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'zp', redirectTo: '/promos/zt0621prime', pathMatch: 'full' },
  { path: 'zp2', redirectTo: '/promos/zt0621prime2', pathMatch: 'full' },
  { path: 'j4', redirectTo: '/promos/zt070421mp', pathMatch: 'full' },
  { path: '4j', redirectTo: '/promos/zt070421yp', pathMatch: 'full' },
  {
    path: 'member-login',
    component: AuthComponent,
    data: {
      title: 'Member Login',
      description: 'Member Login',
    },
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    data: {
      title: 'Home',
      description:
        'Purchase Your Spray or UV Zoom Tan Membership Online In Just Minutes! Look & Feel Beautiful In Your Sun-kissed Skin EVERY DAY With Our Unlimited Tanning Member Privileges. No Contracts, No Commitments, Cancel Online',
    },
  },
  {
    path: 'promos',
    loadChildren: () =>
      import('./promo/promos.module').then((m) => m.PromosModule),
    data: {
      title: 'Promos',
      description: '',
    },
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutModule),
    data: {
      title: 'Checkout',
      description: 'Checkout Description',
    },
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    canLoad: [AuthGuardService],
    data: {
      title: 'My Account',
      preload: true,
      description:
        'View Your Zoom Tan Account Information Here. Login With Your Cell Phone Number And Driver’s License Number To Manage Your Membership, Update Billing Info, View Receipts & More',
    },
  },
  {
    path: 'cancel-membership',
    loadChildren: () =>
      import('./cancel-membership/cancel-membership.module').then(
        (m) => m.CancelMembershipModule
      ),
    canLoad: [AuthGuardService],
    pathMatch: 'full',
    data: {
      title: 'Cancel Membership',
      description:
        'Cancel Your Zoom Tan Membership Here With The Driver’s License & Cell Phone Number Associated With Your Zoom Tan Account',
    },
  },
  {
    path: 'change-membership',
    loadChildren: () =>
      import('./change-membership/change-membership.module').then(
        (m) => m.ChangeMembershipModule
      ),
    canLoad: [AuthGuardService],
    data: {
      title: 'Change Membership',
      description:
        'Upgrade, Downgrade Or Swap Out Your Yearly Or Monthly Zoom Tan Membership Here',
    },
  },
  {
    path: 'pay-balance',
    loadChildren: () =>
      import('./pay-balance/pay-balance.module').then(
        (m) => m.PayBalanceModule
      ),
    canLoad: [AuthGuardService],
    data: {
      title: 'Pay Balance',
      description:
        'Pay Your Zoom Tan Balance Here. Login With Your Cell Phone And Driver’s License Numbers To Check Your Balance, Pay Your Balance, Update Your Card Information & More',
    },
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./onfido/onfido.module').then((m) => m.OnfidoModule),
    canActivate: [LoggedOutGuardService],
    data: {
      title: 'Register',
      description: "Register by scanning your driver's license",
    },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: 'Page Not Found',
      description: 'Page Not Found',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
