import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../auth/services';

import {
  AccountComponent,
  ProfileComponent,
  ReceiptsComponent,
} from './containers';

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'My Account',
    },
    children: [
      { path: '', redirectTo: 'account', pathMatch: 'full' },
      {
        path: 'account',
        data: {
          title: 'My Account',
        },
        component: AccountComponent,
      },
      {
        path: 'receipts',
        data: {
          title: 'Receipts',
        },
        component: ReceiptsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
