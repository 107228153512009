import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromAuth from '../reducers';
import { RefreshClientActions, VerifyCellActions } from '../actions';
import { RefreshClientService } from '../services/refresh-client.service';
import { CheckoutPurchaseActions } from '../../checkout/actions';
import { UpdateBillingActions } from '../../profile/actions';
import { CancelMembershipActions } from '../../cancel-membership/actions';
import { ChangeMembershipActions } from '../../change-membership/actions';
import { PayBalanceActions } from '../../pay-balance/actions';

@Injectable()
export class RefreshClientEffects {
  refreshClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshClientActions.refreshClientRequest),
      withLatestFrom(
        this.authStore$.select(fromAuth.getClient)
      ),
      switchMap(([action, client]) =>
        this.refreshClientService.getRefreshClient().pipe(
          switchMap((response) => {
            const refreshClient = {
              ...client,
              ...response?.data?.getClientRefresh
            };
            return of(
              RefreshClientActions.refreshClientSuccess({
                client: refreshClient,
              })
            );
          },
        ),
          catchError((error) =>
            of(
              RefreshClientActions.refreshClientFailure({
                error: error.message,
              })
            )
          )
        )
      )
    )
  );

  refreshClientRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CheckoutPurchaseActions.checkoutPurchaseSuccess,
        VerifyCellActions.verifyCellSuccess,
        UpdateBillingActions.updateBillingSuccess,
        CancelMembershipActions.cancelMembershipSuccess,
        ChangeMembershipActions.changeMembershipSuccess,
        PayBalanceActions.payBalanceSuccess
      ),
      switchMap(() => of(RefreshClientActions.refreshClientRequest()))
    )
  );

  constructor(
    private actions$: Actions,
    private authStore$: Store<fromAuth.AuthState>,
    private refreshClientService: RefreshClientService
  ) { }
}
