import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { LogoutActions, VerifyClientActions } from '../actions';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class LogoutEffects {
  logoutClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LogoutActions.logoutClient),
      switchMap(() => {
        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.removeItem('token');
        }
        return of(VerifyClientActions.verifyClientRequest({ returnUrl: '' }));
      })
    )
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private actions$: Actions,
  ) {}
}
