import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hyp2-terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrls: ['./terms-and-conditions-dialog.component.scss'],
})
export class TermsAndConditionsDialogComponent implements OnInit {
  constructor(
    public matDialogRef: MatDialogRef<TermsAndConditionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public parsedClientForms: string[]
  ) {}
  ngOnInit() {}
}
