<div class="container">
  <!-- Membership Change Details -->
  <mat-card class="card mat-elevation-z4">
    <h2 class="card__title" data-cy="confirm-change__details">
      Membership Change Details
    </h2>
    <mat-card-content class="card__content">
      <mat-list>
        <!-- Price -->
        <div *ngIf="confirmChangeInformation?.showPrice" class="row">
          <mat-divider></mat-divider>
          <mat-list-item>Price</mat-list-item>
          <mat-list-item class="card__data" data-cy="confirm-change__price">
            {{ confirmChangeInformation?.price | currency }}/mo
          </mat-list-item>
        </div>
        <mat-divider></mat-divider>
        <!-- Features -->
        <div class="row">
          <mat-list-item>Features</mat-list-item>
          <mat-list-item class="card__data" data-cy="confirm-change__uv-features">
            {{ confirmChangeInformation?.shortDescription }}
          </mat-list-item>
        </div>
        <mat-divider></mat-divider>
        <!-- Details -->
        <div class="row">
          <mat-list-item>Details</mat-list-item>
          <mat-list-item class="card__data" data-cy="confirm-change__details-msg">
            {{ confirmChangeInformation?.details }}
          </mat-list-item>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>

  <!-- Billing Details -->
  <mat-card class="card mat-elevation-z4">
    <h2 class="card__title" data-cy="confirm-change__billing">
      Billing Details
    </h2>
    <mat-card-content class="card__content">
      <mat-list>
        <!-- Credit Card -->
        <mat-divider></mat-divider>
        <div *ngIf="!(forcePayment$ | async)" class="row">
          <mat-list-item>Credit Card</mat-list-item>
          <mat-list-item class="card__data">
            <span data-cy="confirm-change__billing-cc">
              {{
                confirmChangeInformation?.creditCardLastFour
                  ? 'xxxx-xxxx-xxxx-' +
                    confirmChangeInformation?.creditCardLastFour
                  : 'No Card Found'
              }}
            </span>
            <button class="card__edit-button change-add-card-button" (click)="setNewCard(true)" data-cy="card__edit-button">
              {{
                confirmChangeInformation?.creditCardLastFour ? 'change' : 'add'
              }}
            </button>
          </mat-list-item>
        </div>
        <div *ngIf="confirmChangeInformation?.nextPayment" class="row">
          <mat-divider></mat-divider>
          <!-- Next Payment -->
          <mat-list-item>Next Payment </mat-list-item>
          <mat-list-item class="card__data" data-cy="confirm-change__billing-next-payment">
            {{ confirmChangeInformation?.nextPaymentAmount | currency }} +
            taxes/fees on
            {{ confirmChangeInformation?.nextPayment | date }}
          </mat-list-item>
        </div>
        <mat-divider></mat-divider>
        <div class="row">
          <mat-list-item class="mat-list-item">Subtotal</mat-list-item>
          <mat-list-item class="card__data">
            <span data-cy="confirm-change__billing-cost">
              {{ confirmChangeInformation?.subtotal | currency }}
            </span>
          </mat-list-item>
        </div>
        <mat-divider></mat-divider>
        <div class="row">
          <mat-list-item class="mat-list-item">Tax</mat-list-item>
          <mat-list-item class="card__data">
            <span data-cy="confirm-change__billing-cost">
              {{ confirmChangeInformation?.tax | currency }}
            </span>
          </mat-list-item>
        </div>
        <mat-divider></mat-divider>
        <!-- Due Today -->
        <div class="row">
          <mat-list-item class="mat-list-item--bold">
            Total Due Today
          </mat-list-item>
          <mat-list-item class="card__data">
            <span class="card__data--bold" data-cy="confirm-change__billing-cost">
              {{ confirmChangeInformation?.total | currency }}
            </span>
          </mat-list-item>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>
  <!-- Payment Form -->
  <hyp2-card-form
    *ngIf="forcePayment$ | async"
    [cancelable]="newCard$ | async"
    (billing)="emitButtonEvent($event)"
    (cancelButtonEvent)="setNewCard(false)"
    [optionalSave]="
      !confirmChangeInformation?.isBadClientBilling &&
      !confirmChangeInformation?.isMembershipYearly &&
      !(confirmChangeInformation?.total === 0)
    "
    data-cy="payment-info__payment-form"
  ></hyp2-card-form>

  <!-- Warning Message -->
  <mat-card class="card mat-elevation-z4" data-cy="confirm-change__notice">
    <mat-card-title class="card__title--red">
      Please Note
    </mat-card-title>
    <mat-card-content class="card__content--red">
      {{ confirmChangeInformation?.note }}
    </mat-card-content>
  </mat-card>
</div>
