import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { VerifyCellService } from '../services';
import { VerifyCellActions } from '../actions';
import {
  ResponseDialogService,
  VerifyCellDialogService,
} from '../../shared/services';

@Injectable()
export class VerifyCellEffects {
  verifyCell$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VerifyCellActions.verifyCellRequest),
      switchMap((action) =>
        this.verifyCellService.verifyClientCell(action.pin).pipe(
          switchMap((response) =>
            of(
              VerifyCellActions.verifyCellSuccess({
                response: response.data,
              })
            )
          ),
          catchError((error) =>
            of(
              VerifyCellActions.verifyCellFailure({
                error: error?.graphQLErrors?.shift()?.message,
              })
            )
          )
        )
      )
    )
  );

  verifyCellSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VerifyCellActions.verifyCellSuccess),
        tap(() => {
          this.responseDialogService.openDialog({
            title: 'Phone Verified',
            description: 'Your phone number has been successfully verified!',
            error: false,
          });
        })
      ),
    { dispatch: false }
  );

  verifyCellFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VerifyCellActions.verifyCellFailure),
        tap(() => {
          this.verifyCellDialogService.openDialog({
            title: 'Verification Error',
            description:
              'There was an issue when attempting to verify your phone number. Please check the received pin is entered correctly and try again.',
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private responseDialogService: ResponseDialogService,
    private verifyCellDialogService: VerifyCellDialogService,
    private verifyCellService: VerifyCellService
  ) {}
}
