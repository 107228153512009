import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

import { pipe } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SelectedItemService } from './selected-item.service';
import { Store } from '@ngrx/store';
import * as fromCore from '../../core/reducers';
import { Membership } from '../../core/models';
import { PortalMembershipsActions } from '../../core/actions';
import { selectWhenLoaded } from '../../shared/utils';

@Injectable({
  providedIn: 'root',
})
export class CheckoutGuardService implements CanActivate {
  constructor(
    private selectedItemService: SelectedItemService,
    private coreStore: Store<fromCore.CoreState>,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const selectedItemService = this.selectedItemService;
    const coreStore = this.coreStore;
    const router = this.router;
    const selectedItem$ = selectedItemService.getSelectedItemObservable();

    return coreStore.pipe(
      loadMembershipFromParam(),
      requireSelectedItemToBeSet(),
    );

    function loadMembershipFromParam() {
      return (() => {
        if (route.queryParams.membership_id) {
          requestMemberships();
          return pipe(
            getMembershipByIdOnceLoaded(route.queryParams.membership_id),
            setMembershipAsSelectedItem(),
          );
        } else {
          return tap();
        }
      })();

      function requestMemberships() {
        const promo = route.queryParams.promo;
        coreStore.dispatch(
          promo
            ? PortalMembershipsActions.getPromoMembershipsRequest({ totalCount: 20, promo })
            : PortalMembershipsActions.getMembershipsRequest({ totalCount: 20 })
        );
      }

      function getMembershipByIdOnceLoaded(membershipId) {
        const promo = route.queryParams.promo;

        const membershipSelector = (
          promo
            ? fromCore.getPromoMembershipById
            : fromCore.getMembershipById
        )(membershipId);

        const loadedSelector = (
          promo
            ? fromCore.getPortalPromoMembershipsLoaded
            : fromCore.getPortalMembershipsLoaded
        );

        return selectWhenLoaded(membershipSelector, loadedSelector);
      }

      function setMembershipAsSelectedItem() {
        return tap((membership: Membership) => {
          if (membership) {
            selectedItemService.selectMembership(membership);
          }
        });
      }
    }

    function requireSelectedItemToBeSet() {
      return pipe(
        withLatestFrom(selectedItem$),
        map(([source, item]) => {
          if (item) {
            return true;
          } else {
            return router.parseUrl('/');
          }
        }),
      )
    }



  }



}



