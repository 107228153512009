import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthRoutingModule } from './auth-routing.module';
import { MaterialModule } from '../shared/material';
import { SharedModule } from '../shared/shared.module';
import { AuthComponent } from './containers';
import { VerifyClientComponent } from './components';

import * as fromAuth from './reducers';
import {
  VerifyClientEffects,
  RegisterClientEffects,
  VerifyCellEffects,
  LogoutEffects,
  RefreshClientEffects,
  TextResendEffects
} from './effects';
// Masks phone number input
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CancelMembershipLogEffects } from '../cancel-membership/effects/cancel-membership-log.effects';
const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
//

export const COMPONENTS = [AuthComponent, VerifyClientComponent];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    SharedModule,
    AuthRoutingModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
    EffectsModule.forFeature([
      VerifyClientEffects,
      RegisterClientEffects,
      VerifyCellEffects,
      LogoutEffects,
      RefreshClientEffects,
      TextResendEffects,
      CancelMembershipLogEffects
    ]),
  ],
})
export class AuthModule {}
