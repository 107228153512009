<div class="container">
  <div class="container__content" *ngIf="clientBilling || !!client.billing">
    <mat-checkbox
      (change)="emitEvent($event)"
      [checked]="agreed"
      mat-checkbox
      data-cy="tos__checkbox"
    >
    </mat-checkbox>
    <div class="container__content-text">
      By checking this box I agree to the terms and conditions of the

      <span *ngIf="showAgreement">
        <a
          class="container__content-text--blue"
          (click)="openTermsAndConditions()"
          data-cy="button__membership--agreement"
          >Membership Agreement</a
        >
        and the</span
      >
      <div>
        <a
          class="container__content-text--blue"
          (click)="openTermsAndConditions()"
          data-cy="button__service--agreement"
          >Service Agreement, Release, Waiver and Assumption of Risk.</a
        >
      </div>
    </div>
  </div>
</div>
