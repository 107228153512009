import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ClientReceipt } from '../../../auth/models';

@Component({
  selector: 'hyp2-receipts-information',
  templateUrl: './receipts-information.component.html',
  styleUrls: ['./receipts-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiptsInformationComponent {
  @Input() receipts: ClientReceipt[];
  @Output() buttonEvent = new EventEmitter();

}
