import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { TextResendService } from '../services';
import { TextResendActions } from '../actions';
import { SnackbarService } from '../../shared/services';

@Injectable()
export class TextResendEffects {
  resendText$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TextResendActions.textResendRequest),
      switchMap(() =>
        this.textResendService.textResend().pipe(
          switchMap((response) => {
            if (response?.data?.textVerificationCode)
              return of(
                TextResendActions.textResendSuccess({
                  response: response.data,
                  textVerificationCode: response.data.textVerificationCode,
                })
              );
            else {
              return of(
                TextResendActions.textResendFailure({
                  error: 'Error please try again',
                })
              );
            }
          }),
          catchError((error) =>
            of(
              TextResendActions.textResendFailure({
                error: error.message,
              })
            )
          )
        )
      )
    )
  );

  textResendSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TextResendActions.textResendSuccess),
        tap((response) => {
          if (response.textVerificationCode)
            this.snackBar.open('Text Sent!', 'Close', 5000);
        })
      ),
    { dispatch: false }
  );

  textResendFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TextResendActions.textResendFailure),
        tap((response) => {
          if (response.error)
            this.snackBar.open('Error please try again', 'Close', 5000);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private snackBar: SnackbarService,
    private textResendService: TextResendService
  ) {}
}
