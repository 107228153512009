import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromChangeMembershipInfo from './change-membership-info.reducer';
import * as fromChangeMembership from './change-membership.reducer';

export const changeFeatureKey = 'change';

export interface ChangeState {
  [fromChangeMembershipInfo.changeMembershipInfoFeatureKey]: fromChangeMembershipInfo.State;
  [fromChangeMembership.changeMembershipFeatureKey]: fromChangeMembership.State;
}

export interface State extends fromRoot.State {
  [changeFeatureKey]: ChangeState;
}

export function reducers(state: ChangeState | undefined, action: Action) {
  return combineReducers({
    [fromChangeMembershipInfo.changeMembershipInfoFeatureKey]:
      fromChangeMembershipInfo.reducer,
    [fromChangeMembership.changeMembershipFeatureKey]:
      fromChangeMembership.reducer,
  })(state, action);
}

export const selectChangeState = createFeatureSelector<ChangeState>(
  changeFeatureKey
);

// Change Membership Info Selectors
export const selectChangeMembershipInfoState = createSelector(
  selectChangeState,
  (state: ChangeState) => state.changeInfo
);

export const getAllChangeMembershipInfo = createSelector(
  selectChangeMembershipInfoState,
  fromChangeMembershipInfo.getAllChangeMembershipInfo
);

export const getMembershipChanges = createSelector(
  selectChangeMembershipInfoState,
  fromChangeMembershipInfo.getMembershipChanges
);
export const getChangeMembershipInfoLoading = createSelector(
  selectChangeMembershipInfoState,
  fromChangeMembershipInfo.getLoading
);

// Change membership
export const selectChangeMembershipState = createSelector(
  selectChangeState,
  (state: ChangeState) => state.changeMembership
);

export const getChangeMembershipLoading = createSelector(
  selectChangeMembershipState,
  fromChangeMembership.getLoading
);
