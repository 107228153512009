import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, filter, withLatestFrom } from 'rxjs/operators';

import { CancelMembershipLogService } from '../services';
import { CancelMembershipLogActions } from '../actions';
import * as fromRouter from '../../reducers';
import { VerifyClientActions } from '../../auth/actions';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

@Injectable()
export class CancelMembershipLogEffects {
  cancelMembershipLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CancelMembershipLogActions.cancelMembershipLogRequest),
      switchMap((action) =>
        this.cancelMembershipLogService
          .cancelMembershipLog(
            action.license,
            action.cell,
            action.success,
            action.message
          )
          .pipe(
            map(({ data }) => {
              return data.cancelMembershipLog
                ? CancelMembershipLogActions.cancelMembershipLogSuccess({
                    cancelMembershipLog: data.cancelMembershipLog,
                  })
                : CancelMembershipLogActions.cancelMembershipLogFailure();
            }),
            catchError((error) =>
              of(CancelMembershipLogActions.cancelMembershipLogFailure())
            )
          )
      )
    )
  );

  clientNotFoundLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VerifyClientActions.verifyClientFailure),
      withLatestFrom(this.routerStore.pipe(select(fromRouter.selectUrl))),
      filter(([action, url]) => url?.includes('cancel-membership')),
      switchMap(([action]) =>
        of(
          CancelMembershipLogActions.cancelMembershipLogRequest({
            license: action.license,
            cell: action.cell,
            message: 'Client Not Found',
            success: 0,
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private cancelMembershipLogService: CancelMembershipLogService,
    private routerStore: Store<fromRouter.State>
  ) {}
}
