import { Component } from '@angular/core';
import { PromoComponent, BillingTypes, Brands } from '../main/promo.component';

@Component({
  templateUrl: '../main/promo.component.html',
  styleUrls: ['../main/promo.component.scss'],
})
export class RadioComponent extends PromoComponent {
  // id
  static path = 'radio';
  static promoCode = 'P3';

  // memberships
  brand = Brands.ZOOMTAN;
  billingType = BillingTypes.MONTHLY;

  // display
  imageFileDesktop = '/assets/images/promos/radio-desktop.jpg';
  imageFileMobile = '/assets/images/promos/radio-mobile.jpg';
  header = 'GET A MONTH OF SUN TANNING FOR ONLY $9.99!';
  text = 'With this exclusive special offer for radio listeners, new and returning members can try us out and save up to 72% on their first tanning month! We do not require any long-term commitments or contracts. Cancellation is FREE and can be completed online any time after just 10 days from the start date. Members who LOVE our service can continue after the first month at regular pricing or opt to cancel and use the remainder of the tanning month that they purchased.';
}
