import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {
  Router,
  CanLoad,
  Route,
  UrlSegment,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { map, tap, filter, withLatestFrom, first } from 'rxjs/operators';

import * as fromAuth from '../reducers';
import * as fromRouter from '../../reducers';

import { VerifyClientActions } from '../actions';
import { isPlatformServer } from '@angular/common';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store<fromAuth.AuthState>,
    private routerStore: Store<fromRouter.State>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }
    return this.getAuthEligibility();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }
    return this.getAuthEligibility(true, segments?.join('/'));
  }

  getAuthBundleObservable(): Observable<[string, boolean, string]> {
    return this.store$
      .pipe(select(fromAuth.getJwt))
      .pipe(
        withLatestFrom(
          this.store$.pipe(select(fromAuth.getIsAuthenticated)),
          this.routerStore.pipe(select(fromRouter.selectUrl))
        )
      );
  }

  getAuthValueObservable() {
    return this.getAuthBundleObservable().pipe(
      tap(([jwt]) => {
        if (jwt === undefined) {
          this.store$.dispatch(
            VerifyClientActions.verifyClientRequest({ returnUrl: null })
          );
        }
      }),
      filter(([jwt, auth]) => jwt !== undefined && auth !== undefined)
    );
  }

  getAuthFirstPipe(firstPipe: boolean) {
    const observable = this.getAuthValueObservable();
    return firstPipe ? observable.pipe(first()) : observable;
  }

  getAuthEligibility(firstPipe = false, destinationPath = 'my-account') {
    return this.getAuthFirstPipe(firstPipe).pipe(
      map(([jwt, auth, currentPath]) => {
        if (auth) {
          return true;
        } else if (!auth) {
          const defaultDestination = (destinationPath === 'my-account');
          const defaultSource = (!currentPath || currentPath === '/');
          const shouldReturn = (!defaultSource && defaultDestination);
          this.router.navigate(['/member-login'], {
            queryParams: {
              returnUrl: shouldReturn ? currentPath : destinationPath,
            },
          });
          return false;
        }
      })
    );
  }
}
